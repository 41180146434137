import { Reducer } from 'redux';

import { APIOrder, APITopic, APIWorkPackage } from '../../types/api';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from './utils';
import { BackendError } from '../../utils/api';
import { flow } from '../../utils/function';
import { isPresent, isDefined } from '../../utils/general';
import groupBy from '../../utils/groupBy';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';
import { getArrivalRowById } from './arrivalRow';
import { sortItems } from './helpers/sort';
import { selectProjectOrders } from './order/order';
import { getRenderableOrderRowById } from './orderRow';
import { getTopic, getTopicsByWorkPackageId } from './topic';
import { getWorkPackageGroupById } from './workPackageGroup';

import { AppState } from '.';

type WorkPackageDataState = Partial<
  Record<
    string,
    remoteData.RemoteData<
      Partial<Record<string, APIWorkPackage>>,
      BackendError | undefined
    >
  >
>;

type WorkPackageState = {
  workPackageData: WorkPackageDataState;
  deleteRequests: Record<
    string,
    remoteData.RemoteData<undefined, BackendError | undefined>
  >;
};

const initialState: WorkPackageState = {
  workPackageData: {},
  deleteRequests: {},
};

const workPackageReducer: Reducer<WorkPackageState, ActionTypes> = (
  state = initialState,
  action
): WorkPackageState => {
  switch (action.type) {
    case 'GET_WORK_PACKAGES_STARTED': {
      const { projectId } = action.payload;

      const dataState = {
        ...state.workPackageData,
        [projectId]: remoteData.loading,
      };

      return {
        ...state,
        workPackageData: dataState,
      };
    }
    case 'GET_WORK_PACKAGES_FAILURE': {
      const { projectId, error } = action.payload;

      const dataState = {
        ...state.workPackageData,
        [projectId]: remoteData.fail(error),
      };

      return {
        ...state,
        workPackageData: dataState,
      };
    }
    case 'GET_WORK_PACKAGES_SUCCESS': {
      const { projectId, workPackages } = action.payload;
      const normalizedworkPackages = normalizeBy('id', workPackages);

      return {
        ...state,
        workPackageData: {
          ...state.workPackageData,
          [projectId]: remoteData.succeed(normalizedworkPackages),
        },
      };
    }
    case 'DELETE_WORK_PACKAGE_STARTED': {
      const { workPackageId } = action.payload;

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [workPackageId]: remoteData.loading,
        },
      };
    }
    case 'DELETE_WORK_PACKAGE_FAILURE': {
      const { workPackageId, error } = action.payload;

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [workPackageId]: remoteData.fail(error),
        },
      };
    }
    case 'DELETE_WORK_PACKAGE_SUCCESS': {
      const { workPackageId, workPackages } = action.payload;

      const oldDataState = state.workPackageData;

      const newDataState = workPackages
        ? workPackages.reduce((nextState, workPackage) => {
            const { id, projectId, isDeleted } = workPackage;

            const { [projectId]: remoteWorkPackages = remoteData.notAsked } =
              nextState;

            return {
              ...nextState,
              [projectId]: remoteData.map(
                remoteWorkPackages,
                ({ [id]: _, ...otherWorkPackages }) =>
                  isDeleted
                    ? otherWorkPackages
                    : { [id]: workPackage, ...otherWorkPackages }
              ),
            };
          }, oldDataState)
        : oldDataState;

      return {
        ...state,
        workPackageData: newDataState,
        deleteRequests: {
          ...state.deleteRequests,
          [workPackageId]: remoteData.succeed(undefined),
        },
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { workPackages: updatedWorkPackages = [] } = action.payload;

    const oldDataState = state.workPackageData;

    const newDataState = updatedWorkPackages
      ? updatedWorkPackages.reduce((nextState, workPackage) => {
          const { id, projectId, isDeleted } = workPackage;

          const { [projectId]: remoteWorkPackages = remoteData.notAsked } =
            nextState;

          return {
            ...nextState,
            [projectId]: remoteData.map(
              remoteWorkPackages,
              ({ [id]: _, ...otherWorkPackages }) =>
                isDeleted
                  ? otherWorkPackages
                  : { [id]: workPackage, ...otherWorkPackages }
            ),
          };
        }, oldDataState)
      : oldDataState;

    return {
      ...state,
      workPackageData: newDataState,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export default workPackageReducer;

type WorkPackageIdentifier = {
  workPackageId: string;
  projectId: string;
};

export const selectProjectWorkPackages =
  (projectId: string) =>
  ({
    workPackages: {
      data: { workPackageData },
    },
  }: AppState) =>
    workPackageData[projectId] ?? remoteData.notAsked;

export const selectDeleteWorkPackageRequest =
  (workPackageId: string) =>
  ({
    workPackages: {
      data: { deleteRequests },
    },
  }: AppState) =>
    deleteRequests[workPackageId] ?? remoteData.notAsked;

export const getWorkPackageById =
  (workPackageId: string) =>
  ({
    workPackages: {
      data: { workPackageData },
    },
  }: AppState): APIWorkPackage | undefined =>
    Object.values(workPackageData)
      .map((remoteWorkPackages) =>
        remoteData.unwrap(remoteWorkPackages ?? remoteData.notAsked, {
          unwrapper: ({ [workPackageId]: workPackage }) => workPackage,
          defaultValue: undefined,
        })
      )
      .find(isPresent);

type WorkSectionRow = {
  order: APIOrder;
  topics: APITopic[];
};

function toWorkSection(
  orders: Partial<Record<string, APIOrder>>,
  topics: APITopic[]
): WorkSectionRow[] {
  return Object.entries(groupBy('orderId', topics))
    .map(([orderId, orderTopics]) => {
      const order = orders[orderId];

      return order
        ? {
            order,
            topics: orderTopics,
          }
        : undefined;
    })
    .filter(isDefined);
}

export function getWorkSectionRows({
  projectId,
  workPackageId,
}: WorkPackageIdentifier): (
  appState: AppState
) => remoteData.RemoteData<WorkSectionRow[]> {
  return (appState: AppState) => {
    const remoteOrders = selectProjectOrders(projectId)(appState);
    const remoteTopics = getTopicsByWorkPackageId(workPackageId)(appState);
    const ordersAndTopics = remoteData.append(remoteOrders, remoteTopics);

    return remoteData.map(ordersAndTopics, ([orders, topics]) =>
      toWorkSection(orders, topics)
    );
  };
}

export const getProjectWorkPackages =
  (projectId: string) =>
  ({
    workPackages: {
      data: { workPackageData },
      sortOrders,
    },
  }: AppState): remoteData.RemoteData<APIWorkPackage[]> =>
    remoteData.map(
      workPackageData[projectId] ?? remoteData.notAsked,
      (workPackages) =>
        sortItems(Object.values(workPackages).filter(isDefined), sortOrders)
    );

export const getWorkPackagesByWorkPackageGroupId =
  (workPackageGroupId: string) =>
  (appState: AppState): APIWorkPackage[] => {
    const { sortOrders } = appState.workPackages;

    const workPackageGroup =
      getWorkPackageGroupById(workPackageGroupId)(appState);
    const workPackageIds = workPackageGroup?.workPackageIds ?? [];

    const workPackages = workPackageIds.map((id) =>
      getWorkPackageById(id)(appState)
    );

    return sortItems(workPackages.filter(isDefined), sortOrders);
  };

export const getWorkPackageCodes =
  (workPackageGroupId: string) =>
  (appState: AppState): string[] => {
    const workPackageGroup =
      getWorkPackageGroupById(workPackageGroupId)(appState);

    const workPackageIds = workPackageGroup?.workPackageIds ?? [];

    const workPackages = workPackageIds.map((id) =>
      getWorkPackageById(id)(appState)
    );

    return workPackages.filter(isDefined).map((wp) => wp.code);
  };

export const getWorkPackageByArrivalRowId =
  (arrivalRowId: string) =>
  (appState: AppState): APIWorkPackage | undefined => {
    const arrivalRow = getArrivalRowById(arrivalRowId)(appState);

    if (!arrivalRow) {
      return undefined;
    }

    const orderRow = getRenderableOrderRowById(arrivalRow.orderRowId ?? '')(
      appState
    );

    if (!orderRow) {
      return undefined;
    }

    const topic = getTopic(orderRow.topicId)(appState);

    if (!topic) {
      return undefined;
    }

    return getWorkPackageById(topic.workPackageId ?? '')(appState);
  };

export const getUnSpecifiedWorkPackage: (
  projectId: string
) => (
  appState: AppState
) => remoteData.RemoteData<APIWorkPackage | undefined> = (projectId) =>
  flow(selectProjectWorkPackages(projectId), (remoteWorkPackages) =>
    remoteData.map(remoteWorkPackages, (workPackages) =>
      Object.values(workPackages).find(
        (row) => row && row.isSpecified === false
      )
    )
  );
