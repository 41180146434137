import { ColumnSizingState } from '@tanstack/react-table';

import { recordDiff } from './general';

type Column = {
  relativeWidth: number;
};

type TableColumns = {
  [name: string]: Column;
};

const getTotalWidth = (columns: TableColumns) => {
  return Object.values(columns).reduce(
    (acc, { relativeWidth }) => acc + relativeWidth,
    0
  );
};

const getWidths =
  <Columns extends TableColumns>(columns: Columns) =>
  <ColumnName extends keyof Columns>(name: ColumnName) => {
    const totalWidth = getTotalWidth(columns);

    const {
      [name]: { relativeWidth },
    } = columns;

    return Math.round(100 * (relativeWidth / totalWidth));
  };

export default getWidths;

export const adjustNeighbouringColumns = (
  originalState: ColumnSizingState,
  oldState: ColumnSizingState,
  newState: ColumnSizingState,
  columnNames: (string | undefined)[]
): ColumnSizingState => {
  const diffInState = recordDiff(oldState, newState);

  const adjustedState = Object.entries(diffInState).reduce((acc, curr) => {
    const [key, value] = curr;

    const newValue = value.new;
    const oldValue = value.old ?? originalState[key];

    const diff = newValue - oldValue;

    const columnIndex = columnNames.findIndex((col) => col === key);

    if (columnIndex === -1) {
      return acc;
    }

    const neighborColumn = columnNames[columnIndex + 1];

    if (!neighborColumn) {
      return {
        ...acc,
        [key]: newValue,
      };
    }

    const neighborColumnSize =
      acc[neighborColumn] ?? originalState[neighborColumn];

    return {
      ...acc,
      [key]: newValue,
      [neighborColumn]: neighborColumnSize - diff,
    };
  }, oldState as ColumnSizingState);

  return adjustedState;
};
