// First entries are blank spaces on purposes; otherwise, library will treat them
// as missing resources and display key names instead
export const fi = {
  'form.inputError.isRequired': 'Pakollinen kenttä',
  'form.inputError.maxLength': 'Maksimipituus on 200 merkkiä',
  'form.inputError.startDateError':
    'Aloituspäivä ei voi olla päättymispäivän jälkeen',
  'form.inputError.endDateError':
    'Päättymispäivä ei voi olla ennen aloituspäivää',
  'form.inputError.maxDateError':
    'Myöhäisin sallittu päättymispäivä {yearsFromNow} vuotta tästä päivästä',
  'form.inputError.codeAlreadyExists': 'Koodi on jo käytössä',

  'analysis.header': 'Erittelyt',

  'auth.header': 'Tervetuloa Sitedrive Financeen',
  'auth.signin.method.Google': 'Kirjaudu Google-tilillä',
  'auth.signin.method.Microsoft': 'Kirjaudu Microsoft-tilillä',
  'auth.signin.method.Email': 'Kirjaudu sähköpostilla',

  'auth.error.unspecific': 'Kirjautuminen epäonnistui',
  'auth.error.auth/internal-error': 'Kirjautuminen epäonnistui',
  'auth.error.auth/account-exists-with-different-credential':
    'Tili on jo olemassa eri tunnistetiedoilla. Kirjaudu sisään sähköposti linkin kautta, ja navigoi käyttäjäasetuksiin yhdistääksesi tili. https://support.sitedrive.com/fi/kb/kirjautuminen-sitedriveen-microsoft-tilill%C3%A4',
  'auth.error.missingEmail': 'Syötä sähköposti',
  'auth.error.backend.insufficientRights':
    'Ei käyttöoikeutta, ota yhteys pääkäyttäjään',

  'auth.email.signIn.enterEmail': 'Syötä sähköpostisoitteesi',
  'auth.email.signIn.form.email.error':
    'Syötä osoite formaatissa example@example.com',
  'auth.email.signIn.sendLink': 'Lähetä kirjautumislinkki',
  'auth.email.signIn.emailSent': 'Tarkista sähköpostista kirjautumislinkki',
  'auth.email.signIn.finishSignIn': 'Kirjaudutaan..',

  'navigation.project': 'Hankinnat',
  'navigation.worksection': 'Työvaiheet',
  'navigation.revenue': 'Tulot',
  'navigation.analysis': 'Erittely',
  'navigation.reporting': 'Raportointi',
  'navigation.target': 'Tavoite',
  'navigation.notifications': 'Ilmoitukset',
  'navigation.notifications.all': 'Kaikki ilmoitukset',
  'navigation.notifications.unread': 'Lukemattomat ilmoitukset',
  'navigation.notifications.noNotifications': 'Ei ilmoituksia',
  'navigation.notifications.new': 'Uusi! ',
  'navigation.notifications.defaultDescription': 'Ilmoitus',
  'navigation.notifications.description.unhandledInvoice':
    'Lasku käsittelemättä',
  'navigation.notifications.description.unhandledActualCost':
    'Muu kulu käsittelemättä',
  'navigation.notifications.description.reclaimedInvoices':
    'Reklamoidut laskut',
  'navigation.notifications.description.newMessage': 'Uusi viesti hankinnalla',
  'navigation.notifications.description.declinedInvoice':
    'Lasku mitätöity hankinnalla',
  'navigation.notifications.description.canceledActualCost': 'Muu kulu peruttu',
  'navigation.notifications.description.invoiceNeedingRehandling':
    'Lasku uudelleen käsiteltävä',
  'navigation.notifications.description.actualCostNeedingRehandling':
    'Muu kulu uudelleen käsiteltävä',
  'navigation.notifications.description.delegationRequest': 'Tarkistuspyyntö',
  'navigation.notifications.description.delegationResponse':
    'Vastaus tarkistuspyyntöön',
  'navigation.notifications.description.invoicesBeingCorrected':
    'Korjauksessa olevat laskut',
  'navigation.notifications.eventsForHandledInvoices':
    'Käsiteltyjen laskujen tapahtumat',
  'navigation.notifications.eventsForHandledInvoices.toolTip':
    'Näytä käyttäjien reklamoimat, uudelleen tarkastukseen lähetetyt, tarkastuksen jälkeen hylätyt ja käyttäjien korjauksessa olevat laskut',
  'navigation.notifications.unprocessedInvoices':
    'Käsittelemättömät laskut tai muut kulut',
  'navigation.notifications.unprocessedInvoices.toolTip':
    'Näyttää laskut vanhimman eräpäivän mukaisessa järjestyksessä',
  'navigation.notifications.delegation.toolTip':
    'Näyttää sinulle ohjatut laskut ja mahdolliset vastaukset sinun pyyntöihisi',
  'navigation.notifications.messages': 'Sinulle osoitetut viestit',
  'navigation.notifications.justNow': 'Juuri nyt',
  'navigation.notifications.ago': 'sitten',
  'navigation.notifications.minutes': 'min',
  'navigation.notifications.hours': 't',
  'navigation.notifications.invoice': 'Lasku',
  'navigation.notifications.expense': 'Kulu',
  'navigation.notifications.registrationDate': 'Kirjaus',
  'navigation.notifications.delegationRequest': 'Tarkista',
  'navigation.notifications.delegationResponse': 'Tarkistettu',
  'navigation.notifications.delegated': 'Tarkistettavana',
  'navigation.notifications.delegationResponse.reject': 'Ei kunnossa!',
  'navigation.notifications.delegationResponse.accept': 'Tarkistettu, ok',
  'navigation.notifications.delegationResponse.noInformation': 'Ei tietoa',
  'navigation.notifications.externalChangeNotifications':
    'Muutokset muista järjestelmistä',
  'navigation.notifications.externalChangeNotifications.targetRows':
    'Ulkoinen tavoitemuutos',
  'navigation.notifications.externalChangeNotifications.newOrders':
    'Uudet hankinnat',
  'navigation.notifications.externalChangeNotifications.removedOrders':
    'Poistetut hankinnat',
  'navigation.notifications.externalChangeNotifications.editedOrders':
    'Muokatut hankinnat',
  'navigation.notifications.externalChangeNotifications.checkOlderChanges':
    'Katso aiemmat muutokset raportoinnista',

  'navigation.projectMenu.userManagement': 'Hallitse käyttäjiä',
  'navigation.projectMenu.userManagement.modal.header':
    'Projektin {projectCodeName} käyttöoikeudet',
  'navigation.projectMenu.userManagement.inviteNewUser': 'Kutsu uusi käyttäjä',
  'navigation.projectMenu.userManagement.addNewUser':
    'Lisää uusi käyttäjä Sitedrive Financeen',
  'navigation.projectMenu.userManagement.unique.email.error':
    'Syöttämäsi sähköpostiosoite on jo käytössä',

  'navigation.projectMenu.userManagement.addProject':
    'Lisää uusi projekti käyttäjälle',
  'navigation.projectMenu.userManagement.addUser':
    'Lisää uusi käyttäjä projektille',

  'header.UOM': 'Yks.',
  'header.orderRow': 'Tilausrivi',

  'meta.none': ' ',
  'meta.empty': ' ',
  'meta.euros': '{euros} €',
  'meta.loading': 'Ladataan...',
  'meta.networkError': 'Yhteysvirhe',
  'meta.failure': 'Oops. Jotain meni pieleen',
  'meta.pleaseContact':
    'Ole yhteydessä pääkäyttäjään sitedrivepay@sitedrive.com',
  'meta.noProjects': 'Ei aktiivisia projekteja',
  'meta.pageNotFound': 'Sivua ei löydy',

  'analysis.button.edit': 'Muokkaa',
  'analysis.button.new': 'Uusi erittely',
  'analysis.button.remove': 'Poista rivi',
  'analysis.table.header.empty': ' ',
  'analysis.table.header.code': 'Tunnus',
  'analysis.table.header.delete': ' ',
  'analysis.table.header.none': ' ',
  'analysis.table.header.description': 'Kuvaus',
  'analysis.table.header.value': 'Rivitieto',
  'analysis.table.header.paymentProgramRowsAmount': 'Tulot',
  'analysis.table.header.orderRowsAmount': 'Menot',
  'analysis.table.header.targetRowsAmount': 'Tavoite',
  'analysis.table.header.arrivalsAmount': 'Vastaanotettu',
  'analysis.table.header.attributeValues': '(dynaamiset sarakkeet)',
  'analysis.table.header.status': 'Status',
  'analysis.table.mainRow.closed': 'Aihe on suljettu',
  'analysis.table.mainRow.open': 'Aihe on auki',

  'analysis.paymentProgramRow.modal.title':
    '{analysisRowCode} - {analysisRowName} - Linkitetyt tulorivit',
  'analysis.orderRow.modal.title':
    '{analysisRowCode} - {analysisRowName} - Linkitetyt tilausrivit',
  'analysis.targetRow.modal.title':
    '{analysisRowCode} - {analysisRowName} - Linkitetyt tavoiterivit',
  'analysis.received.modal.title':
    '{analysisRowCode} - {analysisRowName} - Laskut ja muut kulut',
  'analysis.received.modal.unassignedArrivals': 'Kohdistamattomat vastaanotot',
  'analysis.modal.header.description': 'Kuvaus',
  'analysis.modal.header.order': 'Hankinta',
  'analysis.received.modal.header.identifier': 'Tunnus',
  'analysis.modal.sum': 'Yhteensä: {sum}',
  'analysis.modal.linkedEntity.altText':
    'Linkki tilausriville hankinta-näkymään',
  'analysis.modal.unlink': 'Poista linkitys',

  'common.cancel': 'Peruuta',
  'common.save': 'Tallenna',
  'common.closingConfirmation': 'Haluatko sulkea modaali-ikkunan?',
  'common.send': 'Lähetä',
  'common.total': 'Yhteensä',
  'common.total.short': 'yht.',
  'common.description': 'Kuvaus',
  'common.quantity': 'Määrä',
  'common.unit.short': 'Yks.',
  'common.unitPrice': 'Yksikköhinta',
  'common.amount': 'Summa',
  'common.received': 'Vastaanotettu',
  'common.analysis': 'Erittely',
  'common.toBeReceived': 'Vastaanotettava',
  'common.row': 'Rivi',
  'common.clear': 'Tyhjennä',
  'common.receive': 'Vastaanota',
  'common.dismiss': 'Sulje',
  'common.reason': 'Syy',
  'common.showHide': 'Näytä/piilota sarakkeet',
  'common.description.missing': 'Kuvaus puuttuu',
  'common.email': 'Sähköposti',
  'common.noDate': 'Ei päivämäärää',

  'common.previous': 'Edellinen',
  'common.next': 'Seuraava',
  'common.close': 'Sulje',
  'common.createRows': 'Luo rivit',
  'common.move': 'Siirrä',
  'common.resetChanges': 'Peruuta muutokset',

  'common.code': 'Koodi',
  'common.remainingAmount': 'Jäljellä',
  'common.remainingAmountLong': 'Ennustetta vastaanottamatta',
  'common.status': 'Status',
  'common.unsettled': 'Laskut',
  'common.status.Planned': 'Suunnittelu',
  'common.status.Accepted': 'Sovittu',
  'common.status.Closed': 'Valmis',
  'common.addRow': 'Lisää rivi',
  'common.importRowsFromTable': 'Tuo rivejä taulukosta',
  'common.maxCharacters': 'Suurin sallittu merkkimäärä on {value}',
  'common.increase': 'Kasvata',

  'common.openOrClose': 'Avaa / sulje',
  'common.removeSelections': 'Tyhjennä valinnat',
  'common.remove': 'Poista',

  'common.target': 'Tavoite',
  'common.target.difference': 'Ero tavoitteeseen',
  'common.prediction': 'Ennuste',
  'common.reported': 'Raportoitu',
  'common.currentAccepted': 'Tilanne',
  'common.planned': 'Suunnitelma',
  'common.predictionChangeFromLatest': 'Ennustemuutos',
  'common.contract': 'Sopimus',
  'common.changeOrders': 'Lisä- ja muutostyöt',
  'common.reserves': 'Varaukset',
  'common.orderedButNotReceived': 'Tilattua vastaanottamatta',
  'common.invoicesUnsettledCount': 'laskua käsittelemättä',
  'common.invoiceCorrectionsCount': 'laskua korjattavana',
  'common.invoiceComplaintsCount': 'laskua reklamoitu',
  'common.actualcostsPendingCount': 'muuta kulua käsittelemättä',
  'common.workPackage': 'Työkokonaisuus',
  'common.name': 'Nimi',
  'common.surname': 'Sukunimi',
  'common.phone': 'Puhelin',
  'common.language': 'Kieli',
  'common.role': 'Rooli',
  'common.workPackage.notFound': 'Ei vastaavaa työkokonaisuutta',
  'common.paymentBatchGroup.notFound': 'Ei vastaavaa tuloryhmäluokittelua',
  'common.paymentBatchGroup': 'Tuloryhmäluokittelu',
  'common.noSelection': 'Ei valittu',
  'common.choose': 'Valitse',
  'common.button.csvExport': 'Vie tiedot (csv)',
  'common.workSectionClass': 'Työvaiheluokka',
  'common.projects': 'Projektit',

  'common.notification.error': 'Virhe: {message}',
  'common.notification.invalidCsrfTokenError':
    'Csrf token vanhentunut, päivitä selaimen ikkuna. Jos ongelma ei katoa, ota yhteys ylläpitoon. ({message})',
  'common.notification.unknownError': 'Tapahtui tuntematon virhe ({type})',

  'common.maxRowsExceeded':
    'Suurin sallittu määrä kerralla lisättäviä rivejä on {maxRows}',

  'common.order': 'Hankinta',
  'common.orderRow': 'Tilausrivi',
  'common.targetRow': 'Tavoiterivi',
  'common.newTopic': 'Uusi aihe',
  'common.moreWorkSections': 'Lisää uusia työvaiheita',

  'common.status.ignore': 'En tiedä',
  'common.status.yes': 'Kyllä',
  'common.status.no': 'Ei',
  'common.status.ok': 'Ok',
  'common.otherSections': 'Muut vaiheet',

  'common.orderStatus.1': 'Suunnittelu',
  'common.orderStatus.2': 'Sopimus',
  'common.orderStatus.3': 'Valmis',

  'common.userRights.projectEditor': 'Projektin muokkaaja',
  'common.userRights.readOnly': 'Vain luku',
  'common.userRights.projectInvoiceHandler': 'Projektin laskun käsittelijä',

  'common.userRights.projectEditor.description':
    'Saa muokata projektin tietoja mutta ei päivittää laskujen tilaa',
  'common.userRights.readOnly.description':
    'Saa vain lukea projektin tietoja mutta ei muokata',
  'common.userRights.projectInvoiceHandler.description':
    'Saa muokata projetin tietoja ja päivittää laskujen tilaa',

  'scheduledTasks.workLoad': 'Työmäärä',

  'common.workSection.icon.alt': 'Linkki työvaiheeseen',

  'comments.modal.title.order':
    '{visibleCode}, {orderName} kommentit ja muutosloki',
  'comments.modal.comments.submit': 'Lähetä',
  'comments.modal.comments.header': 'Kommentit',
  'comments.modal.comments.noComments': 'Ei viestejä',
  'comments.modal.comments.placeHolder':
    'Kirjoita viesti. Voit mainita käyttäjiä @-merkillä.',
  'comments.modal.changes.header': 'Muutokset',
  'comments.modal.changes.predictionChangeBeforeLocking': 'Ennustemuutos: ',
  'comments.modal.changes.targetChangeBeforeLocking': 'Tavoitemuutos: ',
  'comments.modal.changes.arrivalsChangeBeforeLocking': 'Vastaanotot: ',
  'comments.modal.changes.currentPeriod': 'Nykyinen',
  'comments.modal.changes.blank': ' ',
  'comments.modal.changes.orderRowNo': 'RiviNo',
  'comments.modal.changes.rowType': 'Laji',
  'comments.modal.changes.user': 'Käyttäjä',
  'comments.modal.changes.description': 'Kuvaus',
  'comments.modal.changes.action': 'Toiminto',
  'comments.modal.changes.filter': 'Filtteri',
  'comments.modal.changes.sumChange': 'Muutos',
  'comments.modal.changes.filter.multiselect.header': 'Suodata muutoshistoriaa',
  'comments.modal.changeStatus.editedBoth':
    'Tilausriviä on muokattu (määrä: {quantityChangeInfo}, yksikköhinta: {unitPriceChangeInfo})',
  'comments.modal.changeStatus.editedQuantity':
    'Tilausriviä on muokattu (määrä: {quantityChangeInfo})',
  'comments.modal.changeStatus.editedUnitPrice':
    'Tilausriviä on muokattu (yksikköhinta: {unitPriceChangeInfo})',
  'comments.modal.changeStatus.editedNoInfo': 'Tilausriviä on muokattu',
  'comments.modal.changeStatus.changeOfCosts': 'Tilausriviä on muokattu',
  'comments.modal.changeStatus.rejected': 'Lasku on hylätty',
  'comments.modal.changeStatus.processed': 'Lasku on käsitelty',
  'comments.modal.changeStatus.newRow': 'Tilausrivi on lisätty',
  'comments.modal.changeStatus.deletion': 'Tilausrivi on poistettu',
  'comments.modal.changeStatus.orderChangeWithValues':
    'Tilausrivi on siirretty {moveChangeInfo}',
  'comments.modal.changeStatus.orderChange':
    'Tilausrivi on siirretty hankintojen välillä',
  'comments.modal.changeStatus.topicChange':
    'Tilausrivi on siirretty aiheiden välillä',
  'comments.modal.changeStatus.movedToTopicWithValues':
    'Tilausrivi on siirretty aiheelle {moveChangeInfo}',
  'comments.modal.changeStatus.statusChange':
    'Tilausrivin statusta on muutettu',

  'comments.modal.changeStatus.editedBoth.target':
    'Tavoiteriviä on muokattu (määrä: {quantityChangeInfo}, yksikköhinta: {unitPriceChangeInfo})',
  'comments.modal.changeStatus.editedQuantity.target':
    'Tavoiteriviä on muokattu (määrä: {quantityChangeInfo})',
  'comments.modal.changeStatus.editedUnitPrice.target':
    'Tavoiteriviä on muokattu (yksikköhinta: {unitPriceChangeInfo})',
  'comments.modal.changeStatus.editedNoInfo.target': 'Tavoiteriviä on muokattu',
  'comments.modal.changeStatus.changeOfCosts.target':
    'Tavoiteriviä on muokattu',
  'comments.modal.changeStatus.newRow.target': 'Tavoiterivi on lisätty',
  'comments.modal.changeStatus.deletion.target': 'Tavoiterivi on poistettu',
  'comments.modal.changeStatus.orderChangeWithValues.target':
    'Tavoiterivi on siirretty {moveChangeInfo}',
  'comments.modal.changeStatus.orderChange.target':
    'Tavoiterivi on siirretty hankintojen välillä',
  'comments.modal.changeStatus.topicChange.target':
    'Tavoiterivi on siirretty aiheiden välillä',
  'comments.modal.changeStatus.movedToTopicWithValues.target':
    'Tavoiterivi on siirretty aiheelle {moveChangeInfo}',

  'comments.modal.changeStatus.changeOfCosts.short': 'Muokattu',
  'comments.modal.changeStatus.deletion.short': 'Poistettu',
  'comments.modal.changeStatus.orderChange.short':
    'Siirretty hankintojen välillä',
  'comments.modal.changeStatus.topicChange.short': 'Siirretty aiheiden välillä',
  'comments.modal.changeStatus.statusChange.short': 'Status muutettu',
  'comments.modal.changeStatus.newRow.short': 'Lisätty',

  'component.AnalysisLiker.filter.placeholder':
    'Hae erittelykoodilla tai kuvauksella...',

  'datePicker.months.Jan': 'Tammikuu',
  'datePicker.months.Feb': 'Helmikuu',
  'datePicker.months.Mar': 'Maaliskuu',
  'datePicker.months.Apr': 'Huhtikuu',
  'datePicker.months.May': 'Toukokuu',
  'datePicker.months.Jun': 'Kesäkuu',
  'datePicker.months.Jul': 'Heinäkuu',
  'datePicker.months.Aug': 'Elokuu',
  'datePicker.months.Sep': 'Syyskuu',
  'datePicker.months.Oct': 'Lokakuu',
  'datePicker.months.Nov': 'Marraskuu',
  'datePicker.months.Dec': 'Joulukuu',

  'months.short.0': 'tammi',
  'months.short.1': 'helmi',
  'months.short.2': 'maalis',
  'months.short.3': 'huhti',
  'months.short.4': 'touko',
  'months.short.5': 'kesä',
  'months.short.6': 'heinä',
  'months.short.7': 'elo',
  'months.short.8': 'syys',
  'months.short.9': 'loka',
  'months.short.10': 'marras',
  'months.short.11': 'joulu',

  'datePicker.labels.previousMonth': 'Edellinen kuukausi',
  'datePicker.labels.nextMonth': 'Seuraava kuukausi',

  'datePicker.weekDaysShort.Mon': 'Ma',
  'datePicker.weekDaysShort.Tue': 'Ti',
  'datePicker.weekDaysShort.Wed': 'Ke',
  'datePicker.weekDaysShort.Thu': 'To',
  'datePicker.weekDaysShort.Fri': 'Pe',
  'datePicker.weekDaysShort.Sat': 'La',
  'datePicker.weekDaysShort.Sun': 'Su',

  'datePicker.weekDaysLong.Mon': 'Maanantai',
  'datePicker.weekDaysLong.Tue': 'Tiistai',
  'datePicker.weekDaysLong.Wed': 'Keskiviikko',
  'datePicker.weekDaysLong.Thu': 'Torstai',
  'datePicker.weekDaysLong.Fri': 'Perjantai',
  'datePicker.weekDaysLong.Sat': 'Lauantai',
  'datePicker.weekDaysLong.Sun': 'Sunnuntai',

  'order.mainButtons.edit': 'Muokkaa',
  'order.mainButtons.showComments': 'Kommentit',
  'order.mainButtons.showTarget': 'Näytä tavoite',
  'order.mainButtons.showInfo': 'Hankinnan perustiedot',
  'order.mainButtons.continueReception': 'Jatka vastaanottoa',
  'order.mainButtons.startReception': 'Aloita vastaanotto',

  'order.analysisTags.showMore': 'Näytä lisää erittelyjä',
  'order.analysisTags.add': 'Luo erittelyjä',

  'order.editMode.saveAndExit': 'Tallenna ja poistu',
  'order.editMode.toolBelt.remove': 'Poista tilausrivejä',
  'order.editMode.toolBelt.move': 'Siirrä {count} valittua tilausriviä',
  'order.editMode.toolBelt.move.one': 'Siirrä {count} valittu tilausrivi',
  'order.editMode.toolBelt.add': 'Lisää aihe',

  'order.editMode.moveModal.title':
    'Siirrä valitut tilausrivit ({amount} kpl, yht. {totalPrice})',
  'order.editMode.moveModal.filterLabel': 'Hae sisältöä:',
  'order.editMode.moveModal.filterPlaceHolder':
    'Hae hankintanumerolla, kuvauksella tai toimittajalla...',

  'order.editMode.moveModal.scrollListTitle': 'Valitse hankinta',

  'order.editMode.moveModal.dropdownLabel': 'Siirrä aiheelle:',
  'order.editMode.moveModal.dropdownPlaceholder': 'Valitse aihe...',
  'order.editMode.moveModal.dropdown.newTopic':
    'Luo uusi aihe samalla työvaiheella ({workSections})',
  'order.editMode.moveModal.dropdown.multipleNewTopics':
    'Luo uudet aiheet samoilla työvaiheilla ({workSections})',

  'order.editMode.moveModal.dropdown.selectionInfo':
    'Olet valinnut aiheen, joka liittyy eri työvaiheeseen',
  'order.editMode.moveModal.notification':
    'Rivi(t) ({descriptions}) siirretty hankinnalle ',

  'order.editMode.delete.topic.success.notification':
    'Aihe ({deletedTopicName}) poistettu. Tavoite- ja ennusterivit siirtyivät automaattisesti oletusaiheen ',

  'order.editMode.delete.topic.success.notification.after': ' alle',

  'order.editMode.moveModal.button.action': 'Siirrä aiheelle',
  'order.editMode.moveModal.information.invoice':
    'Lasku {expenseId} siirretty hankinnalle',
  'order.editMode.moveModal.information.actualCost':
    'Kulu {expenseId} siirretty hankinnalle',
  'order.editMode.moveModal.noOtherOrders':
    'Valittuihin tilausriveihin on vastaanottoja, vain hankinnan sisäinen siirto sallitaan',
  'order.editMode.moveModal.bottomInfo':
    'Valittu {orderRowCount} kpl tilausrivejä siirrettäväksi, mukana siirtyvät näihin linkitetyt tavoiterivit ({targetRowCount} kpl)',

  'order.excelImport.importRows': 'Rivien tuonti',
  'order.excelImport.instructions':
    'Syötä alla olevaan tekstikenttään taulukkolaskentaohjelmasta kopioidut tilausrivit. Rivien määrää ei ole rajattu. Jokaisella rivillä tulee olla vain seuraavat solut (järjestyksessä): otsikko, määrä, yksikkö, yksikköhinta.',
  'order.excelImport.error': 'Syötetyissä tiedoissa on virheitä.',
  'order.excelImport.createRows': 'Luo rivit',
  'order.excelImport.previewTable.error':
    'Viallinen rivi. Varmista, että rivi vastaa yllä olevaa ohjeistusta.',

  'order.receiveMode.showHandled': 'Näytä käsitellyt',
  'order.receiveMode.hideHandled': 'Piilota käsitellyt',
  'order.receiveMode.exit': 'Poistu vastaanottotilasta',
  'order.receiveMode.arrival': 'vastaanotto',
  'order.receiveMode.unitPriceMissing': 'Riviltä puuttuu hinta.',

  'order.receiveMode.invoice.add': 'Lisää hankintaan ja vastaanota',
  'order.receiveMode.cost.move': 'Siirrä toiseen hankintaan',
  'order.receiveMode.invoice.manualEntry': 'Luo manuaalitiliöintirivi',

  'order.receiveMode.invoice.delegate': 'Lisää tarkastaja',
  'order.receiveMode.invoice.delegate.OpenInvoice': 'Avaa lasku',
  'order.receiveMode.invoice.delegate.inProgress': 'Avoinna',
  'order.receiveMode.invoice.delegate.accepted': 'Hyväksytty',
  'order.receiveMode.invoice.delegate.rejected': 'Hylätty',
  'order.receiveMode.invoice.delegate.ignored': 'En tiedä',
  'order.receiveMode.invoice.delegate.reviewRequestFromMe': 'Tarkistuspyynnöt',
  'order.receiveMode.invoice.delegate.reviewRequestToMe':
    'Tarkistuspyyntö ({assignerName})',
  'order.receiveMode.invoice.delegate.modal.title':
    'Lähetä laskusta tarkistuspyyntö',
  'order.receiveMode.invoice.delegate.modal.selectReviewers':
    'Valitse tarkastajat',
  'order.receiveMode.invoice.delegate.modal.description': 'Saateteksti',
  'order.receiveMode.invoice.delegate.modal.placeholder':
    'Kirjoita viesti tarkistajalle',
  'order.receiveMode.invoice.delegate.denyReason':
    'Kerro vielä miksi lasku ei ole ok?',
  'order.receiveMode.invoice.delegate.isInvoiceOkayText': 'Onko lasku ok?',

  'order.receiveMode.invoice.delegate.denyModalHeader': 'Laskun tarkastus',
  'order.receiveMode.invoice.decline': 'Hylkää lasku',
  'order.receiveMode.invoice.fileComplaint': 'Aseta reklamoiduksi',
  'order.receiveMode.invoice.cancelComplaint': 'Peru reklamaatio',
  'order.receiveMode.invoice.startCorrection': 'Aloita käsittelyn korjaaminen',
  'order.receiveMode.invoice.finishCorrection': 'Korjaa',
  'order.receiveMode.invoice.isOpen': 'Lasku on auki',
  'order.receiveMode.invoice.isClosed': 'Lasku on suljettu',
  'order.receiveMode.invoice.amount': 'Laskun summa:',
  'order.receiveMode.invoice.amountInclVAT': 'Verollinen summa:',
  'order.receiveMode.invoice.image.inNewTab':
    'Avaa laskun kuva uuteen välilehteen',
  'order.receiveMode.invoice.noArrivalRows':
    'Laskulle ei ole vielä kohdistettu rivejä.',
  'order.receiveMode.invoice.attachemnt.deletable': 'Olemassa olevat liitteet:',
  'order.receiveMode.invoice.attachemnt.new': 'Lisätyt liitteet:',
  'order.receiveMode.invoice.attachment.upload': 'Lisää laskun liite',
  'order.receiveMode.invoice.attachment.upload.success':
    'Liitteiden lähetys onnistui!',
  'order.receiveMode.invoice.attachment.upload.dragdrop':
    'Pudota tiedostoja tähän tai',
  'order.receiveMode.invoice.attachment.upload.button': 'Valitse tiedostot',
  'order.receiveMode.invoice.attachment.upload.format':
    'Tiedostomuoto PDF, maksimikoko 10 Mt.',
  'order.receiveMode.invoice.spread.arrival.rows': 'Jaa koodeittain',

  'order.receiveMode.invoiceTable.sectionTitle': 'Laskut',

  'order.receiveMode.moveInvoiceModal.errorModal':
    'Laskuun liittyvää hankintaa ja/tai hankintakokonaisuutta ei ole saatavilla, yritä myöhemmin uudelleen.',
  'order.receiveMode.moveCostModal.title': 'Siirrä lasku toiseen hankintaan',
  'order.receiveMode.moveCostModal.amount': 'Summa:',
  'order.receiveMode.moveCostModal.filterPlaceholder':
    'Hae hankinnan numerolla, urakoitsijan nimellä, tai hakusanalla...',
  'order.receiveMode.moveCostModal.moveToOrder': 'Siirrä',
  'order.receiveMode.moveCostModalTH.name': 'Nimi',
  'order.receiveMode.moveCostModalTH.visibleCode': 'Hankinnan koodi',
  'order.receiveMode.moveCostModalTH.contractor': 'Toimittaja',

  'order.receiveMode.actualCost.add': 'Lisää hankintaan ja vastaanota',
  'order.receiveMode.actualCost.isOpen': 'Kulu on auki',
  'order.receiveMode.actualCost.isClosed': 'Kulu on suljettu',
  'order.receiveMode.actualCost.amount': 'Kulun summa:',
  'order.receiveMode.actualCost.noArrivalRows':
    'Kululle ei ole vielä kohdistettu rivejä.',
  'order.receiveMode.actualCost.sectionTitle': 'Muut kulut',
  'order.receiveMode.actualCost.image.inNewTab':
    'Avaa kulun kuva uuteen välilehteen',
  'order.receiveMode.actualCost.rehandle': 'Käsittele uudelleen',

  'order.receiveMode.arrivalRow.missingOrderRow': 'Puuttuva tilausrivi',
  'order.receiveMode.arrivalRow.checkBoxLabel': 'Valittu vastaanotettavaksi',
  'order.receiveMode.arrivalRow.notSelectedLabel': 'Ei valittu',
  'order.receiveMode.arrivalRow.deleteButtonLabel': 'Poista vastaanottorivi',

  'order.receiveMode.arrivalRowTH.invoiceDate': 'Eräpäivä',
  'order.receiveMode.arrivalRowTH.invoiceDate.short': 'Eräpv',
  'order.receiveMode.arrivalRowTH.actualCostDate': 'Kirjauspäivä',
  'order.receiveMode.arrivalRowTH.actualCostDate.short': 'Kirjaus',
  'order.receiveMode.arrivalRowTH.chooseRow': 'Valitse rivi',
  'order.receiveMode.arrivalRowTH.chooseAll':
    'Valitse kaikki vastaanotettavaksi',
  'order.receiveMode.arrivalRowTH.arrivalAbbreviation': 'VO',
  'order.receiveMode.arrivalRowTH.rowNo': 'Rivi',
  'order.receiveMode.arrivalRowTH.status': 'Laskun tila',
  'order.receiveMode.arrivalRowTH.statusShort': 'Tila',
  'order.receiveMode.arrivalRowTH.supplier': 'Toimittaja',
  'order.receiveMode.arrivalRowTH.procurement': 'Hankinta',
  'order.receiveMode.arrivalRowTH.invoiceAmount': 'Laskun summa',
  'order.receiveMode.arrivalRowTH.quantity': 'Määrä',
  'order.receiveMode.arrivalRowTH.workSection': 'Työvaihe',
  'order.receiveMode.arrivalRowTH.workSection.short': 'Vaihe',
  'order.receiveMode.arrivalRowTH.unit': 'Yks.',
  'order.receiveMode.arrivalRowTH.unitPrice': 'Yksikköhinta',
  'order.receiveMode.arrivalRowTH.amount': 'Tiliöity summa',
  'order.receiveMode.arrivalRowTH.date': 'Pvm',
  'order.receiveMode.arrivalRowTH.vatCode': 'Verokoodi',
  'order.receiveMode.arrivalRowTH.accountCode': 'Kirjanpitotili',
  'order.receiveMode.arrivalRowTH.options': 'Toiminnot',

  'order.receiveMode.arrivalRowOrderGroup.receivedInProcurement':
    'Vastaanotettu hankinnassa',
  'order.receiveMode.arrivalRowOrderGroup.receivedInProcurement.another':
    'Vastaanotettu toisessa hankinnassa',

  'order.receiveMode.assignmentTool.assign': 'Kohdista',
  'order.receiveMode.assignmentTool.rows': 'riviä,',
  'order.receiveMode.assignmentTool.amount': 'summa',
  'order.receiveMode.assignmentTool.unAssigned': 'Kohdistamaton',
  'order.receiveMode.assignmentTool.toActualCost': 'Kululle',
  'order.receiveMode.assignmentTool.toInvoice': 'Laskulle',

  'order.receiveMode.complaintModal.sendEmailToSupplier':
    'Lähetä toimittajalle sähköposti',
  'order.receiveMode.complaintModal.success.saved': 'Reklamaatio tallennettu',
  'order.receiveMode.complaintModal.success': 'Reklamaatio lähetetty',
  'order.receiveMode.complaintModal.success.email.error':
    'Reklamaatio tallennettu, mutta sähköpostin lähetys epäonnistui',
  'order.receiveMode.complaintModal.title': 'Reklamoi laskusta',
  'order.receiveMode.complaintModal.information': `Laskun voi käsitellä normaalisti reklamoidussa tilassa. Tilan voi poistaa valitsemalla 'Poista reklamointi' edellisestä valikosta.`,
  'order.receiveMode.complaintModal.receiver': 'Vastaanottaja: *',

  'order.receiveMode.complaintModal.response': 'Lähetä vastaus osoitteeseen: *',
  'order.receiveMode.complaintModal.copyAddress': 'Lähetä kopio osoitteeseen:',
  'order.receiveMode.complaintModal.reason': 'Anna reklamoinnin syy: *',
  'order.receiveMode.complaintModal.emailTitle': 'Otsikko: *',
  'order.receiveMode.complaintModal.emailContent': 'Viesti: *',
  'order.receiveMode.complaintModal.attachments': 'Liitteet:',

  'order.receiveMode.complaintModal.formField.placeholder.supplier':
    'Kirjoita toimittajan sähköpostiosoite',
  'order.receiveMode.complaintModal.formField.placeholder.cc':
    'Kirjoita sähköpostiosoite kopiolle',
  'order.receiveMode.complaintModal.formField.placeholder.response':
    'Kirjoita sähköpostiosoite vastaukselle',
  'order.receiveMode.complaintModal.formField.placeholder.reason':
    'Valitse syy',
  'order.receiveMode.complaintModal.formField.placeholder.emailTitle':
    'Kirjoita aihe',
  'order.receiveMode.complaintModal.formField.placeholder.emailContent':
    'Kirjoita viesti',
  'order.receiveMode.complaintModal.send.success':
    'Reklamaatio lähetetty onnistuneesti',
  'order.receiveMode.complaintModal.send.failure':
    'Reklamaation lähettäminen epäonnistui',
  'order.receiveMode.complaintModal.fileUpload.note':
    '(Huom. Laskun kuva lähtee liitteenä automaattisesti)',

  'order.receiveMode.declineInvoiceModal.confirmation':
    'Haluatko varmasti hylätä laskun?',
  'order.receiveMode.declineInvoiceModal.information':
    'Lasku siirtyy taloustiimille hylkäämisen jälkeen.',
  'order.receiveMode.declineInvoiceModal.reason': 'Anna hylkäämisen syy:',

  'order.receiveMode.invoiceCorrection.formField.required.receiver':
    'Vastaanottajan osoite on pakollinen',
  'order.receiveMode.invoiceCorrection.formField.required.response':
    'Vastausosoite on pakollinen',
  'order.receiveMode.invoiceCorrection.formField.required.reason':
    'Reklamaation syy on pakollinen',
  'order.receiveMode.invoiceCorrection.formField.required.emailTitle':
    'Sähköpostin otsikko on pakollinen',
  'order.receiveMode.invoiceCorrection.formField.required.emailContent':
    'Sähköpostin sisältö on pakollinen',

  'order.receiveMode.invoiceCorrection.confirmation':
    'Haluatko asettaa laskun käsittelyn korjattavaksi?',
  'order.receiveMode.invoiceCorrection.information':
    'Laskun käsittelyn korjatut tiedot siirtyvät taloustiimille korjauksen jälkeen',
  'order.receiveMode.invoiceCorrection.reason': 'Anna korjauksen syy:',

  'order.receiveMode.actualCostRehandle.confirmation':
    'Haluatko asettaa kulun uudelleen käsiteltäväksi?',
  'order.receiveMode.actualCostRehandle.information':
    'Korjatut tiedot siirtyvät taloustiimille korjauksen jälkeen',
  'order.receiveMode.comment': 'Kommentti:',

  'order.receiveMode.deleteMultipleArrivalRows': 'Poista valitut vastaanotot',
  'order.receiveMode.processingTool.process': 'Käsittele',
  'order.receiveMode.invoice.success.notification':
    'Lasku käsitelty. Voit katsoa käsitellyt laskut ',
  'order.receiveMode.invoice.success.notification.link': 'avaamalla käsitellyt',
  'order.receiveMode.actualCost.success.notification':
    'Kulu käsitelty. Voit katsoa käsitellyt laskut ',
  'order.receiveMode.actualCost.success.notification.link':
    'avaamalla käsitellyt',

  'order.receiveMode.invoiceStatus.notHandled': 'Käsittelemättä',
  'order.receiveMode.invoiceStatus.handled': 'Käsitelty {dateAndUser}',
  'order.receiveMode.invoiceStatus.canceled': 'Peruttu {dateAndUser}',
  'order.receiveMode.invoiceStatus.paid': 'Maksettu {dateAndUser}',
  'order.receiveMode.invoiceStatus.approved': 'Hyväksytty {dateAndUser}',
  'order.receiveMode.invoiceStatus.complaintPending':
    'Reklamoitu {dateAndUser}',
  'order.receiveMode.invoiceStatus.beingCorrected':
    'Korjauksessa {dateAndUser}',
  'order.receiveMode.invoiceStatus.corrected': 'Korjattu {dateAndUser}',
  'order.receiveMode.invoiceStatus.declined': 'Hylätty {dateAndUser}',

  'order.receiveMode.unassignedArrivalRows.searchPlaceholder':
    'Etsi laskuista...',

  'order.receiveMode.unassignedArrivalRows.sectionTitle':
    'Kohdistamattomat vastaanotot ― ',

  'order.receiveMode.utils.invoiceIsProcessable':
    'Lasku {identifier} on käsiteltävissä',
  'order.receiveMode.utils.invoiceTooManyArrivals':
    'Laskulle {identifier} on kohdistettu liikaa vastaanottoja ({handleNetAmountPending})',
  'order.receiveMode.utils.invoiceTooFewArrivals':
    'Lasku {identifier} ei ole käsiteltävissä, koska laskulle on kohdistettu liian vähän vastaanottoja ({handleNetAmountPending})',
  'order.receiveMode.utils.invoiceNoVatCode':
    'Lasku {identifier} ei ole käsiteltävissä, koska verokoodi puuttuu tiliöinnistä',
  'order.receiveMode.utils.invoiceNoWorkSection':
    'Lasku {identifier} ei ole käsiteltävissä, koska siihen on kohdistettu rivejä, joilta puuttuu työvaihe',
  'order.receiveMode.utils.invoiceNoCostType':
    'Lasku {identifier} ei ole käsiteltävissä, koska kustannuslaji puuttuu',
  'order.receiveMode.utils.undefinedHandlingError':
    'Lasku {identifier} ei ole käsiteltävissä, ota yhteys ylläpitoon',
  'order.receiveMode.utils.invoiceNoAccountCode':
    'Lasku {identifier} ei ole käsiteltävissä, koska kirjanpitotili puuttuu tiliöinnistä',
  'order.receiveMode.utils.invoiceNoCodes':
    'Lasku {identifier} ei ole käsiteltävissä, koska kirjanpitotili ja verokoodi puuttuvat tiliöinnistä',
  'order.receiveMode.utils.invoiceIncorrectVatAmount':
    'Lasku {identifier} ei ole käsiteltävissä, koska tiliöintien ALV:n osuus eroaa laskulla ilmoitetusta ALV:n osuudesta {handleVatAmountPending} verran',
  'order.receiveMode.utils.invoiceIsDeclined': 'Lasku {identifier} on hylätty',
  'order.receiveMode.utils.invoiceHasComplaintPending':
    'Laskusta {identifier} on tehty reklamaatio',
  'order.receiveMode.utils.invoiceBeingCorrected':
    'Laskun käsittely {identifier} on korjauksessa',
  'order.receiveMode.utils.invoiceCorrected':
    'Käyttäjä on korjannut laskun {identifier} käsittelyä jälkikäteen',
  'order.receiveMode.utils.invoiceIsProcessed':
    'Lasku {identifier} on käsitelty',
  'order.receiveMode.utils.invoiceIsPaid': 'Lasku {identifier} on maksettu',
  'order.receiveMode.utils.invoiceIsApproved':
    'Lasku {identifier} on hyväksytty',
  'order.receiveMode.utils.actualCostIsProcessable':
    'Kulu {identifier} on käsiteltävissä',
  'order.receiveMode.utils.actualCostTooManyArrivals':
    'Kululle {identifier} on kohdistettu liikaa vastaanottoja ({handleNetAmountPending})',
  'order.receiveMode.utils.actualCostTooFewArrivals':
    'Kulu {identifier} ei ole käsiteltävissä, koska kululle on kohdistettu liian vähän vastaanottoja ({handleNetAmountPending})',
  'order.receiveMode.utils.actualCostIsProcessed':
    'Kulu {identifier} on käsitelty',
  'order.receiveMode.utils.actualCostIsCanceled':
    'Kulu {identifier} on peruttu kirjanpidon toimesta',

  'order.receiveMode.utils.invoiceNoArrivalRows':
    'Lasku {identifier} ei ole käsiteltävissä, koska siihen ei ole vielä kohdistettu rivejä.',
  'order.receiveMode.utils.actualCostNoArrivalRows':
    'Kulu {identifier} ei ole käsiteltävissä, koska siihen ei ole vielä kohdistettu rivejä.',

  'order.receiveMode.notification.popUps.blocked':
    'Kaikkia liitteitä ei voitu avata uusille välilehdille. Salli sivuston pop-up-ikkunat selaimen asetuksista.',

  'order.targetMode.toolBelt.addTargetRows': 'Luo tavoiterivejä',
  'order.targetMode.toolBelt.splitTargetRows': 'Jaa tavoiterivejä',
  'order.targetMode.toolBelt.addOrderRows': 'Luo tilausrivejä tavoitteesta',
  'order.targetMode.toolBelt.move': 'Siirrä {count} valittua tavoiteriviä',
  'order.targetMode.toolBelt.move.one': 'Siirrä {count} valittu tavoiterivi',
  'order.targetMode.toolBelt.divide': 'Jaa tavoitetta',

  'order.targetMode.splitIcon.tooltip.disabled':
    'Rivi on jaettu: \n{targetRowInfo} \nja näytetään vain lisätietona',
  'order.targetMode.splitIcon.tooltip.splitFrom':
    'Rivi on syntynyt tavoiterivin jakamisesta: \n{targetRowInfo}',
  'order.targetMode.moveToTargetView.tooltip': 'Siirry tavoitenäkymään',

  'order.addTargetRowsModal.requiredField': 'Pakollinen kenttä',
  'order.addTargetRowsModal.button.addNewRow': 'Lisää uusi rivi',
  'order.addTargetRowsModal.button.addNewRow.titleDisabled':
    'Suurin sallittu määrä kerralla lisättäviä rivejä on {maxRows}',
  'order.addTargetRowsModal.button.submit': 'Luo rivit',
  'order.addTargetRowsModal.placeholder.description': 'Lisää kuvaus',
  'order.addTargetRowsModal.placeholder.quantity': 'Määrä',
  'order.addTargetRowsModal.placeholder.unit': 'Yksikkö',
  'order.addTargetRowsModal.placeholder.unitPrice': 'Yksikköhinta',
  'order.addTargetRowsModal.placeholder.order': 'Valitse hankinta...',
  'order.addTargetRowsModal.placeholder.topic': 'Valitse aihe...',
  'order.addTargetRowsModal.placeholder.analysis': 'Valitse erittely...',
  'order.addTargetRowsModal.tableHeader.none': ' ',
  'order.addTargetRowsModal.tableHeader.description': 'Kuvaus',
  'order.addTargetRowsModal.tableHeader.quantity': 'Määrä',
  'order.addTargetRowsModal.tableHeader.unit': 'Yksikkö',
  'order.addTargetRowsModal.tableHeader.unitPrice': 'Yksikköhinta',
  'order.addTargetRowsModal.tableHeader.selectOrder': 'Valitse hankinta: *',
  'order.addTargetRowsModal.tableHeader.selectTopic': 'Valitse aihe: *',
  'order.addTargetRowsModal.tableHeader.selectAnalysis': 'Valitse erittely:',
  'order.addTargetRowsModal.tableHeader.alsoCreateOrderRows':
    'Luo myös tilausrivit',
  'order.addTargetRowsModal.title': 'Luo uusi tavoiterivi',
  'order.addTargetRowsModal.targetRowsSum': 'Uudet tavoiterivit yhteensä',
  'order.addTargetRowsModal.orderRowsSum': 'Uudet tilausrivit yhteensä',

  'order.targetSplitModal.title': 'Jaa valitut tilaus- ja tavoiterivit',
  'order.targetSplitModal.title.remark':
    '(Jaettua tavoiteriviä ei voi poistaa myöhemmin)',
  'order.targetSplitModal.withoutOrderRowsTable.title':
    'Valitut tavoiterivit ilman tilausrivejä',
  'order.targetSplitModal.withOrderRowsTable.title':
    'Valitut tavoite- ja tilausrivit',
  'order.targetSplitModal.targetRowsSum': 'Tavoiterivien summa',
  'order.targetSplitModal.orderRowsSum': 'Tilausrivien summa',
  'order.targetSplitModal.remainingAmount':
    'Kaikkea tavoitetta ei ole jaettu (Jäljellä {remainingAmount})',
  'order.targetSplitModal.remainingAmount.row': 'Jakamatta {remainingAmount}',

  'order.targetSplitModal.tooMuchArrivals':
    'Tilausriveillä on liikaa vastaanottoja, joita ei voida jakaa. Lisää tilausrivin summaa tai poista vastaanottoja.',

  'order.targetSplitModal.tooltip.orderRowTotal':
    'Jaettavan tilausrivin kokonaissumma: {orderRowAmount}',
  'order.targetSplitModal.tooltip.tooMuchArrivals':
    'Siirrettävä summa {toBeSplitAmount} ei saa ylittää tilausrivin ({orderRowAmount}) ja vastaanotetun määrän ({arrivalAmount}) erotusta ({difference}).',

  'order.targetSplitModal.tooltip.newOrderRow.total':
    'Syntyvän tilausrivin summa: {orderRowAmount}',

  'order.targetSplitModal.tableHeader.none': ' ',
  'order.targetSplitModal.tableHeader.description': 'Kuvaus',
  'order.targetSplitModal.tableHeader.description2': ' ',
  'order.targetSplitModal.tableHeader.quantity': 'Määrä',
  'order.targetSplitModal.tableHeader.unit': 'Yksikkö',
  'order.targetSplitModal.tableHeader.unitPrice': 'Yksikköhinta',
  'order.targetSplitModal.tableHeader.selectOrder': 'Valitse hankinta: *',
  'order.targetSplitModal.tableHeader.selectTopic': 'Valitse aihe: *',
  'order.targetSplitModal.tableHeader.selectAnalysis': 'Valitse erittely:',
  'order.targetSplitModal.tableHeader.total': 'Yhteensä',

  'order.targetSplitModal.button.addRest': 'Lisää jakamatta oleva osuus',

  'order.targetSplitModal.button.submit': 'Jaa rivit',

  'order.targetMode.moveModal.title':
    'Siirrä valitut tavoiterivit ({amount} kpl, yht. {totalPrice})',
  'order.targetMode.moveModal.filterLabel': 'Hae sisältöä:',
  'order.targetMode.moveModal.filterPlaceHolder':
    'Hae tilausnumerolla, kuvauksella tai toimittajalla...',

  'order.targetMode.moveModal.scrollListTitle': 'Valitse hankinta',

  'order.targetMode.moveModal.dropdownLabel': 'Siirrä aiheelle:',
  'order.targetMode.moveModal.dropdownPlaceholder': 'Valitse aihe...',

  'order.targetMode.moveModal.button.action': 'Siirrä aiheelle',

  'order.targetMode.moveModal.bottomInfo':
    'Huomioi, että pelkät tavoiterivit siirtyvät. Voit halutessasi lisätä tilausrivejä erikseen.',

  'order.manualEntryModal.header': 'Luo manuaalitiliöintirivi laskulle',

  'order.manualEntryModal.allocateToOtherProject':
    'Kohdista toiselle projektille',
  'order.manualEntryModal.allocateToOtherOrder':
    'Kohdista toiselle hankinnalle',
  'order.manualEntryModal.withoutAccount': 'Laskulla tiliöimättä',
  'order.manualEntryModal.sum': 'Tiliöitävä nettosumma',
  'order.manualEntryModal.quantityRemainingToBeReceived':
    'Vastaanotettavaa jäljellä',
  'order.manualEntryModal.receive.quantity': 'Vastaanotettava määrä',
  'order.manualEntryModal.submit': 'Luo rivi',
  'order.manualEntryModal.linkToProcurement': 'Muokkaa valittua hankintaa',
  'order.manualEntryModal.vatCode': 'Verokoodi {required}',
  'order.manualEntryModal.account': 'Kirjanpitotili {required}',
  'order.manualEntryModal.requiredField': 'Pakollinen kenttä',
  'order.manualEntryModal.sumTooLarge':
    'Annetun summan tulee olla pienempi kuin laskulla on tiliöimättä',
  'order.manualEntryModal.hasToBeNumber':
    'Syötä kenttään numero, desimaaliosa pilkulla erotettuna',
  'order.manualEntryModal.hasToBePositive':
    'Annetun summan tulee olla positiivinen',
  'order.manualEntryModal.isProjectDimension.tooltip':
    'Projektikoodi {projectCode} ei valittavissa, käytä tarvittaessa hankintojen välistä kohdistusta',

  'order.invoicesPerOrderRowModal.title':
    '{orderRowNo} {orderRowDescription}, vastaanotettu yhteensä: {arrivalTotal}',

  'order.invoicesPerOrderRowModal.table.header.timestamp': 'Aika',
  'order.invoicesPerOrderRowModal.table.header.user': 'Vastaanottaja',
  'order.invoicesPerOrderRowModal.table.header.amount': 'Summa',
  'order.invoicesPerOrderRowModal.table.header.invoice': 'Lasku',
  'order.invoicesPerOrderRowModal.table.header.link': 'Siirry laskulle',

  'order.invoicesPerOrderRowModal.table.linkIcon.alt':
    'Linkki laskuun tai muuhun kuluun',

  'order.options.formik.error.name.mandatory': 'Pakollinen kenttä',
  'order.options.formik.error.name.length': 'Maksimipituus on 200 merkkiä',
  'order.options.formik.error.code.procurementAreaMatch':
    'Koodin pitää alkaa samoilla merkeillä kuin hankintakokonaisuus',
  'order.options.formik.error.code.length': 'Maksimipituus on 10 merkkiä',
  'order.options.formik.error.code.unique':
    'Koodin pitää olla uniikki projektin sisällä',
  'order.options.formik.error.code.mandatory': 'Pakollinen kenttä',
  'order.options.formik.error.code.onlyLettersAndNumbersAndDashes':
    'Vain kirjaimet, numerot ja väliviivat (-) sallittuja',

  'order.options.header.new': 'Luo uusi hankinta',
  'order.options.header.tooltip':
    'Tilausviite luodaan automaattisesti. Käytä numeroa tilattaessa ja pyydä laskuttajaa lisäämään se laskulle viitteeksi. Näin lasku saadaan kohdistumaan tähän hankintaan.',
  'order.options.header.edit': 'Hankinnan perustiedot',
  'order.options.secondHeader.edit': 'Tilausviite',
  'order.options.secondHeader.tooltip':
    'Käytä viitettä tilattaessa ja pyydä laskuttajaa lisäämään se laskulle viitteeksi. Näin lasku saadaan kohdistumaan tähän hankintaan.',
  'order.options.header.edit.open': 'Perustiedot on auki',
  'order.options.header.edit.closed': 'Perustiedot on kiinni',
  'order.options.visibleCode': 'Hankinnan koodi',
  'order.options.name': 'Hankinnan nimi',
  'order.options.supplier': 'Toimittaja',
  'order.options.costType': 'Kustannuslaji',
  'order.options.vatCode': 'ALV-verokoodi',
  'order.options.account': 'Kirjanpidon tili',
  'order.options.status': 'Hankinnan status',
  'order.options.header.delete': 'Hankinnan poistaminen',
  'order.options.header.delete.open': 'Poistovalikko on auki',
  'order.options.header.delete.closed': 'Poistovalikko on kiinni',
  'order.options.deleteButton': 'Poista hankinta',
  'order.options.cannotBeDeleted':
    'Hankintaa ei voida poistaa. Siirrä / poista tilausrivit ja tavoiterivit sekä raportoi projekti ennen poistamista',

  'order.invoiceLines.addButton': 'Lisää valitut tilausriveiksi',
  'order.invoiceLines.headerTitle': 'Laskun sähköiset rivit',
  'order.invoiceLines.chooseAll': 'Valitse kaikki',
  'order.invoiceLines.header.chooseRow': 'Valitse rivi',
  'order.invoiceLines.header.rowNo': '#',
  'order.invoiceLines.header.productCode': 'Tuote',
  'order.invoiceLines.header.description': 'Kuvaus',
  'order.invoiceLines.header.quantity': 'Määrä',
  'order.invoiceLines.header.unit': 'Yks',
  'order.invoiceLines.header.netPrice': 'Hinta',
  'order.invoiceLines.header.netTotal': 'Nettosumma',
  'order.invoiceLines.header.VAT': 'Vero',
  'order.invoiceLines.header.orderRow': 'Valittu tilausrivi',
  'order.invoiceLines.row.selectRow': 'Valitse rivi',
  'order.invoiceLines.row.notSelectedLabel': 'Ei valittu',
  'order.invoiceLines.row.newOrderRow': 'Uusi tilausrivi',
  'order.invoiceLines.row.newOrderRows':
    'Luo uusi tilausrivi jokaiselle laskun riville',
  'order.invoiceLines.row.newOrderRowShortLabel': 'Uusi',
  'order.invoiceLines.row.tooltip.received':
    'Rivi on jo vastaanotettu ({orderRowDescription})',
  'order.invoiceLines.group.unassigned': 'Ei kohdistusta',
  'order.invoiceLines.group.assigned': 'Laskunrivi ryhmitelty',
  'order.invoiceLines.group.assigned.after': 'alle',
  'order.invoiceLines.selected': '{count} laskuriviä valittu, yhteensä {sum}',

  'order.actualCostLines.addButton': 'Lisää valitut tilausriveiksi',
  'order.actualCostLines.headerTitle': 'Muun kulun sähköiset rivit',
  'order.actualCostLines.chooseAll': 'Valitse kaikki',
  'order.actualCostLines.header.chooseRow': 'Valitse rivi',
  'order.actualCostLines.header.rowNo': '#',
  'order.actualCostLines.header.description': 'Kuvaus',
  'order.actualCostLines.header.quantity': 'Määrä',
  'order.actualCostLines.header.unit': 'Yks',
  'order.actualCostLines.header.unitPrice': 'Yksikköhinta',
  'order.actualCostLines.header.netTotal': 'Nettosumma',
  'order.actualCostLines.header.orderRow': 'Valittu tilausrivi',
  'order.actualCostLines.row.selectRow': 'Valitse rivi',
  'order.actualCostLines.row.notSelectedLabel': 'Ei valittu',
  'order.actualCostLines.row.newOrderRow': 'Uusi tilausrivi',
  'order.actualCostLines.row.newOrderRows':
    'Luo uusi tilausrivi jokaiselle laskun riville',
  'order.actualCostLines.row.newOrderRowShortLabel': 'Uusi',
  'order.actualCostLines.row.tooltip.received':
    'Rivi on jo vastaanotettu ({orderRowDescription})',
  'order.actualCostLines.group.unassigned': 'Ei kohdistusta',
  'order.actualCostLines.group.assigned': 'Laskunrivi ryhmitelty',
  'order.actualCostLines.group.assigned.after': 'alle',

  'order.invoice.no.image': 'Ei laskun kuvaa',
  'order.invoice.attachment.selector.tooltip': 'Selaile laskun liitteitä',

  'order.invoiceLines.convertModal.title':
    'Laskun {vendorInvoiceNo} ({amount}) sähköisten rivien vastaanoton yhteenveto',
  'order.invoiceLines.convertModal.table.header.orderRow.rowNo': ' ',
  'order.invoiceLines.convertModal.table.header.orderRow.description': 'Kuvaus',
  'order.invoiceLines.convertModal.table.header.orderRow.quantity': 'Määrä',
  'order.invoiceLines.convertModal.table.header.orderRow.amount': 'Summa',
  'order.invoiceLines.convertModal.table.header.arrow': ' ',
  'order.invoiceLines.convertModal.table.header.arrivalRow.quantity': 'Määrä',
  'order.invoiceLines.convertModal.table.header.arrivalRow.amount': 'Summa',
  'order.invoiceLines.convertModal.table.header.orderRow.change': 'Muutos',
  'order.invoiceLines.convertModal.table.header.orderRow.amountLeft':
    'Jäljelle jää',
  'order.invoiceLines.convertModal.table.uppertierheader.orderRows':
    'Tilausrivit',
  'order.invoiceLines.convertModal.table.uppertierheader.arrivalRows':
    'Vastaanotto',
  'order.invoiceLines.convertModal.table.topic.open': 'Aihe on auki',
  'order.invoiceLines.convertModal.table.topic.closed': 'Aihe on kiinni',
  'order.invoiceLines.convertModal.table.arrow': 'Nuoli',
  'order.invoiceLines.convertModal.table.footer.totalShortHand': 'Yht.',
  'order.invoiceLines.convertModal.table.footer.total': 'Lasku yhteensä',
  'order.invoiceLines.convertModal.table.footer.difference':
    'vastaanottamatta jää',
  'order.invoiceLines.convertModal.newTopics': 'Uusien työvaiheiden aiheet',
  'order.invoiceLines.convertModal.button.action': 'Vastaanota',
  'order.invoiceLines.convertModal.button.invalidPrice':
    'Tilausrivin yksikköhinta ei voi olla 0€',
  'order.invoiceLines.convertModal.invalidData':
    'Virhe datassa, ota yhteys ylläpitoon',
  'order.invoiceLines.convertModal.button.noRows': 'Ei rivejä valittuna',
  'order.invoiceLines.convertModal.button.invalidInvoiceStatus':
    'Lasku on jo käsitelty',

  'order.actualCostLines.convertModal.title':
    'Kulun {entryNo} ({amount}) sähköisten rivien vastaanoton yhteenveto',
  'order.actualCostLines.convertModal.button.action': 'Vastaanota',
  'order.actualCostLines.convertModal.table.uppertierheader.orderRows':
    'Tilausrivit',
  'order.actualCostLines.convertModal.table.uppertierheader.arrivalRows':
    'Vastaanotto',

  'orderRow.status.Contract': 'Sopimus',
  'orderRow.status.Reserves': 'Varaus',
  'orderRow.status.ChangeOrder': 'LMT',
  'orderRow.inputs.SelectRow': 'Valitse rivi',
  'orderRow.inputs.rowNumber': 'Rivinumero',
  'orderRow.inputs.Title': 'Kuvaus',
  'orderRow.inputs.Amount': 'Määrä',
  'orderRow.inputs.Unit': 'Yksikkö',
  'orderRow.inputs.UnitPrice': 'Yksikköhinta',
  'orderRow.inputs.ProgressPill': 'Vastaanota loput',
  'order.inputs.Remove': 'Poista rivi',

  'order.topicRow.icon.alt': 'Linkki työvaiheen aiheeseen',
  'order.topicRow.Remove': 'Poista rivi',

  'procurement.header': 'Hankinnat',

  'project.info.profit': 'Kate:',
  'project.info.profitChange': 'Katemuutos edelliseen:',

  'project.tooltip.PoC':
    'Tämän hetken valmiusaste. Lasketaan Sitedriven aikataulutehtävien raportoidusta valmiusasteesta ja suhteellisesta työmäärästä',
  'project.tooltip.PoCDiff':
    'Erotus aiemmin raportoidusta kuluvalle kuukaudelle suunnitellusta valmiusasteesta',
  'project.tooltip.previousPoC':
    'Edellisen raportoinnin suunniteltu valmiusaste kuluvalle kuukaudelle',
  'project.tooltip.target': 'Kaikkien tavoiterivien summa ml. lisäykset',
  'project.tooltip.additionalTarget':
    'Tässä järjestelmässä lisättyjen ei-alkuperäisten tavoiterivien summa',
  'project.tooltip.differenceToTarget': 'Tavoitteen ja ennusteen erotus',
  'project.tooltip.costPlan':
    'Tilausrivien summa, arvio lopullisesta kokonaiskustannuksesta',
  'project.tooltip.differenceToReported':
    'Nykyisen ennusteen ja edellisen raportoidun ennusteen erotus',
  'project.tooltip.contract': 'Sopimus -statuksella olevat kustannukset',
  'project.tooltip.changeOrders':
    'Lisä- ja muutostyö -statuksella olevat kustannukset',
  'project.tooltip.reserves': 'Varaus -statuksella olevat kustannukset',
  'project.tooltip.accepted':
    'Vastaanotettu yhteensä, vähintään käsiteltyjen laskujen verran',
  'project.tooltip.remaining':
    'Ennuste vähennettynä vastaanotetuilla, jäljellä olevat kustannukset',
  'project.tooltip.unsettled': 'Käsittelemättömät laskut tai muut kustannukset',
  'project.tooltip.status': 'Hankinnan status',

  'project.procurementTable.secondaryHeader.text': 'Suodatettu summa',

  'project.procurementTable.order.status': 'Hankinnan status',
  'project.procurementTable.areas.open':
    'Kaikki hankintakokonaisuudet ovat avoinna',
  'project.procurementTable.areas.closed':
    'Kaikki hankintakokonaisuudet ovat suljettuna',

  'project.procurementTable.orders.asc':
    'Hankinnat koodin mukaan nousevassa järjestyksessä',
  'project.procurementTable.orders.desc':
    'Hankinnat koodin mukaan laskevassa järjestyksessä',
  'project.procurementTable.header.functions': 'Toiminnot',
  'project.procurementTable.order.number':
    'Hankintakokonaisuuden tai hankinnan numero',
  'project.procurementTable.order.title':
    'Hankintakokonaisuuden tai hankinnan otsikko',
  'project.procurementTable.order.target': 'Tavoite',
  'project.procurementTable.order.additionalTarget': 'Lisätavoite',
  'project.procurementTable.order.differenceToTarget': 'Ero tavoitteeseen',
  'project.procurementTable.order.prediction': 'Ennuste',
  'project.procurementTable.order.difference': 'Ennustemuutos',
  'project.procurementTable.order.contractTotal': 'Sopimus',
  'project.procurementTable.order.changeOrdersTotal': 'Lisä- ja muutostyöt',
  'project.procurementTable.order.reserves': 'Varaus',
  'project.procurementTable.order.receivedTotal': 'Vastaanotettu',
  'project.procurementTable.order.unsettled': 'Käsittelemättömät laskut',

  'project.procurementTable.procurementAreaRow': 'Lisää hankinta',
  'project.procurementTable.procurementAreaRow.open':
    'Hankintakokonaisuus on auki',
  'project.procurementTable.procurementAreaRow.closed':
    'Hankintakokonaisuus on suljettu',

  'target.table.header.empty': ' ',
  'target.table.header.spacer': ' ',
  'target.table.header.description': 'Kuvaus',
  'target.table.header.quantity': 'Määrä',
  'target.table.header.unit': 'Yksikkö',
  'target.table.header.unitPrice': 'Yksikköhinta',
  'target.table.header.target': 'Tavoitteen summa',
  'target.table.header.prediction': 'Ennusteen summa',
  'target.table.header.difference': 'Ero tavoitteeseen',

  'target.table.alt.suffix': 'Liittyvä tilausrivi',
  'target.table.selectionSummary.selectionTotal':
    'Valittujen rivien tavoite yhteensä',
  'target.table.selectionSummary.selectionTotal.noOrderRow':
    'Tilausriveihin linkkaamaton tavoite yhteensä',

  'reporting.header': 'Raportointi',
  'reporting.button.addNewSnapshot': 'Raportoi uusi',
  'reporting.table.header.toggleButton': ' ',
  'reporting.table.header.timeStamp': 'Raportointihetki',
  'reporting.table.header.reportingPeriod': 'Raportointikausi',
  'reporting.table.header.description': 'Kuvaus',
  'reporting.table.header.userName': 'Raportoija',
  'reporting.table.header.percentageOfCompletion': 'Valmiusaste',
  'reporting.table.header.targetChangeBeforeLocking':
    'Tavoitteen muutos edelliseen',
  'reporting.table.header.targetTotal': 'Tavoite',
  'reporting.table.header.additionalTargetTotal': 'Lisätavoite',
  'reporting.table.header.costPredictionTotal': 'Ennuste',
  'reporting.table.header.predictionChangeBeforeLocking':
    'Ennusteen muutos edelliseen',
  'reporting.table.header.contractTotal': 'Sopimus',
  'reporting.table.header.changeOrdersTotal': 'LMT',
  'reporting.table.header.reservesTotal': 'Varaukset',
  'reporting.table.header.revenueTotal': 'Tulot',
  'reporting.table.header.profit': 'Kate',
  'reporting.table.header.profitChange': 'Katemuutos',

  'reporting.table.snapshot.open.alt': 'Raportoitu vedos auki',
  'reporting.table.snapshot.closed.alt': 'Raportoitu vedos kiinni',
  'reporting.table.snapshot.order.link.alt': 'Linkki hankintaan',

  'reporting.table.snapshot.tooltip.planned':
    '* Perustuu suunniteltuun valmiusasteeseen ajankohdalle {timelineDate} (raportoitu {snapshotCreationDate})',
  'reporting.table.snapshot.tooltip.previouslyPlanned':
    '* Perustuu aiemman raportoinnin suunniteltuun valmiusasteeseen ajankohdalle {timelineDate} (raportoitu {snapshotCreationDate})',

  'reporting.addNewSnapshot.modal.header':
    'Uusi raportointi: {projectCodeName}',

  'reporting.addNewSnapshot.modal.table.header.sectionName': ' ',
  'reporting.addNewSnapshot.modal.table.header.current': 'Raportoidaan',
  'reporting.addNewSnapshot.modal.table.header.previous': 'Edellinen',
  'reporting.addNewSnapshot.modal.table.header.difference': 'Muutos',
  'reporting.addNewSnapshot.modal.table.header.icon': ' ',

  'reporting.addNewSnapshot.modal.description': 'Kuvaus',
  'reporting.addNewSnapshot.modal.reportingPeriod': 'Raportointikausi',
  'reporting.addNewSnapshot.modal.costPrediction': 'Ennuste',
  'reporting.addNewSnapshot.modal.costs': 'Kustannukset',
  'reporting.addNewSnapshot.modal.target': 'Tavoite',
  'reporting.addNewSnapshot.modal.contract': 'Sopimus',
  'reporting.addNewSnapshot.modal.changeOrders': 'LMT',
  'reporting.addNewSnapshot.modal.reserves': 'Varaukset',
  'reporting.addNewSnapshot.modal.revenue': 'Tulot',
  'reporting.addNewSnapshot.modal.profit': 'Kate',
  'reporting.addNewSnapshot.modal.percentageOfCompletion': 'Valmiusaste',
  'reporting.addNewSnapshot.modal.poc.actual': 'Toteuma',
  'reporting.addNewSnapshot.modal.poc.planned': 'Suunniteltu',
  'reporting.addNewSnapshot.modal.poc.previouslyPlanned':
    'Edellisen raportoidun suunniteltu',

  'reporting.addNewSnapshot.modal.pocRowTooltip':
    'Raportoitava valmiusaste riippuu alla olevasta valinnasta. Edellinen valmiusaste kuvastaa tälle kuukaudelle viimeksi raportoitua suunnitelmaa.',
  'reporting.addNewSnapshot.modal.pocRowTooltip.invalid':
    'Valmiusastetiedoissa on puutteita. Siirry tästä tarkistamaan työvaiheet, joissa esiintyy sama ikoni.',

  'reporting.addNewSnapshot.modal.differenceTooltip':
    'Ero edellisten raportoitujen lukujen ja nykytilanteen välillä',

  'reporting.addNewSnapshot.modal.poc.actualTooltip':
    'Kauden valmiusasteena käytetään aikataulutehtäville kirjattuja valmiusasteita. Raportin perustana on toteutuneen työn arvo.',
  'reporting.addNewSnapshot.modal.poc.plannedTooltip':
    'Kauden valmiusasteena käytetään aikataulutehtäville suunniteltuja valmiusasteita. Raportin perustana on suunniteltu työn arvo.',
  'reporting.addNewSnapshot.modal.poc.previouslyPlannedTooltip':
    'Kauden valmiusasteena käytetään aikataulutehtäville aiemmassa raportoinnissa suunniteltuja valmiusasteita. Ennuste pysyy siis samana, jos kaikki kustannukset ovat aikataulutettuja.',

  'reporting.addNewSnapshot.modal.poc.exceptionNote':
    'Tämä on poikkeustilanne, kirjoita syy tekstikenttään.',

  'reporting.addNewSnapshot.modal.linkToPoCBasicGraph': 'Valmiusastekuvaaja',
  'reporting.addNewSnapshot.modal.linkToPoCWorkSectionGraph':
    'Valmiusasteet vaiheittain',

  'reporting.addNewSnapshot.modal.linkToPoCTable': 'Yhteenvetotaulukko',

  'reporting.addNewSnapshot.modal.submitButton': 'Raportoi',

  'reporting.currentPeriod.orderSnapshots.sectionTitle':
    'Nykyinen (ei raportoitu)',

  'revenue.header': 'Tulot',
  'revenue.button.edit': 'Muokkaa',
  'revenue.toolbelt.button.addGroup': 'Lisää ryhmä',
  'revenue.toolbelt.button.moveRows': 'Siirrä rivejä',
  'revenue.summary.total': 'Tulot yhteensä: {amount}',
  'revenue.summary.billed': 'Laskutettu: {amount}',
  'revenue.summary.notBilled': 'Laskuttamatta: {amount}',

  'revenue.addGroup.form.header': 'Luo uusi maksueräryhmä',

  'revenue.addGroup.formik.paymentBatchGroup': 'Luokittelu',

  'revenue.addGroup.formik.error.mandatory': 'Pakollinen kenttä',
  'revenue.addGroup.formik.error.description.length': 'Liian pitkä kuvaus',
  'revenue.addGroup.formik.error.code.length': 'Liian pitkä koodi',

  'revenue.moveRows.modal.title':
    'Siirrä valitut rivit ({amount} kpl, yht. {totalPrice})',

  'revenue.moveRows.modal.dropdownLabel': 'Siirrä ryhmään:',
  'revenue.moveRows.modal.submitButton': 'Siirrä ryhmään',

  'revenue.table.header.select': ' ',
  'revenue.table.header.batchCode': 'Tunnus',
  'revenue.table.header.description': 'Kuvaus',
  'revenue.table.header.unitPrice': 'Yksikköhinta',
  'revenue.table.header.quantity': 'Määrä',
  'revenue.table.header.unit': 'Yksikkö',
  'revenue.table.header.vat': 'Alv-%',
  'revenue.table.header.netPrice': 'Nettosumma',
  'revenue.table.header.grossPrice': 'Bruttosumma',
  'revenue.table.header.billingDate': 'Laskutuspäivä',
  'revenue.table.header.actualizedBilling': 'Toteutunut laskutussumma',
  'revenue.table.header.analysis': 'Erittely',
  'revenue.table.header.status': 'Status',
  'revenue.table.header.attachment': 'Liite',
  'revenue.table.header.delete': ' ',

  'revenue.table.header.sort.ascending.alt': 'Nouseva järjestys',
  'revenue.table.header.sort.descending.alt': 'Laskeva järjestys',

  'revenue.table.group.remove': 'Poista ryhmä',

  'revenue.table.rows.addRow': 'lisää rivi',
  'revenue.table.rows.remove': 'Poista rivi',

  'revenue.table.mainRow.closed': 'Aihe on suljettu',
  'revenue.table.mainRow.open': 'Aihe on auki',

  'revenue.table.input.percentage.toolTip':
    'Syötä prosenttiarvo väliltä 0 - 100%.',
  'revenue.status.Preliminary': 'Alustava',
  'revenue.status.Planned': 'Suunniteltu',
  'revenue.status.Accepted': 'Hyväksytty',
  'revenue.status.Invoiced': 'Laskutettu',
  'revenue.status.Paid': 'Maksettu',
  'revenue.excelImport.numberOfRows': 'Tuodaan {numberOfNewRows} riviä',
  'revenue.excelImport.instructions':
    'Lisää Excelistä kopioidut rivit alla olevaan syöttökenttään. Rivien lukumäärää ei rajoiteta, mutta jokaisella kopioidulla rivillä tulee olla sarakekoodi, kuvaus, määrä, yksikkö, yksikköhinta, ALV, laskutuspäivä (dd.mm.yyyy) (samassa järjestyksessä)',

  'validation.number.toolTip':
    'Syötä kenttään numero, desimaaliosa pilkulla erotettuna. Suurin sallittu tarkkuus on neljä desimaalia.',
  'validation.string.length.toolTip':
    'Merkkijonon pituus ylittää enimmäispituuden {length} merkkiä.',
  'validation.unique.string.toolTip':
    'Syötä uniikki koodi. Syöttämäsi koodi on jo käytössä.',

  'validation.hasMaxNWholeNumbersPositive': 'Luku on liian suuri',
  'validation.hasMaxNWholeNumbersNegative': 'Luku on liian pieni',
  'validation.hasMaxNDecimals':
    'Suurin sallittu desimaalitarkkuus on neljä desimaalia',
  'validation.number.error':
    'Syötä kenttään numero, desimaaliosa pilkulla erotettuna',
  'validation.big.js.error': 'Muunnos numeroksi ei onnistunut',
  'validation.integer.error': 'Syötä kenttään kokonaisluku, ei desimaaleja',

  'validation.quantity.orderedLessThanReceived':
    'Tilattu määrä ei voi olla vähemmän kuin mitä on vastaanotettu',
  'validation.quantity.orderedMoreThanReceived':
    'Tilattu määrä ei voi olla enemmän kuin mitä on vastaanotettu',

  'validation.unknownField': 'Validoinnille tuntematon kenttä',
  'validation.invalidValue': 'Virheellinen arvo',

  'validation.invalidParent': 'Vanhemman tunnusta ei löytynyt datasta',
  'validation.invalidParentChildRelationship':
    'Lapsi ei voi olla vanhempansa vanhempi',
  'validation.parentIsDeleted':
    'Vanhemman tunnus oli merkattu poistetuksi, mutta lasta ei ollut merkattu poistetuksi',
  'validation.tooManyValuesFound': 'Liian monta eri arvoa löytyi',

  'validation.excessQuantity': `Vastaanotettavaa on jäljellä vain {quantityAndUnit}.
    Haluatko kasvattaa tilattua määrää {excessQuantityAndUnit} ({changeAmountEur})?`,
  'validation.emptyAmount': 'Määrä on tyhjä.',
  'validation.duplicateValue':
    'Syötä uniikki koodi. Syöttämäsi koodi on jo käytössä.',
  'validation.billingDate.error': 'Vain päivämäärä-arvo on sallittu',
  'validation.vat.error': 'Numeron tulee olla 0–100',

  'validation.maximumChange':
    'Voit korjata vastaanottoa korkeintaan vastaanoton kokonaismäärän ({arrivalQuantityAndUnit}).',

  'worksection.graph.title': 'Toteuma, tavoite ja ennuste',
  'worksection.workpackage.create': 'luo työvaihe',
  'worksection.workpackage.edit': 'Työvaiheen perustiedot',
  'worksection.workpackage.editModal.header':
    'Työvaiheen perustiedot: {code} - {name}',
  'worksection.workpackage.editModal.searchScheduleWorkPackages':
    'Hae työpaketin kuvauksella, alueella, tiimillä tai työvaiheluokalla...',
  'worksection.workpackage.editModal.linkScheduleWorkPackages':
    'Kohdista työpaketteja työvaiheeseen: "{code} {name}"',
  'worksection.workpackage.editModal.linkScheduleWorkPackages.linkButton':
    'Kohdista {count} työpakettia',
  'worksection.workpackage.editModal.linkScheduleWorkPackages.unLinkButton':
    'Irrota kaikki',
  'worksection.workpackage.editModal.notIncludedInPoc.tooltip':
    'Tämä työpaketti näytetään vain kontekstin vuoksi, sitä ei lasketa mukaan valmiusastelaskentaan.',
  'worksection.workpackage.delete': 'Poista työvaihe',
  'worksection.workpackage.delete.tooltip':
    'Poistaminen onnistuu vain, jos työvaiheeseen ei ole linkitettynä ennuste- tai tavoiterivejä ja se on raportoitu kertaalleen tyhjänä.',
  'worksection.workpackage.name': 'Työvaiheen nimi',
  'worksection.workpackage.code': 'Työvaiheen koodi',
  'worksection.workpackage.monitoring': 'Työvaiheen seuranta',
  'worksection.workpackage.monitoring.timespan': 'Seurataan aikavälinä',
  'worksection.workpackage.timespan.explanation':
    'Työvaiheen valmiusaste lasketaan lineaarisesti syötettyjen päivämäärien perusteella.',
  'worksection.workpackage.monitoring.linkedSchedule':
    'Seurataan töiden valmiutta',
  'worksection.workpackage.pocToday': 'Valmiusaste:',
  'worksection.workpackage.pocEOMonth':
    'Valmiusaste kuukauden loppuun mennessä:',
  'worksection.workpackage.linkedSchedule.explanation':
    'Työvaiheen valmiusaste lasketaan aikataulusta valittujen työpakettien perusteella. Jos työvaihekoodilla löytyy aikataulusta työpaketteja, ne kohdistetaan työvaiheeseen automaattisesti.',
  'worksection.workpackage.linkToWorkpackages':
    'Kohdista lisää työpaketteja...',
  'worksection.workpackage.category': 'Työvaiheen luokka',
  'worksection.workpackage.description': 'Työvaiheen kuvaus',
  'worksection.workpackage.scheduleWorkPackage': 'Työpaketti',
  'worksection.workpackage.noWorkpackagesAvailable':
    'Valitut kriteerit työpaketeille eivät vastaa yhtään työpakettia.',
  'worksection.workpackage.noWorkpackagesAvailable.body':
    'Varmista, että valittu työvaiheluokka on oikea ja että työpaketit aikataulussa on merkitty kuulumaan työvaiheluokkaan. Voit myös valita suoraan yksittäisiä työpaketteja tai seurata valmiutta aikavälin avulla.',
  'worksection.workpackage.noWorkpackagesAvailable.link': 'Siirry aikatauluun',
  'worksection.workpackage.noLinkages':
    'Ei työpaketteja linkitettynä. Valitse työvaiheluokka tai kohdista yksittäisiin työpaketteihin.',
  'worksection.workpackage.location': 'Alue',
  'worksection.workpackage.team': 'Tiimi',
  'worksection.workpackage.status': 'Status',
  'worksection.workpackage.startDate': 'Alkupäivä',
  'worksection.workpackage.endDate': 'Loppupäivä',
  'worksection.workpackage.disabledDatesInfo':
    'Aikataulutehtävien päivät käytössä',
  'worksection.workpackage.wpDatesTooltipInfo':
    'Aikataulutehtävien päivämääriä käytetään laskelmissa manuaalisesti syötettyjen päivämäärien sijaan',
  'worksection.workpackage.overlappingLinkages':
    'Työpaketti kohdistettu useaan työvaiheeseen',

  'worksection.workpackage.connectedtasks.title': 'Ajoitetut tehtävät',
  'worksection.workpackage.connectedtasks.linkToSchedule': 'Muokkaa tehtävät',
  'worksection.workpackage.connectedtasks.noConnectedTaskInfo':
    'Aikataulutehtäviä ei ole valittu työvaiheelle. Valitse työvaiheeseen kuuluvat tehtävät aikataulusta. Vaihtoehtoisesti voit ajoittaa työvaiheen käyttämällä kiinteää aikaväliä.',

  'worksection.workpackage.refreshToolTip':
    'Hae päivittyneet tiedot aikataulusta',

  'worksection.header': 'Työvaiheet',
  'worksection.workPackage.groups.open':
    'Kaikki työkokonaisuusryhmät ovat avoinna',
  'worksection.workPackage.groups.closed':
    'Kaikki työkokonaisuusryhmät ovat suljettuna',

  'worksection.workPackage.groups.sortAsc':
    'Lajittele työkokonaisuusryhmät nousevassa järjestyksessä',
  'worksection.workPackage.groups.sortDesc':
    'Lajittele työkokonaisuusryhmät laskevassa järjestyksessä',

  'worksection.table.header.name': 'Kuvaus',
  'worksection.table.header.personResponsible': 'Vastuuhenkilö',
  'worksection.table.header.targetTotal': 'Tavoite',
  'worksection.table.header.additionalTargetTotal': 'Lisätavoite',
  'worksection.table.header.predictionTotal': 'Ennuste',
  'worksection.table.header.differenceToTarget': 'Ero tavoitteeseen',
  'worksection.table.header.predictionChangeFromLatest': 'Ennustemuutos',
  'worksection.table.header.contractTotal': 'Sopimus',
  'worksection.table.header.remaining': 'Jäljellä',
  'worksection.table.header.changeOrdersTotal': 'Lisä- ja muutostyöt',
  'worksection.table.header.reservesTotal': 'Varaukset',
  'worksection.table.header.receivedTotal': 'Vastaanotettu',
  'worksection.table.header.none': ' ',
  'worksection.table.header.code': 'Koodi',
  'worksection.table.header.percentageOfCompletion': 'Valmiusaste',
  'worksection.table.header.percentageOfCompletion.toolTip':
    'Työvaiheelle ei ole määritettynä aikataulutehtäviä tai aikaväliä. Klikkaa muokataksesi työvaiheen tietoja.',
  'worksection.table.header.percentageOfCompletion.eoMonth':
    'Valmiusaste kuukauden loppuun',
  'worksection.table.header.differenceToPreviousPoC': 'Ero suunniteltuun VA-%',
  'worksection.table.header.previousPoC': 'Raportoitu suunniteltu VA-%',
  'worksection.table.mainRow.closed': 'Työvaihe on suljettu',
  'worksection.table.mainRow.open': 'Työvaihe on auki',
  'worksection.table.header.workPackageGroup.PoC.toolTip':
    'Työvaiheryhmä sisältää työvaiheita, joilla ei ole määritettynä aikataulutehtäviä tai aikaväliä.',
  'worksection.orderRow.header.status': 'Rivin status',

  'worksection.table.workPackageGroup.open': 'Työvaihekokonaisuus on auki',
  'worksection.table.workPackageGroup.closed':
    'Työvaihekokonaisuus on suljettu',

  'worksectionDetails.back': 'Työvaiheet:',
  'worksectionDetails.actionButton': 'Tuo hankintoja ja aiheita',
  'worksection.actionButton.addWorkPackage': 'Lisää työvaihe',

  'worksection.table.header.createWorkPackage': 'Luo uusi työvaihe',
  'worksectionDetails.actionButton.info': 'Työvaiheen perustiedot',
  'worksectionDetails.header': '{code} - {name}',

  'worksectionDetails.orderTable.order': 'Hankinta',
  'worksectionDetails.orderTable.target': 'Tavoite',
  'worksectionDetails.orderTable.additionalTarget': 'Lisätavoite',
  'worksectionDetails.orderTable.predictionTotal': 'Ennuste',
  'worksectionDetails.orderTable.predictionChangeFromLatest': 'Ennustemuutos',
  'worksectionDetails.orderTable.contractTotal': 'Sopimus',
  'worksectionDetails.orderTable.changeOrdersTotal': 'Lisä- ja muutostyöt',
  'worksectionDetails.orderTable.reserved': 'Varaukset',
  'worksectionDetails.orderTable.received': 'Vastaanotettu',
  'worksectionDetails.orderTable.status': 'Status',
  'worksectionDetails.orderTable.linkToEntityIcon.alt':
    'Linkki hankinnan aiheeseen',

  'worksectionDetails.orderTable.topicRow.closed': 'Aihe on suljettu',
  'worksectionDetails.orderTable.topicRow.open': 'Aihe on auki',

  'users.editMode.editUserSettings': 'Käyttäjäasetukset',
  'users.editMode.language': 'Kieli',

  'target.header': 'Tavoite',

  'target.toolbelt.button.importTarget': 'Tuo tavoite',
  'target.toolbelt.button.updateTarget': 'Päivitä tavoite',
  'target.toolbelt.button.edit': 'Muokkaa',
  'target.toolbelt.button.edit.tooltip':
    'Luo tilausrivejä hankinnoille tavoiteriveistä tai tavoitteen hierarkiatasoista',
  'target.toolbelt.button.updateTarget.disabled':
    'Projektilla on ulkoiset tavoitemuutokset sallittuja, joten sen tavoitetta ei voi muokata csv-tiedoston avulla',
  'target.toolbelt.button.updateProcurements.selectAllLowestLevelItemsRows':
    'Valitse alimpien tasojen rivit',
  'target.toolbelt.button.updateProcurements.selectNextLevelItems':
    'Valitse ylemmät rivit',
  'target.toolbelt.button.updateProcurements.selectLowestLevel':
    'Valitse vain alin taso',
  'target.toolbelt.button.updateProcurements.selectNextLevel':
    'Valitse seuraava taso',
  'target.toolbelt.button.updateProcurements.filterLinkedToOrderRows':
    'Suodata tilausriveihin linkatut',
  'target.table.updateProcurements.filterLinkedToOrderRows.toolTip':
    'Tämä rivi on jo linkattu tilausriviin',
  'target.updateProcurementsModal.header': 'Tilausrivien luominen',
  'target.toolbelt.button.updateProcurements.convertToOrderRows':
    'Luo valinnoista tilausrivejä',
  'target.updateProcurementsModal.noData.header':
    'Linkkaamattomia rivejä ei löytynyt',
  'target.updateProcurementsModal.noData.body':
    'Varmista, että olet valinnut rivejä, joihin on linkattuna tavoitetta ja tavoiterivit eivät ole linkattuna jo tilausriveihin.',

  'target.updateProcurementsModal.table.header.code': 'Tunnus',
  'target.updateProcurementsModal.table.header.description': 'Kuvaus',
  'target.updateProcurementsModal.table.header.quantity': 'Määrä',
  'target.updateProcurementsModal.table.header.unit': 'Yksikkö',
  'target.updateProcurementsModal.table.header.unitPrice': 'Yksikköhinta',
  'target.updateProcurementsModal.table.header.amount': 'Summa',

  'target.updateProcurementsModal.table.row.unsavedChanges.tooltip':
    'Riviin liittyy tallentamattomia muutoksia. Klikkaamalla ikonia voit resetoida muutokset.',

  'target.updateProcurementsModal.table.row.unsavedChanges.tooltip.targetRow':
    'Rivin alkuperäinen hankinta: {order} \nRivin alkuperäinen työvaihe: {workPackage} \n\nKlikkaamalla ikonia voit perua muutokset.',

  'target.updateProcurementsModal.footer.rowCount':
    'Uusien tilausrivien lukumäärä',
  'target.updateProcurementsModal.footer.amount': 'Uusien tilausrivien summa',
  'target.updateProcurementsModal.footer.forecast.before': 'Ennuste nyt',
  'target.updateProcurementsModal.footer.forecast.after':
    'Ennuste rivien luonnin jälkeen',

  'target.updateProcurementsModal.move':
    'Siirrä {count} valittua tavoiteriviä (ei tallenna muutoksia tietokantaan)',
  'target.updateProcurementsModal.move.one':
    'Siirrä {count} valittu tavoiterivi (ei tallenna muutoksia tietokantaan)',

  'target.updateProcurementsModal.create.and.move':
    'Luo rivit ja tallenna muutokset',

  'target.targetImportModal.steps.uploadFile': 'Lataa tiedosto',
  'target.targetImportModal.steps.selectColumns': 'Kohdista sarakkeet',
  'target.targetImportModal.steps.previewData': 'Esikatsele sisältö',
  'target.targetImportModal.uploadFile.header': '1/3 Lataa tiedosto',
  'target.targetImportModal.uploadFile.paragraph':
    'Tällä toiminnolla voit tuoda/päivittää csv-tiedostosta kustannustavoitetiedot sekä niihin liittyvät hankinnat ja työvaiheet projektille “{projectCodeName}”.',
  'target.targetImportModal.uploadFile.paragraph2':
    'Olemassa olevia tavoiterivejä ei poisteta, ellei niitä ole erikseen merkitty poistettaviksi csv-tiedostossa.',
  'target.targetImportModal.uploadFile.checkInstructions': 'Katso ohjeet',
  'target.targetImportModal.uploadFile.downloadExample':
    'Lataa csv-tuontipohja',
  'target.targetImportModal.uploadFile.downloadExistingData':
    'Lataa tuontipohja, jossa mukana aiemmin tuodut tiedot',
  'target.targetImportModal.uploadFile.error':
    'Virhe tiedoston latauksessa, maksimi tiedostokoko on {megabytes}MB',
  'target.targetImportModal.uploadFile.upload.button': 'Valitse tiedosto',

  'target.targetImportModal.uploadFile.instructions.header': 'Ohjeet',
  'target.targetImportModal.uploadFile.instructions.paragraph1':
    'Aloita sarakeotsikot taulukon ensimmäiseltä riviltä.',
  'target.targetImportModal.uploadFile.instructions.paragraph2':
    'Varmista, että taulukko sisältää sarakkeet: Rivin tunnus, Vanhemman tunnus, Näytettävä koodi, Kuvaus, Määrä, Yksikkö, Yksikköhinta, Tavoitteen summa, Hankinnan koodi, Hankinnan kuvaus, Työvaiheen koodi ja Työvaiheen kuvaus. Poistettu -sarake on valinnainen, mutta sen avulla voi tarvittaessa poistaa olemassa olevia rivejä.',
  'target.targetImportModal.uploadFile.instructions.paragraph3':
    'Rivin tunnukset ja vanhemman tunnukset ovat pakollisia, muut sarakkeet voi jättää tyhjiksi. Pisin sallittu kuvaus on 255 merkkiä.',
  'target.targetImportModal.uploadFile.instructions.paragraph4':
    'Vain hierarkiassa alimpien tasojen rivit tarvitsevat hankinnan ja työvaiheen koodit ja kuvaukset, mutta ne voidaan myös periyttää vanhemmilta jos jätetään tyhjiksi.',
  'target.targetImportModal.uploadFile.instructions.paragraph5':
    'Rivin tunnuksen pitää olla yksilöllinen, ja sitä käytetään hierarkian luomiseen jos sitä käytetään vanhemman tunnus -kentässä. Näytettävä koodi tulisi koostua nimikeryhmän (esim. suoritteen) koodista sekä nimikkeen (esim. panoksen) omasta koodista. Eri koodit voidaan erottaa toisistaan “-” tavuviivalla, esim:',

  'target.targetImportModal.uploadFile.instructions.paragraph5.detail1':
    '1311 Rakenneosa (esim. väliseinät)',
  'target.targetImportModal.uploadFile.instructions.paragraph5.detail2':
    '1311-1000 Suorite (esim. asennettu seinä)',
  'target.targetImportModal.uploadFile.instructions.paragraph5.detail3':
    '1311-1000-3 Panos (esim. työ / materiaali)',

  'target.targetImportModal.uploadFile.instructions.paragraph6':
    'Tavoite muodostuu vain alimman tason rivien summista, eli niistä riveistä, joita ei mainita vanhemman tunnus -kentässä. Hierarkian ylempien tasojen yksikköhintoja tai summia ei tarvitse määrittää, sillä niitä ei käytetä.',
  'target.targetImportModal.uploadFile.instructions.paragraph7':
    'Hierarkiatasoja voi hyödyntää myöhemmin esim. ennusterivien luomisessa hankinnoille.',

  'target.targetImportModal.selectColumns.header': '2/3 Kohdista sarakkeet',
  'target.targetImportModal.selectColumns.paragraph':
    'Kohdista vaaditut kustannushallinnan kentät oikeisiin sarakkeisiin csv-taulukossasi. Jotkut kentät on jo kohdistettu nimensä perusteella. Tekemäsi kohdistukset muistetaan seuraavalla kerralla.',

  'target.targetImportModal.selectColumns.linked': 'Kohdistettu',
  'target.targetImportModal.selectColumns.key': 'Kustannushallinnan kenttä',
  'target.targetImportModal.selectColumns.csvColumn': 'Csv-taulukon sarake',
  'target.targetImportModal.selectColumns.preview': 'Tietojen esikatselu',

  'target.targetImportModal.previewTable.header': '3/3 Esikatsele sisältö',
  'target.targetImportModal.previewTable.invalidTooltip':
    'Kenttien arvot ovat virheellisiä: ',
  'target.targetImportModal.previewTable.updateTooltip':
    'Kenttien arvot päivittyneet: ',
  'target.targetImportModal.previewTable.rowsToBeImported': 'Tuodut rivit',
  'target.targetImportModal.previewTable.targetRowsToBeImported':
    'Tuotujen tavoiterivien lkm',
  'target.targetImportModal.previewTable.hierarchyRowsToBeImported':
    'Tuotujen hierarkiarivien lkm',
  'target.targetImportModal.previewTable.targetRowsToBeImported.amount':
    'Tuotujen tavoiterivien summa',
  'target.targetImportModal.previewTable.currentTargetAmount': 'Tavoite nyt',
  'target.targetImportModal.previewTable.targetChange': 'Tavoitteen muutos',
  'target.targetImportModal.previewTable.newTargetAmount':
    'Tavoite päivityksen jälkeen',
  'target.targetImportModal.previewTable.invalidRows':
    'Puutteelliset ja virheelliset rivit',
  'target.targetImportModal.previewTable.changedRows': 'Kaikki muutokset',
  'target.targetImportModal.previewTable.addedRows': 'Uudet rivit',
  'target.targetImportModal.previewTable.deletedRows': 'Poistettavat rivit',
  'target.targetImportModal.previewTable.updatedRows': 'Päivittyneet rivit',
  'target.targetImportModal.previewTable.importedProcurements':
    'Tuotujen hankintojen lkm',
  'target.targetImportModal.previewTable.importedWorkSections':
    'Tuotujen työvaiheiden lkm',

  'target.targetImportModal.previewTable.isOrderUpdateAllowed':
    'Salli hankintojen ja työvaiheiden tietojen päivitys',
  'target.targetImportModal.previewTable.isOrderUpdateAllowed.tooltip':
    'Uudet rivit tuodaan annetuilla koodeilla. Oletuksena olemassa olevia tavoiterivejä ei siirretä. Jos tämä vaihtoehto on valittuna, olemassa olevat tavoiterivit päivitetään uusille työvaiheille ja hankinnoille, jos koodit poikkeavat nykyisistä arvoista.',

  'target.targetImportModal.csv.headers.rowCode': 'Rivin tunnus',
  'target.targetImportModal.csv.headers.parentCode': 'Vanhemman tunnus',
  'target.targetImportModal.csv.headers.referenceNo': 'Näytettävä koodi',
  'target.targetImportModal.csv.headers.procurementCode': 'Hankinnan koodi',
  'target.targetImportModal.csv.headers.procurementName': 'Hankinnan nimi',
  'target.targetImportModal.csv.headers.workSectionCode': 'Työvaiheen koodi',
  'target.targetImportModal.csv.headers.workSectionName': 'Työvaiheen nimi',
  'target.targetImportModal.csv.headers.isDeleted': 'Poistettu',

  'target.summary.target': 'Tavoite: {amount}',
  'target.summary.predictionTotal': 'Ennuste: {amount}',
  'target.summary.differenceToTarget': 'Ero tavoitteeseen: {amount}',

  'target.table.header.referenceNo': 'Koodi',
  'target.table.header.order': 'Hankinta',
  'target.table.header.workSection': 'Työvaihe',
  'target.table.header.searchInput': 'Suodata hakusanalla',

  'target.table.hierarchies.open': 'Kaikki hierarkiatasot ovat auki',
  'target.table.hierarchies.closed': 'Kaikki hierarkiatasot ovat kiinni',
  'target.table.hierarchies.openLevel': 'Avaa hierarkiataso',
  'target.table.hierarchies.closeLevel': 'Sulje hierarkiataso',

  'target.noHierarchies.sectionTitle': 'Tavoiterivit ilman hierarkiaa',
};
