import React from 'react';

import styled from 'styled-components';
import { css } from 'styled-components';

import { TextId } from '../../localization';

import { CircleIconButtonContainer } from '../Navigation/Components';
import Txt from '../Txt';

// eslint-disable-next-line import/prefer-default-export
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  /* Center content both vertically and horizontally */
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.5);

  z-index: 50;
`;

export const ModalContent = styled.div<{
  staticPosition?: boolean;
  overflowVisible?: boolean;
}>`
  position: ${(props) => (props.staticPosition ? 'static' : 'relative')};
  max-height: 90vh;
  min-width: 25rem;
  overflow-y: auto;
  ${({ overflowVisible }) =>
    overflowVisible
      ? css`
          overflow: visible;
        `
      : ''}
`;

type StepIndicatorProps = {
  steps: TextId[];
  selectedIndex: number;
  setStep?: (step: number) => void;
};

export const StepIndicator = ({
  steps,
  selectedIndex,
  setStep,
}: StepIndicatorProps) => {
  return (
    <StepContainer>
      {steps.map((step, index) => (
        <React.Fragment key={`step-indicator-${step}`}>
          <Column>
            <ParentCircleIcon selected={selectedIndex === index}>
              <ChildCircleIcon
                disabled={selectedIndex < index || !setStep}
                onClick={() => {
                  if (setStep) {
                    setStep(index);
                  }
                }}
              >
                {index + 1}
              </ChildCircleIcon>
            </ParentCircleIcon>
            <StepDescriptionDiv>
              <Txt id={step} />
            </StepDescriptionDiv>
          </Column>
          {index !== steps.length - 1 ? <StyledHr /> : null}
        </React.Fragment>
      ))}
    </StepContainer>
  );
};

const StepContainer = styled.div`
  position: relative;

  margin-right: ${(props) => props.theme.margin[96]};

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-grow: 1;
`;

type CircleIconProps = {
  selected: boolean;
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ParentCircleIcon = styled.div<CircleIconProps>`
  position: relative;

  border: ${({ selected, theme }) =>
    selected ? `2px solid ${theme.color.darkpurple}` : '0'};
  border-radius: 50%;

  width: 2rem;
  height: 2rem;

  background-color: ${(props) => props.theme.color.white};

  font-size: 0.625rem;
  text-align: center;
  line-height: 1.5rem;
`;

const ChildCircleIcon = styled(CircleIconButtonContainer)`
  position: absolute;
  top: 50%;
  left: 50%;

  margin: calc(-1.5rem / 2) 0px 0px calc(-1.5rem / 2);

  background-color: ${(props) => props.theme.color.darkpurple};

  color: ${(props) => props.theme.color.white};
`;

const StyledHr = styled.hr`
  border: 2px solid ${(props) => props.theme.color.darkpurple};
  flex-grow: 1;
`;

const StepDescriptionDiv = styled.div`
  position: absolute;
  top: 2rem;
  margin-top: ${(props) => props.theme.margin[8]};
  text-wrap: nowrap;
`;
