import React, { useState, useRef, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { ActualCost as APIActualCost } from '../../../../../store/reducers/actualCost';
import {
  isAnyActualCostAttachmentRequestsPending,
  useActualCostAttachmentUrls,
} from '../../../../../store/reducers/actualCostAttachments';
import {
  getActualCostAssignedArrivalRowIdsByActualCostId,
  getAssignedArrivalRowsForActualCostTotal,
} from '../../../../../store/reducers/arrivalRow';
import {
  getActiveProjectId,
  getIsOuterBarOpen,
} from '../../../../../store/reducers/ui';

import {
  convertActualCost,
  fetchActualCostAttachmentFile,
} from '../../../../../store/actions';
import { setOuterBarState } from '../../../../../store/actions/ui';

import { useArrivalRowSelection } from '../../../../../hooks/ui';
import useTxt from '../../../../../hooks/useTxt';

import {
  DropDownList,
  DropDownItem,
} from '../../../../../components/DropdownList';
import Checkbox from '../../../../../components/Input/Checkbox';
import { Spinner } from '../../../../../components/Loading';
import { InvoiceRow, SecondaryRow } from '../../../../../components/Table';

import { getActualCostStatus } from './utils';
import { getBaseApiUrl } from '../../../../../utils/api';
import * as big from '../../../../../utils/big';
import CAN, {
  CaslOrderRequestParams,
} from '../../../../../utils/caslUserPermissions';
import { dateFormat, compactDateFormat } from '../../../../../utils/format';
import {
  ACTUAL_COST_STATUS_NOT_HANDLED,
  ACTUAL_COST_STATUS_HANDLED,
} from '../../../../../utils/general';
import { isClickOrKeyboardSelection } from '../../../../../utils/mouseOrKeyInteraction';

import {
  IconDown,
  IconRight,
  IconKebabMenu,
  IconAttachmentBlack,
  IconPaperAdd,
  IconSplitScreen,
  IconChange,
  IconNewTab,
  IconPaperFix,
} from '../../../../../assets/svg';

import { NoOverflowSpan } from '..';
import ArrivalRow from './ArrivalRow';
import {
  IndicatorImg,
  InvoiceTableBody,
  TableBodyGap,
  ProcessingCell,
  AttachmentCount,
} from './Components';
import CorrectionModal from './CorrectionModal';
import {
  NoWrapCellDiv,
  DescriptionSpan,
  SimpleTextToolTip,
  StyledCheckboxDiv,
  StyledIconButton,
} from './Invoice';
import {
  CompactCell,
  LeftPaddedCompactCell,
  RightPaddedCompactCell,
  UnitPriceCompactCell,
  SelectableInvoiceCell,
} from './LaariCells';
import MoveCostModal from './MoveCostModal/MoveCostModal';
import ProcessingTool from './ProcessingTool';

type Props = {
  actualCost: APIActualCost;
  isSelected: boolean;
  onSelect?: (id: string) => void;
  isAllRendered: boolean;
  isHandled?: boolean;
  index: number;
  focus: boolean;
  setFocus: React.Dispatch<React.SetStateAction<number>>;
};

const ActualCost = ({
  actualCost,
  isSelected,
  onSelect,
  isAllRendered,
  isHandled,
  index,
  focus,
  setFocus,
}: Props) => {
  const history = useHistory();
  const rowRef = useRef<HTMLTableRowElement>(null);
  const apiBaseUrl = getBaseApiUrl();

  const [showMoveModal, setShowMoveModal] = useState(false);

  const [showActualCostRehandleModal, setActualCostRehandleModal] =
    useState(false);

  const { hash } = history.location;
  const dispatch = useDispatch();

  useEffect(() => {
    if (focus && rowRef.current) {
      // Move element into view when it is focused
      rowRef.current.focus();
    }
  }, [focus, rowRef]);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const sortedArrivalRowIds = useSelector(
    getActualCostAssignedArrivalRowIdsByActualCostId({
      actualCostId: actualCost.id,
      orderId: actualCost.orderId ?? '',
    }),
    (a, b) => a.join() === b.join()
  );

  const attachmentUrls = useActualCostAttachmentUrls(actualCost.id);

  const projectId = useSelector(getActiveProjectId) ?? '';

  const isAttachmentsLoading = useSelector(
    isAnyActualCostAttachmentRequestsPending(actualCost.id)
  );

  const [isOpen, setIsOpen] = useState(isSelected);
  const [isScrolled, setScrolled] = useState(false);
  const [dropDownVisible, setDropDownVisibility] = useState(false);

  useEffect(() => {
    if (!isAllRendered) {
      return;
    }

    if (isScrolled) {
      return;
    }

    // TODO: find a way to define this in routes.ts
    if (hash !== `#actualCostId-${actualCost.id}`) {
      return;
    }

    if (rowRef.current) {
      rowRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
      setScrolled(true);
    }
  }, [hash, actualCost.id, isScrolled, isAllRendered]);

  const toggleIsOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setFocus(index);
    setIsOpen((open) => !open);
  };

  const [selectedArrivalRows, setSelectedArrivalRows] =
    useArrivalRowSelection();

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const assignedArrivalRowsTotal = useSelector(
    getAssignedArrivalRowsForActualCostTotal({
      actualCostId: actualCost.id,
      orderId: actualCost.orderId ?? '',
    }),
    shallowEqual
  );

  const toggleDropDown = (e: React.SyntheticEvent) => {
    setDropDownVisibility(!dropDownVisible);
    setFocus(index);
    stopPropagation(e);
  };

  const showOuterBar = () => {
    setFocus(index);
    dispatch(
      setOuterBarState({
        type: 'actual_cost',
        contentId: actualCost.id,
      })
    );
  };
  const arrivalRowIds = sortedArrivalRowIds;

  const selectedArrivalRowIds = Object.keys(selectedArrivalRows).reduce(
    (prev, curr) => {
      if (selectedArrivalRows[curr]) {
        prev.push(curr);
      }

      return prev;
    },
    [] as string[]
  );

  const onClickCheckbox = () => {
    if (onSelect) {
      setSelectedArrivalRows(arrivalRowIds, !allArrivalRowsSelected);
    }
  };

  const allArrivalRowsSelected =
    selectedArrivalRowIds.length > 0
      ? arrivalRowIds.every((key) => selectedArrivalRowIds.includes(key))
      : false;

  const selectedArrivalRowIdsForThisActualCost = selectedArrivalRowIds.filter(
    (id) => arrivalRowIds.includes(id)
  );

  const onChangeConvertActualCost = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (sortedArrivalRowIds.length === 0 && !isHandled) {
        toggleDropDown(e);
        dispatch(convertActualCost(actualCost.id));

        if (onSelect) {
          onSelect(actualCost.id);
        }
      }
    }
  };

  const onChangeMoveActualCost = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (actualCost.isMovable) {
        toggleDropDown(e);
        setShowMoveModal(true);
      }
    }
  };

  const onStartActualCostRehandle = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (isHandled) {
        toggleDropDown(e);
        setActualCostRehandleModal(true);
      }
    }
  };

  const onOpenInNewTab = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      toggleDropDown(e);

      const imageUrl = `${apiBaseUrl}v1/attachments/actual-costs/${actualCost.id}`;

      dispatch(fetchActualCostAttachmentFile(actualCost.id, imageUrl, true));
    }
  };

  const status = getActualCostStatus(actualCost);

  const hasSelectableArrivalRows =
    actualCost.statusId === ACTUAL_COST_STATUS_NOT_HANDLED;

  const showArrivalRow = isOpen && sortedArrivalRowIds.length > 0;
  const showEmptyArrivalRow = isOpen && sortedArrivalRowIds.length <= 0;

  const openLinks = (e: React.SyntheticEvent) => {
    stopPropagation(e);

    if (isAttachmentsLoading) {
      return;
    }

    // dispatch action to open all attachment links in new tabs

    attachmentUrls.forEach((url) => {
      dispatch(fetchActualCostAttachmentFile(actualCost.id, url, true));
    });
  };

  const addText = useTxt('order.receiveMode.actualCost.add');
  const openText = useTxt('order.receiveMode.actualCost.isOpen');
  const closedText = useTxt('order.receiveMode.actualCost.isClosed');
  const moveText = useTxt('order.receiveMode.cost.move');

  const rehandleText = useTxt('order.receiveMode.actualCost.rehandle');

  const noRowsAssignedText = useTxt(
    'order.receiveMode.actualCost.noArrivalRows'
  );

  const openImageInNewTabTxt = useTxt(
    'order.receiveMode.actualCost.image.inNewTab'
  );

  const checkBoxLabelText = useTxt('order.receiveMode.arrivalRowTH.chooseAll');

  const statusText = useTxt(status.text, {
    identifier: status.identifier,
    handleNetAmountPending: status.handleNetAmountPending
      ? big.priceFormat(status.handleNetAmountPending)
      : '0',
  });

  const shortStatusText = useTxt(status.shortText, {
    dateAndUser: actualCost.latestStatusChangeAt
      ? `${compactDateFormat.format(actualCost.latestStatusChangeAt)}, ${
          actualCost.latestUpdater
        }`
      : '',
  });

  const showCheckbox =
    onSelect && hasSelectableArrivalRows && arrivalRowIds.length > 0;

  const outerBarOpen = useSelector(getIsOuterBarOpen());
  const rowHighlighted = hash === `#actualCostId-${actualCost.id}`;

  const ability = new CaslOrderRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  return (
    <InvoiceTableBody isActive={isSelected}>
      {showMoveModal ? (
        <MoveCostModal
          projectId={projectId}
          onClose={() => setShowMoveModal(false)}
          cost={actualCost}
        />
      ) : null}
      {showActualCostRehandleModal ? (
        <CorrectionModal
          cost={actualCost}
          onClose={() => setActualCostRehandleModal(false)}
        />
      ) : null}
      <InvoiceRow
        selected={isSelected}
        isHandled={isHandled}
        rowHighlighted={rowHighlighted}
        ref={rowRef}
        tabIndex={focus ? 0 : -1}
        clickable
        data-testid={`actual-cost-selector-${actualCost.id}`}
        onClick={(e) => {
          e.stopPropagation();
          toggleIsOpen(e);

          if (onSelect) {
            onSelect(actualCost.id);
          }
        }}
      >
        <SelectableInvoiceCell selected={isSelected}>
          <StyledIconButton
            icon={isOpen ? IconDown : IconRight}
            onClick={toggleIsOpen}
            aria-label={isOpen ? openText : closedText}
          />
        </SelectableInvoiceCell>

        <CompactCell colSpan={2} contentContainer={NoWrapCellDiv}>
          {showCheckbox ? (
            <StyledCheckboxDiv>
              <Checkbox
                checked={allArrivalRowsSelected || false}
                onChange={onClickCheckbox}
                disabled={!allowedUser}
                aria-label={checkBoxLabelText}
              />
            </StyledCheckboxDiv>
          ) : null}
          <DescriptionSpan>{actualCost.documentNumber}</DescriptionSpan>
        </CompactCell>
        <CompactCell contentContainer={NoWrapCellDiv}>
          <IndicatorImg
            isCompact={outerBarOpen}
            alt={statusText}
            src={status.icon}
          />
          {outerBarOpen ? null : shortStatusText}
          {actualCost.comment && actualCost.comment.length > 0 ? (
            <SimpleTextToolTip>{actualCost.comment}</SimpleTextToolTip>
          ) : null}
        </CompactCell>
        <CompactCell colSpan={outerBarOpen ? 1 : 2}>
          <NoOverflowSpan>{actualCost.supplierName ?? null}</NoOverflowSpan>
        </CompactCell>
        <CompactCell align="right">
          <ActualCostSumWrapper>
            {big.priceFormat(actualCost.amount)}
          </ActualCostSumWrapper>
        </CompactCell>
        <CompactCell colSpan={outerBarOpen ? 1 : 5}>
          {dateFormat.format(new Date(actualCost.date))}
        </CompactCell>

        {!outerBarOpen ? (
          <UnitPriceCompactCell align="right">
            {assignedArrivalRowsTotal}
          </UnitPriceCompactCell>
        ) : (
          <CompactCell align="right">{assignedArrivalRowsTotal}</CompactCell>
        )}
        {!outerBarOpen ? <CompactCell /> : null}

        <RightPaddedCompactCell>
          <StyledIconButton
            icon={IconKebabMenu}
            disabled={!allowedUser}
            onClick={toggleDropDown}
            data-testid={`kebab-menu-${actualCost.id}`}
            ref={buttonRef}
          />
          <StyledIconButton
            icon={IconSplitScreen}
            onClick={showOuterBar}
            data-testid={`split-screen-${actualCost.id}`}
          />
          {dropDownVisible ? (
            <DropDownList
              role="menu"
              ref={buttonRef}
              onClose={() => setDropDownVisibility(false)}
            >
              <DropDownItem
                disabled={sortedArrivalRowIds.length > 0 || isHandled}
                tabIndex={0}
                role="menuitem"
                onClick={onChangeConvertActualCost}
                onKeyDown={onChangeConvertActualCost}
              >
                <IconPaperAdd />
                {addText}
              </DropDownItem>
              <DropDownItem
                disabled={!actualCost.isMovable}
                tabIndex={0}
                role="menuitem"
                onClick={onChangeMoveActualCost}
                onKeyDown={onChangeMoveActualCost}
              >
                <IconChange />
                {moveText}
              </DropDownItem>
              <DropDownItem
                tabIndex={0}
                role="menuitem"
                onClick={onOpenInNewTab}
                onKeyDown={onOpenInNewTab}
              >
                <IconNewTab />
                {openImageInNewTabTxt}
              </DropDownItem>
              {isHandled ? (
                <DropDownItem
                  tabIndex={0}
                  role="menuitem"
                  onClick={onStartActualCostRehandle}
                  onKeyDown={onStartActualCostRehandle}
                >
                  <IconPaperFix />
                  {rehandleText}
                </DropDownItem>
              ) : null}
            </DropDownList>
          ) : null}
          &nbsp;
          {attachmentUrls.length > 0 ? (
            <>
              {isAttachmentsLoading ? (
                <Spinner size="1rem" />
              ) : (
                <StyledIconButton
                  icon={IconAttachmentBlack}
                  onClick={openLinks}
                >
                  {attachmentUrls.length > 1 ? (
                    <AttachmentCount>{attachmentUrls.length}</AttachmentCount>
                  ) : null}
                </StyledIconButton>
              )}
            </>
          ) : null}
        </RightPaddedCompactCell>
      </InvoiceRow>

      {showArrivalRow
        ? sortedArrivalRowIds.map((id) => (
            <ArrivalRow
              projectId={actualCost.projectId ?? ''}
              key={`actualCost-${actualCost.id}-arrival-row-${id}`}
              arrivalRowId={id}
              isSelectable={hasSelectableArrivalRows}
              isHandled={actualCost.statusId === ACTUAL_COST_STATUS_HANDLED}
            />
          ))
        : null}
      {showEmptyArrivalRow ? (
        <SecondaryRow>
          <LeftPaddedCompactCell colSpan={outerBarOpen ? 9 : 15}>
            {noRowsAssignedText}
          </LeftPaddedCompactCell>
        </SecondaryRow>
      ) : null}
      {isOpen && !isHandled ? (
        <tr>
          <ProcessingCell colSpan={outerBarOpen ? 9 : 15} isActive={isSelected}>
            <ProcessingTool
              actualCost={actualCost}
              arrivalRowIds={sortedArrivalRowIds}
              selectedRelatedArrivalRowIds={
                selectedArrivalRowIdsForThisActualCost
              }
            />
          </ProcessingCell>
        </tr>
      ) : null}
      <TableBodyGap isActive={isSelected}>
        <td colSpan={outerBarOpen ? 9 : 14} />
      </TableBodyGap>
    </InvoiceTableBody>
  );
};

const ActualCostSumWrapper = styled.span`
  padding-right: 1rem;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
`;

export default React.memo(ActualCost);
