import React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import useMeasure from 'react-use-measure';

import {
  getCoreRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  useReactTable,
  OnChangeFn,
  Updater,
} from '@tanstack/react-table';
import { Row } from '@tanstack/react-table';
import { ColumnSizingState } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import styled from 'styled-components';

import { getHierarchyMaxDepth } from '../../../../store/reducers/target/hierarchy';

import useTargetViewColumns from '../../hooks/useTargetViewColumns';
import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import { ToolTip } from '../../../../components/Cell';
import { GlobalLoadingSpinner } from '../../../../components/Loading';

import { isDefined } from '../../../../utils/general';
import { adjustNeighbouringColumns } from '../../../../utils/headerWidth';

import SelectionSummary from './SelectionSummary';
import TargetViewRow from './TargetRowOrTargetRowHierarchyEntry';
import Th from './Th';
import { UpdateProcurementsModal } from '../UpdateProcurementsModal';

type TooltipProps =
  | {
      text?: string;
      place?: 'top' | 'bottom' | 'left' | 'right';
      parentMeasures?: {
        width: number;
        height: number;
        top: number;
        left: number;
        right: number;
        bottom: number;
        virtualRowStart: number | undefined;
      };
    }
  | undefined;

interface ToolTipContextProps {
  tooltipProps: TooltipProps;
  setToolTipProps: React.Dispatch<React.SetStateAction<TooltipProps>>;
}

export const TargetTableTooltipContext =
  React.createContext<ToolTipContextProps>({
    tooltipProps: undefined,
    setToolTipProps: () => {},
  });

/**
 * Parentmeasures height is sensitive for changes since useVirtualizer library uses ResizeObservers as well.
 * If the parentMeasures height changes too quickly, it will trigger resizeObserver event and "ResizeObserver loop completed with undelivered notifications" error will be thrown.
 */

const TargetTable = React.forwardRef(
  (
    {
      projectId,
      data,
      parentMeasures,
      toolbeltButtonSelectionState,
      setToolbeltButtonSelectionState,
      showUpdateProcurementsModal,
      setUpdateProcurementsModal,
    }: {
      projectId: string;
      data: TargetRowOrTargetRowHierarchyEntry[];
      parentMeasures: { width: number; height: number | string };
      toolbeltButtonSelectionState?: {
        isAnySelected: boolean;
        isHighestLevelSelected: boolean;
      };
      setToolbeltButtonSelectionState?: React.Dispatch<
        React.SetStateAction<{
          isAnySelected: boolean;
          isHighestLevelSelected: boolean;
        }>
      >;
      showUpdateProcurementsModal?: boolean;
      setUpdateProcurementsModal?: React.Dispatch<
        React.SetStateAction<boolean>
      >;
    },
    ref: React.Ref<{
      toggleExpandAll: (expand: boolean) => void;
      selectAllLowestLevelItems: () => void;
      selectLowestLevel: () => void;
      selectNextLevel: () => void;
    }>
  ) => {
    const columns = useTargetViewColumns(parentMeasures.width - 20);

    const columnNames = columns.map((column) => column.id);

    const origSizes = columns.reduce(
      (acc, curr) => {
        if (curr.id) {
          acc[curr.id] = curr.size ?? 0;
        }

        return acc;
      },
      {} as Record<string, number>
    );

    const [rowSelection, setRowSelection] = React.useState<
      Record<string, boolean>
    >({});

    const [columnSizing, setColumnSizing] = React.useState<ColumnSizingState>(
      {}
    );

    // if parentMeasures.width changes, reset columnSizing
    React.useEffect(() => {
      setColumnSizing({});
    }, [parentMeasures.width]);

    const [tooltipProps, setToolTipProps] = React.useState<TooltipProps>();

    const isFetchingMaxDepth = useSelector(getHierarchyMaxDepth(projectId));

    const memoizedData = React.useMemo(() => data, [data]);

    const [measureRef, measures] = useMeasure({ debounce: 100 });
    const windowSize = useWindowSize();

    const enableRowSelection = (
      row: Row<TargetRowOrTargetRowHierarchyEntry>
    ) => {
      if (
        row.original.isAntiRow ||
        row.original.isDisabled ||
        row.original.isDeleted
      ) {
        return false;
      }

      const parentRows = row.getParentRows();

      const isParentSelected = parentRows.some(
        (parentRow) => rowSelection[parentRow.id] === true
      );

      return !isParentSelected;
    };

    const onColumnSizingChange: OnChangeFn<ColumnSizingState> = (
      updaterOrValue: Updater<ColumnSizingState>
    ) => {
      if (typeof updaterOrValue === 'function') {
        const newState = updaterOrValue(columnSizing);

        const adjustedState = adjustNeighbouringColumns(
          origSizes,
          columnSizing,
          newState,
          columnNames
        );

        return setColumnSizing(adjustedState);
      }

      const adjustedState = adjustNeighbouringColumns(
        origSizes,
        columnSizing,
        updaterOrValue,
        columnNames
      );

      return setColumnSizing(adjustedState);
    };

    const table = useReactTable({
      data: memoizedData,
      columns,
      getRowId: (row) => row.id,
      state: {
        rowSelection,
        columnSizing,
      },
      enableSubRowSelection: false,
      getSubRows: (row) => row.subRows,
      onRowSelectionChange: setRowSelection,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      enableRowSelection: enableRowSelection,
      manualSorting: true,
      onColumnSizingChange,
    });

    const { rows } = table.getRowModel();

    const isHighestLevelSelected = rows
      .filter((row) => row.depth === 0)
      .map((row) => row.getIsSelected())
      .every((isSelected) => isSelected);

    const isAnySelected = React.useMemo(() => {
      return Object.values(rowSelection).some((value) => value === true);
    }, [rowSelection]);

    React.useEffect(() => {
      if (
        setToolbeltButtonSelectionState &&
        (toolbeltButtonSelectionState?.isAnySelected !== isAnySelected ||
          toolbeltButtonSelectionState?.isHighestLevelSelected !==
            isHighestLevelSelected)
      ) {
        setToolbeltButtonSelectionState({
          isAnySelected,
          isHighestLevelSelected,
        });
      }
    }, [
      isAnySelected,
      setToolbeltButtonSelectionState,
      isHighestLevelSelected,
      toolbeltButtonSelectionState?.isAnySelected,
      toolbeltButtonSelectionState?.isHighestLevelSelected,
    ]);

    const { flatRows } = table.getRowModel();

    const selectAllLowestLevelItems = () => {
      const lowestLevelRows = flatRows.filter(
        (row) =>
          row.original.type === 'targetRow' &&
          row.original.isDeleted === false &&
          row.original.isDisabled === false &&
          row.original.isAntiRow === false
      );

      const selectedRows = lowestLevelRows.reduce(
        (acc, row) => {
          acc[row.id] = true;

          return acc;
        },
        {} as Record<string, boolean>
      );

      setRowSelection(selectedRows);

      return true;
    };

    const selectLowestLevel = () => {
      const lowestLevel = Math.max(...flatRows.map((row) => row.depth));

      const lowestLevelRows = flatRows.filter(
        (row) => row.depth === lowestLevel
      );

      const selectedRows = lowestLevelRows.reduce(
        (acc, row) => {
          acc[row.id] = true;

          return acc;
        },
        {} as Record<string, boolean>
      );

      setRowSelection(selectedRows);

      return true;
    };

    const selectNextLevel = () => {
      const selectedRowsIds = Object.entries(rowSelection)
        .map(([key, value]) => (value === true ? key : undefined))
        .filter(isDefined);

      const previouslySelected = flatRows.filter((row) =>
        selectedRowsIds.includes(row.id)
      );

      // if all selected rows are on the same level and every member of the level is selected, select the next level
      const selectedRowsLevels = [
        ...new Set(previouslySelected.map((row) => row.depth)),
      ];

      if (selectedRowsLevels.length === 1) {
        const currentLevel = selectedRowsLevels[0];

        const allSelected = flatRows
          .filter((row) => row.depth === currentLevel)
          .every((row) => selectedRowsIds.includes(row.id));

        if (allSelected && currentLevel > 0) {
          const nextLevel = currentLevel - 1;

          const nextLevelRows = flatRows.filter(
            (row) => row.depth === nextLevel
          );

          const selectedRows = nextLevelRows.reduce(
            (acc, row) => {
              acc[row.id] = true;

              return acc;
            },
            {} as Record<string, boolean>
          );

          return setRowSelection(selectedRows);
        }
      }

      // if highest level parentId is null, then reselect highest level
      const selectedRowsParents = previouslySelected
        .map((row) => (row.parentId ? row.parentId : row.id))
        .filter(isDefined);

      // make sure no parent is concurrently selected with its children

      const selectedRowsChildren = flatRows
        .filter((row) => selectedRowsParents.includes(row.id))
        .map((row) => row.getLeafRows())
        .flat()
        .map((row) => row.id);

      const selectedRows = selectedRowsParents
        .filter((id) => !selectedRowsChildren.includes(id))
        .reduce(
          (acc, row) => {
            acc[row] = true;

            return acc;
          },
          {} as Record<string, boolean>
        );

      return setRowSelection(selectedRows);
    };

    React.useImperativeHandle(ref, () => {
      const toggleExpandAll = table.toggleAllRowsExpanded;

      return {
        toggleExpandAll,
        selectAllLowestLevelItems,
        selectLowestLevel,
        selectNextLevel,
      };
    });

    const rowsLength = React.useMemo(() => rows.length, [rows]);

    const tableContainerRef = React.useRef<HTMLDivElement | null>(null);

    const rowVirtualizer = useVirtualizer({
      count: rowsLength,
      getScrollElement: () => tableContainerRef.current,
      estimateSize: React.useCallback(() => 40, []), // estimate row height for accurate scrollbar dragging
      overscan: 40,
      paddingEnd: 87,
      measureElement:
        typeof window !== 'undefined' &&
        navigator.userAgent.indexOf('Firefox') === -1
          ? (element) => element?.getBoundingClientRect().height
          : undefined,
    });

    const measureElement = React.useCallback(
      (node: Element | null) => {
        return rowVirtualizer.measureElement(node);
      },
      [rowVirtualizer]
    );

    const containerWidth = React.useMemo(() => {
      return parentMeasures.width;
    }, [parentMeasures.width]); // 40px is the margin of target view container

    const containerHeight = React.useMemo(() => {
      return parentMeasures.height;
    }, [parentMeasures.height]);

    const tableHeaders = table
      .getHeaderGroups()
      .map((headerGroup) => headerGroup.headers)
      .flat();

    const columnSizeVars = React.useMemo(() => {
      const colSizes: { [key: string]: number } = {};
      for (let i = 0; i < tableHeaders.length; i++) {
        const header = tableHeaders[i]!;

        const thWidth = header.getSize();

        colSizes[header.id] = thWidth;
      }

      return colSizes;
    }, [tableHeaders]);

    const virtualItems = rowVirtualizer.getVirtualItems();

    const visibleRows = React.useMemo(() => {
      return virtualItems.map((virtualRow) => ({
        ...virtualRow,
        row: rows[virtualRow.index],
      }));
    }, [virtualItems, rows]);

    if (isFetchingMaxDepth.kind !== 'Success') {
      return (
        <UusiDiv height={containerHeight}>
          <StyledContainer>
            <GlobalLoadingSpinner />
          </StyledContainer>
        </UusiDiv>
      );
    }

    const calculateTooltipPlacement = (): TooltipDivProps => {
      let left: number | undefined;
      let right: number | undefined;

      if (
        tooltipProps?.parentMeasures?.left &&
        tooltipProps?.parentMeasures?.left < windowSize.width / 2
      ) {
        left = tooltipProps?.parentMeasures?.left
          ? tooltipProps?.parentMeasures?.left - measures.left + 24
          : 0;
      } else {
        right = tooltipProps?.parentMeasures?.right
          ? measures.right - tooltipProps?.parentMeasures?.right + 24
          : 0;
      }

      return {
        left,
        right,
        virtualRowStart: tooltipProps?.parentMeasures?.virtualRowStart,
      };
    };

    const tooltipPlacement = calculateTooltipPlacement();

    return (
      <>
        {showUpdateProcurementsModal ? (
          <UpdateProcurementsModal
            onClose={() =>
              setUpdateProcurementsModal
                ? setUpdateProcurementsModal(false)
                : {}
            }
            data={data}
            selectionState={rowSelection}
            projectId={projectId}
          />
        ) : null}
        <div ref={measureRef}>
          <UusiDiv height={containerHeight}>
            <StyledContainer ref={tableContainerRef}>
              <TargetTableTooltipContext.Provider
                value={{ tooltipProps, setToolTipProps }}
              >
                <StyledTable>
                  <StyledThead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <StyledTr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <Th
                              key={header.id}
                              header={header}
                              width={columnSizeVars[header.id] ?? 0}
                              deltaOffset={
                                table.getState().columnSizingInfo.deltaOffset
                              }
                            />
                          );
                        })}
                      </StyledTr>
                    ))}
                  </StyledThead>
                  <StyledTBody height={rowVirtualizer.getTotalSize()}>
                    {visibleRows.map((virtualRow) => {
                      const isRowSelected = virtualRow.row.getIsSelected();

                      const selectionState = table.getState().rowSelection;

                      const parentRows = virtualRow.row.getParentRows();

                      const isSelectionDisabled = parentRows.some(
                        (parentRow) => selectionState[parentRow.id] === true
                      );

                      const leafRows = virtualRow.row.getLeafRows();

                      const isChildSelected = leafRows.some((childRow) =>
                        childRow.getIsSelected()
                      );

                      const targetRowLeafs = leafRows.filter(
                        (childRow) =>
                          childRow.original.type === 'targetRow' &&
                          childRow.original.isDeleted === false &&
                          childRow.original.isDisabled === false &&
                          childRow.original.isAntiRow === false
                      );

                      const allChildrenSelectedOrDisabled =
                        isRowSelected || targetRowLeafs.length === 0
                          ? false
                          : targetRowLeafs.every(
                              (childRow) =>
                                childRow.getIsSelected() ||
                                !childRow.getCanSelect()
                            );

                      return (
                        <TargetViewRow
                          key={virtualRow.row.id}
                          row={virtualRow.row}
                          virtualRow={virtualRow}
                          measureElement={measureElement}
                          containerWidth={containerWidth - 20}
                          isSelected={isRowSelected}
                          isSelectionDisabled={isSelectionDisabled}
                          allChildrenSelectedOrDisabled={
                            allChildrenSelectedOrDisabled
                          }
                          isChildSelected={isChildSelected}
                          columnSizes={columnSizeVars}
                        />
                      );
                    })}
                  </StyledTBody>
                </StyledTable>
                {tooltipProps ? (
                  <StyledTooltip {...tooltipPlacement}>
                    {tooltipProps?.text ?? ''}
                  </StyledTooltip>
                ) : null}
              </TargetTableTooltipContext.Provider>
              {isAnySelected ? (
                <SelectionSummary
                  selectionState={rowSelection}
                  data={flatRows}
                  tableHeaders={tableHeaders}
                  containerWidth={containerWidth}
                />
              ) : null}
            </StyledContainer>
          </UusiDiv>
        </div>
      </>
    );
  }
);

const StyledContainer = styled.div`
  position: relative;

  height: 100%;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  overflow: auto;

  transform: translate3d(0, 0, 0);
`;

type ContainerProps = {
  height: number | string;
};

const UusiDiv = styled.div<ContainerProps>`
  height: ${({ height }) =>
    typeof height === 'string' ? height : `${height}px`};
  width: 100%;
`;

type TBodyProps = {
  height: number;
};

const StyledTBody = styled.tbody<TBodyProps>`
  position: relative;
  height: ${({ height }) => height}px;
  display: grid;
`;

const StyledTable = styled.table`
  display: grid;
  align-items: center;

  border-collapse: collapse;
  border-spacing: 0;

  table-layout: fixed;
`;

const StyledThead = styled.thead`
  position: sticky;
  top: 0;

  display: grid;

  background-color: white;

  z-index: 1;
`;

const StyledTr = styled.tr`
  border-bottom: 2px solid ${(props) => props.theme.color.rowBorder};
  height: 48px;
  width: 100%;
  display: flex;
`;

type TooltipDivProps = {
  left?: number;
  right?: number;
  virtualRowStart?: number;
};

const StyledTooltip = styled(ToolTip).attrs<TooltipDivProps>(
  ({ virtualRowStart }) => ({
    style: { transform: `translateY(${(virtualRowStart ?? 0) + 48}px)` },
  })
)<TooltipDivProps>`
  left: ${({ left }) => (left ? `${left}px` : 'auto')};
  right: ${({ right }) => (right ? `${right}px` : 'auto')};
  top: auto;

  width: 300px;

  display: inline-block;

  white-space: pre-line;

  pointer-events: none;
`;

export default TargetTable;
