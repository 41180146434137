import React from 'react';
import { useSelector } from 'react-redux';

import styled, { css } from 'styled-components';

import { getWorkPackageById } from '../../../store/reducers/workPackage';

import Modal, {
  Content,
  Header,
  Container,
} from '../../../components/Modal/Modal';
import Txt from '../../../components/Txt';

import EditWorkPackageForm from './NewEditWorkPackageForm';
import ScheduleTree from './NewScheduleTree';

interface EditWorkPackageProps {
  onClose: () => void;
  workPackageId: string;
  headerInfo: { code: string; name: string };
}

const EditWorkSectionModal = ({
  onClose,
  workPackageId,
  headerInfo,
}: EditWorkPackageProps) => {
  const [isScheduleInUse, setScheduleInUse] = React.useState<boolean>(false);

  const [scheduleTreeSelection, setScheduleTreeSelection] = React.useState<{
    workSectionClassId?: string | null;
    workPackageVirtualSpaceLinkages?: {
      nextgenWorkPackageId: string | null;
      virtualSpaceId: string | null;
    }[];
  }>({});

  const workPackage = useSelector(getWorkPackageById(workPackageId));

  const memoizedWorkPackage = React.useMemo(() => workPackage, [workPackage]);

  React.useEffect(() => {
    if (memoizedWorkPackage) {
      if (
        memoizedWorkPackage.workSectionClassId ||
        memoizedWorkPackage.workPackageVirtualSpaceLinkages.length > 0
      ) {
        setScheduleInUse(true);
      }

      setScheduleTreeSelection({
        workSectionClassId: memoizedWorkPackage.workSectionClassId,
        workPackageVirtualSpaceLinkages:
          memoizedWorkPackage.workPackageVirtualSpaceLinkages,
      });
    }
  }, [memoizedWorkPackage]);

  if (!workPackage) {
    return null;
  }

  return (
    <Modal onClose={onClose} overflowVisible>
      <StyledContainer isScheduleInUse={isScheduleInUse}>
        <Header>
          <Txt
            id="worksection.workpackage.editModal.header"
            values={headerInfo}
          />
        </Header>
        <StyledContent noMaxHeight>
          <EditWorkPackageForm
            workPackage={workPackage}
            onClose={onClose}
            isScheduleInUse={isScheduleInUse}
            setScheduleInUse={setScheduleInUse}
            scheduleTreeSelection={scheduleTreeSelection}
            setScheduleTreeSelection={setScheduleTreeSelection}
          />
          {isScheduleInUse ? (
            <ScheduleTree
              workPackage={workPackage}
              scheduleTreeSelection={scheduleTreeSelection}
              setScheduleTreeSelection={setScheduleTreeSelection}
            />
          ) : null}
        </StyledContent>
      </StyledContainer>
    </Modal>
  );
};

export default EditWorkSectionModal;

const StyledContainer = styled(Container)<{ isScheduleInUse: boolean }>`
  ${(props) =>
    props.isScheduleInUse
      ? css`
          width: 70vw;
          height: 85vh;
        `
      : css`
          width: 52rem;
          max-height: 90vh;
          transition: all 0.5s;
        `}
`;

const StyledContent = styled(Content)`
  padding-right: ${(props) => `${props.theme.margin[32]}`};
`;
