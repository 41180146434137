import React from 'react';
import { useHistory } from 'react-router-dom';

import { ColumnDef } from '@tanstack/react-table';
import Big from 'big.js';
import styled, { css } from 'styled-components';

import Txt from '../../../components/Txt';
import {
  TargetRowDescription,
  InvalidIconAndTooltip,
  StyledCheckbox,
  UpdateInformationAndIcon,
} from '../components/TargetTable/TableCells';
import {
  ReferenceNoTh,
  TargetTableSearchInput,
} from '../components/TargetTable/Th';

import * as big from '../../../utils/big';
import { isClickOrKeyboardSelection } from '../../../utils/mouseOrKeyInteraction';

import { generateUrl, routes, useParams } from '../../../routes';

import { TargetRowOrTargetRowHierarchyEntry } from './useTargetViewData';

const textIdHead = 'target.table.header.';

const originalColumnRelativeSizes = {
  referenceNo: 70,
  description: 250,
  quantity: 50,
  unit: 30,
  unitPrice: 50,
  target: 70,
  orderCodeName: 80,
  workSectionCodeName: 80,
} as const;

export default function useTargetViewColumns(totalColumnWidth?: number) {
  const { viewMode } = useParams(routes.TARGET);
  const history = useHistory();

  const onClickOrKeyboardSelection = (
    e: React.MouseEvent | React.KeyboardEvent,
    callback: () => void
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      callback();
    }
  };

  const onClickOrder = React.useCallback(
    (projectId: string, orderId: string | null, topicId: string | null) => {
      if (!orderId || !topicId) {
        return;
      }

      history.push(
        generateUrl({
          route: routes.ORDER_WITH_OPEN_TOPIC,
          projectId,
          orderId,
          topicId,
          showTargetRows: 'showTarget',
        })
      );
    },
    [history]
  );

  const onClickWorkPackage = React.useCallback(
    (projectId: string, workPackageId: string | undefined | null) => {
      if (!workPackageId) {
        return;
      }

      const nextUrl = generateUrl({
        route: routes.WORKSECTION_EXPANDED,
        projectId,
        workPackageId,
      });

      history.push(nextUrl);
    },
    [history]
  );

  const relativeColumnSizesTotal = Object.values(
    originalColumnRelativeSizes
  ).reduce((acc, size) => acc + size, 0);

  const calculateColumnSize = React.useCallback(
    (relativeSize: number | undefined) => {
      if (!relativeSize) {
        return relativeSize;
      }

      return Math.round(
        (totalColumnWidth ?? relativeSize) *
          (relativeSize / relativeColumnSizesTotal)
      );
    },
    [relativeColumnSizesTotal, totalColumnWidth]
  );

  const columns = React.useMemo<
    ColumnDef<TargetRowOrTargetRowHierarchyEntry>[]
  >(
    () => [
      {
        id: 'referenceNo',
        accessorKey: 'referenceNo',
        header: ({ table }) => {
          return <ReferenceNoTh table={table} />;
        },
        cell: ({ getValue, row, virtualRowStart, table }) => {
          const value =
            getValue<TargetRowOrTargetRowHierarchyEntry['referenceNo']>();

          const onChange = () => {
            const selectionState = table.getState().rowSelection;
            const childRows = row.getLeafRows();
            const isSelected = row.getIsSelected();

            const newState = { ...selectionState };

            for (let i = 0; i < childRows.length; i++) {
              const childRow = childRows[i];

              delete newState[childRow.id];
            }

            newState[row.id] = !isSelected;

            table.setRowSelection(newState);
          };

          const leafRows = row.getLeafRows();

          const isChildSelected = leafRows.some((childRow) =>
            childRow.getIsSelected()
          );

          const targetRowLeafs = leafRows.filter(
            (childRow) =>
              childRow.original.type === 'targetRow' &&
              childRow.original.isDeleted === false &&
              childRow.original.isDisabled === false &&
              childRow.original.isAntiRow === false
          );

          const allChildrenSelectedOrDisabled =
            row.getIsSelected() || targetRowLeafs.length === 0
              ? false
              : targetRowLeafs.every(
                  (childRow) =>
                    childRow.getIsSelected() || !childRow.getCanSelect()
                );

          return (
            <RowDiv>
              {viewMode === 'edit' ? (
                <StyledCheckbox
                  allChildrenSelected={allChildrenSelectedOrDisabled}
                  indeterminate={isChildSelected}
                  checked={row.getIsSelected()}
                  onChange={() => onChange()}
                  disabled={!row.getCanSelect()}
                />
              ) : null}
              <UpdateInformationAndIcon
                row={row}
                virtualRowStart={virtualRowStart}
              />
              <InvalidIconAndTooltip
                row={row}
                keys={['rowCode', 'parentCode', 'referenceNo']}
                virtualRowStart={virtualRowStart}
              />
              {value}
            </RowDiv>
          );
        },
        size: calculateColumnSize(originalColumnRelativeSizes['referenceNo']),
        minSize: 100,
        maxSize: 700,
        meta: {
          align: 'left',
        },
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: ({ table }) => {
          return (
            <StyledDescriptionDiv>
              <Txt id={`${textIdHead}description` as const} component="b" />
              <TargetTableSearchInput table={table} />
            </StyledDescriptionDiv>
          );
        },
        cell: ({ row, virtualRowStart }) => {
          return (
            <TargetRowDescription row={row} virtualRowStart={virtualRowStart} />
          );
        },
        size: calculateColumnSize(originalColumnRelativeSizes['description']),
        minSize: 200,
        maxSize: 1500,
        meta: {
          align: 'left',
          borderWidthRight: 2,
        },
      },
      {
        id: 'quantity',
        accessorKey: 'quantity',
        header: () => {
          return <Txt id={`${textIdHead}quantity` as const} component="b" />;
        },
        cell: ({ getValue, row, virtualRowStart }) => {
          const value =
            getValue<TargetRowOrTargetRowHierarchyEntry['quantity']>();

          return (
            <>
              <InvalidIconAndTooltip
                row={row}
                keys={['quantity']}
                virtualRowStart={virtualRowStart}
              />
              {big.amountFormat(value ?? new Big(0))}
            </>
          );
        },
        size: calculateColumnSize(originalColumnRelativeSizes['quantity']),
        minSize: 100,
        maxSize: 500,
        meta: {
          align: 'right',
          borderWidthRight: 1,
        },
      },
      {
        id: 'unit',
        accessorKey: 'unit',
        header: () => {
          return <Txt id={`${textIdHead}unit` as const} component="b" />;
        },
        cell: ({ getValue, row, virtualRowStart }) => {
          const value = getValue<TargetRowOrTargetRowHierarchyEntry['unit']>();

          return (
            <>
              <InvalidIconAndTooltip
                row={row}
                keys={['unit']}
                virtualRowStart={virtualRowStart}
              />
              {value}
            </>
          );
        },
        size: calculateColumnSize(originalColumnRelativeSizes['unit']),
        minSize: 50,
        maxSize: 500,
        meta: {
          align: 'left',
          borderWidthRight: 1,
        },
      },
      {
        id: 'unitPrice',
        accessorKey: 'unitPrice',
        header: () => {
          return <Txt id={`${textIdHead}unitPrice` as const} component="b" />;
        },
        cell: ({ getValue, row, virtualRowStart }) => {
          const value =
            getValue<TargetRowOrTargetRowHierarchyEntry['unitPrice']>();

          return (
            <>
              <InvalidIconAndTooltip
                row={row}
                keys={['unitPrice']}
                virtualRowStart={virtualRowStart}
              />
              {big.amountFormat(value ?? new Big(0))}
            </>
          );
        },
        size: calculateColumnSize(originalColumnRelativeSizes['unitPrice']),
        minSize: 100,
        maxSize: 500,
        meta: {
          align: 'right',
          borderWidthRight: 1,
        },
      },
      {
        id: 'target',
        accessorKey: 'target',
        header: () => {
          return <Txt id={`${textIdHead}target` as const} component="b" />;
        },
        cell: ({ row, getValue, virtualRowStart }) => {
          const value =
            getValue<TargetRowOrTargetRowHierarchyEntry['target']>() ??
            new Big(0);

          const filteredTarget = row.original.filteredTarget ?? new Big(0);

          const isFiltered = value.minus(filteredTarget).abs().gt(0.5);

          return (
            <>
              <InvalidIconAndTooltip
                row={row}
                keys={['target']}
                virtualRowStart={virtualRowStart}
              />
              <StyledTargetSpan isFiltered={isFiltered}>
                {big.priceFormat(filteredTarget)}
              </StyledTargetSpan>
            </>
          );
        },
        size: calculateColumnSize(originalColumnRelativeSizes['target']),
        minSize: 100,
        maxSize: 500,
        meta: {
          align: 'right',
          borderWidthRight: 1,
        },
      },
      {
        id: 'orderCodeName',
        accessorKey: 'orderCodeName',
        header: () => {
          return <Txt id={`${textIdHead}order` as const} component="b" />;
        },
        cell: ({ row, virtualRowStart }) => {
          const { topicId, projectId, orderId, orderCode, orderName } =
            row.original;

          const value = orderCode ? `${orderCode} ${orderName}` : null;

          return value ? (
            <StyledPointerSpan
              tabIndex={0}
              isClickable={!!topicId}
              onKeyDown={(e) =>
                onClickOrKeyboardSelection(e, () =>
                  onClickOrder(projectId, orderId, topicId)
                )
              }
              onClick={(e) =>
                onClickOrKeyboardSelection(e, () =>
                  onClickOrder(projectId, orderId, topicId)
                )
              }
            >
              <InvalidIconAndTooltip
                row={row}
                keys={['procurementCode']}
                virtualRowStart={virtualRowStart}
              />
              {value}
            </StyledPointerSpan>
          ) : null;
        },
        size: calculateColumnSize(originalColumnRelativeSizes['orderCodeName']),
        minSize: 100,
        maxSize: 500,
        meta: {
          align: 'left',
          borderWidthRight: 1,
        },
      },
      {
        id: 'workSectionCodeName',
        accessorKey: 'workSectionCodeName',
        header: () => {
          return <Txt id={`${textIdHead}workSection` as const} component="b" />;
        },
        cell: ({ row, virtualRowStart }) => {
          const { projectId, workPackageId, workPackageCode, workPackageName } =
            row.original;

          const value = workPackageCode
            ? `${workPackageCode} ${workPackageName}`
            : null;

          return value ? (
            <StyledPointerSpan
              tabIndex={0}
              isClickable={!!workPackageId}
              onKeyDown={(e) =>
                onClickOrKeyboardSelection(e, () =>
                  onClickWorkPackage(projectId, workPackageId)
                )
              }
              onClick={(e) =>
                onClickOrKeyboardSelection(e, () =>
                  onClickWorkPackage(projectId, workPackageId)
                )
              }
            >
              <InvalidIconAndTooltip
                row={row}
                keys={['workSectionCode']}
                virtualRowStart={virtualRowStart}
              />
              {value}
            </StyledPointerSpan>
          ) : null;
        },
        size: calculateColumnSize(
          originalColumnRelativeSizes['workSectionCodeName']
        ),
        minSize: 100,
        maxSize: 500,
        meta: {
          align: 'left',
        },
      },
    ],
    [onClickOrder, onClickWorkPackage, viewMode, calculateColumnSize]
  );

  return columns;
}

const StyledPointerSpan = styled.span<{ isClickable: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ isClickable }) =>
    isClickable
      ? css`
          cursor: pointer;
        `
      : null}
  overflow: hidden;
`;

const StyledDescriptionDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StyledTargetSpan = styled.span<{ isFiltered: boolean }>`
  ${({ isFiltered }) =>
    isFiltered
      ? css`
          color: ${({ theme }) => theme.color['M3/ref/primary/primary60']};
        `
      : null}
`;
