import React, { memo } from 'react';

import { flexRender, Row } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';
import styled, { css } from 'styled-components';

import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import { GridCellTd } from '../../../../components/Cell';

import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

import { routes, useParams } from '../../../../routes';

import styleTheme from '../../../../styles/theme';

type TargetRowOrTargetRowHierarchyEntryProps = {
  virtualRow: VirtualItem;
  row: Row<TargetRowOrTargetRowHierarchyEntry>;
  measureElement: (node: Element | null) => void;
  containerWidth: number;
  isSelected?: boolean;
  isSelectionDisabled?: boolean;
  isChildSelected?: boolean;
  allChildrenSelectedOrDisabled?: boolean;
  columnSizes: {
    [key: string]: number;
  };
};

const TargetViewRow = memo(
  ({
    virtualRow,
    row,
    measureElement,
    columnSizes,
  }: TargetRowOrTargetRowHierarchyEntryProps) => {
    const { viewMode } = useParams(routes.TARGET);

    const toggleRow = () => {
      if (row.getCanExpand()) {
        row.toggleExpanded();
      }
    };

    const onClickOrKeyPressRow = (
      e:
        | React.KeyboardEvent<HTMLTableRowElement>
        | React.MouseEvent<HTMLTableRowElement>
    ) => {
      if (isClickOrKeyboardSelection(e)) {
        e.preventDefault();
        toggleRow();
      }
    };

    const needsTooltip = () => {
      if (row.original.invalid) {
        return true;
      }

      if (row.original.isSplitFrom) {
        return true;
      }

      if (row.original.splitTo) {
        return true;
      }

      if (
        row.original.updateInformation &&
        row.original.updateInformation.changeType === 'update'
      ) {
        return true;
      }

      return false;
    };

    const showTooltip = needsTooltip();

    const memoizedVirtualRowStart = React.useMemo(() => {
      if (showTooltip) {
        return virtualRow.start;
      }

      return undefined;
    }, [virtualRow.start, showTooltip]);

    return (
      <StyledTr
        key={row.id}
        virtualRow={virtualRow}
        data-index={virtualRow.index}
        ref={(node) => measureElement(node)}
        parent={row.depth === 0 && row.subRows.length > 0}
        canExpand={row.getCanExpand()}
        depth={row.depth}
        onClick={onClickOrKeyPressRow}
        onKeyPress={onClickOrKeyPressRow}
        tabIndex={0}
        isLinkedToOrderRow={!!row.original.orderRowId}
        isHighlighted={row.original.isHighLighted}
        isDisabled={row.original.isDisabled}
        invalid={row.original.invalid}
        isDeleted={row.original.isDeleted}
        changeType={row.original.updateInformation?.changeType}
        viewMode={viewMode}
        noHierarchyEntryParent={
          row.original.id === 'hierarchyEntry-none' ||
          row.original.targetRowHierarchyEntryId === 'hierarchyEntry-none'
        }
      >
        {row.getVisibleCells().map((cell) => {
          return (
            <StyledTd
              key={cell.id}
              width={columnSizes[cell.column.id] ?? 0}
              align={cell.column.columnDef?.meta?.align}
              borderWidthRight={cell.column.columnDef?.meta?.borderWidthRight}
            >
              {flexRender(cell.column.columnDef.cell, {
                ...cell.getContext(),
                virtualRowStart: memoizedVirtualRowStart,
              })}
            </StyledTd>
          );
        })}
      </StyledTr>
    );
  }
);

type TrProps = {
  virtualRow: VirtualItem;
  parent: boolean;
  noHierarchyEntryParent?: boolean;
  isHighlighted?: boolean;
  isDisabled?: boolean;
  isLinkedToOrderRow?: boolean;
  depth: number;
  canExpand: boolean;
  invalid?: boolean;
  isDeleted?: boolean;
  changeType?: 'update' | 'create' | 'delete' | 'noChange';
  viewMode?: 'edit';
};

const backgroundColor = (props: TrProps) => {
  if (props.invalid) {
    return css`
      background-color: ${styleTheme.color['M3/ref/error/error90']};
    `;
  }

  if (props.isDisabled) {
    return css`
      background-color: ${styleTheme.color.graphiteB76};
    `;
  }

  if (props.isHighlighted) {
    return css`
      background-color: ${styleTheme.color['M3/ref/primary/primary95']};
    `;
  }

  if (props.isDeleted) {
    return css`
      background: ${({ theme }) => theme.color.graphiteB86};
    `;
  }

  if (props.isLinkedToOrderRow && props.viewMode === 'edit') {
    return css`
      background: ${({ theme }) => theme.color.beryl};
    `;
  }

  if (props.changeType) {
    switch (props.changeType) {
      case 'update':
        return css`
          background: rgb(244 183 107 / 29%);
        `;
      case 'delete':
        return css`
          background: ${({ theme }) => theme.color.graphiteB86};
        `;
      case 'create':
        return css`
          background: rgb(141 222 129 / 29%);
        `;
    }
  }
};

const StyledTr = styled.tr.attrs<TrProps>(({ virtualRow }) => ({
  style: { transform: `translateY(${virtualRow.start}px)` },
}))<TrProps>`
  position: absolute;

  border-bottom: ${({ parent }) => (parent ? 2 : 1)}px solid
    ${(props) => props.theme.color.rowBorder};

  width: 100%;
  height: ${({ parent }) => (parent ? 48 : 32)}px;

  display: flex;

  ${(props) => backgroundColor(props)}

  font-size: ${({ depth, theme }) =>
    depth === 0 ? theme.fontSize.h2 : theme.fontSize.base};
  ${({ canExpand }) =>
    canExpand
      ? css`
          cursor: pointer;
        `
      : ''}
  ${({ noHierarchyEntryParent }) =>
    noHierarchyEntryParent
      ? css`
          margin-top: ${(props) => props.theme.margin[40]};
        `
      : ''}
    ${({ noHierarchyEntryParent, parent }) =>
    noHierarchyEntryParent && parent
      ? css`
          border-top: 2px solid ${(props) => props.theme.color.rowBorder};
        `
      : ''}
`;

type TdProps = {
  width: number;
  borderWidthRight?: number;
};

const StyledTd = styled(GridCellTd).attrs<TdProps>(({ width }) => ({
  style: { width: `${width}px` },
}))<TdProps>`
  border-right: ${({ borderWidthRight }) => borderWidthRight ?? 0}px solid
    ${(props) => props.theme.color.rowBorder};
  flex-flow: nowrap;
`;

export default TargetViewRow;
