import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { APIWorkPackage } from '@customtypes/api';

import { selectDeleteWorkPackageRequest } from '../../../store/reducers/workPackage';

import { deleteWorkSection } from '../../../store/actions/workPackage';

import useTxt from '../../../hooks/useTxt';

import { IconButton } from '../../../components/Buttons';
import { DropDownItem, DropDownList } from '../../../components/DropdownList';
import ToolTip from '../../../components/Tooltip';

import { isClickOrKeyboardSelection } from '../../../utils/mouseOrKeyInteraction';

import { IconKebabMenu, IconDelete } from '../../../assets/svg';

import { generateUrl, routes } from '../../../routes';

type WorkSectionKebabMenuProps = {
  workSection: APIWorkPackage;
  noMargin?: boolean;
  onClose: () => void;
};

export const WorkSectionKebabMenu = ({
  workSection,
  noMargin,
  onClose,
}: WorkSectionKebabMenuProps) => {
  const [isMenuOpen, setDropDownVisibility] = React.useState(false);
  const history = useHistory();

  const deleteRequestState = useSelector(
    selectDeleteWorkPackageRequest(workSection.id)
  );

  React.useEffect(() => {
    if (deleteRequestState.kind === 'Success') {
      const url = generateUrl({
        route: routes.WORKSECTIONS,
        projectId: workSection.projectId,
      });

      console.log(url);
      history.push(url);
      onClose();
    }
  }, [deleteRequestState.kind, onClose, history, workSection.projectId]);

  const dispatch = useDispatch();

  const deleteText = useTxt('worksection.workpackage.delete');
  const deleteTooltipText = useTxt('worksection.workpackage.delete.tooltip');
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const onKebabMenuPress = (
    e: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent
  ) => {
    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();

      return setDropDownVisibility((state) => {
        const newState = !state;

        return newState;
      });
    }
  };

  const onDelete = (
    e: React.KeyboardEvent<HTMLSpanElement> | React.MouseEvent
  ) => {
    if (isClickOrKeyboardSelection(e) && workSection.isDeletable) {
      e.preventDefault();
      setDropDownVisibility(false);
      dispatch(deleteWorkSection(workSection.id));
    }
  };

  return (
    <div>
      {isMenuOpen ? (
        <StyledDropdownList
          role="menu"
          onClose={() => setDropDownVisibility(false)}
          ref={buttonRef}
        >
          <ToolTip tip={deleteTooltipText}>
            <DropDownItem
              disabled={!workSection.isDeletable}
              tabIndex={0}
              role="menuitem"
              onClick={onDelete}
              onKeyDown={onDelete}
            >
              <IconDelete />
              {deleteText}
            </DropDownItem>
          </ToolTip>
        </StyledDropdownList>
      ) : null}
      <StyledIconButton
        icon={IconKebabMenu}
        onClick={onKebabMenuPress}
        onKeyPress={onKebabMenuPress}
        ref={buttonRef}
        customSize="24px"
        noMargin={noMargin}
      />
    </div>
  );
};

const StyledDropdownList = styled(DropDownList)`
  right: auto;
  top: auto;
  margin: ${({ theme }) => theme.margin[16]};
  padding: ${({ theme }) => theme.margin[16]} 0;
`;

const StyledIconButton = styled(IconButton)<{ noMargin?: boolean }>`
  margin-bottom: ${({ theme, noMargin }) =>
    noMargin ? '0' : theme.margin[24]};
`;
