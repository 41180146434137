import React from 'react';

function useOnClickOutside(
  onClickOutside: () => undefined | void,
  exceptions?: React.ForwardedRef<HTMLElement>[]
) {
  const ref = React.useRef(null);

  const clickListener = React.useCallback(
    (e: MouseEvent) => {
      if (ref && ref.current) {
        if (!(ref.current! as any).contains(e.target)) {
          if (
            !exceptions ||
            !exceptions.some((exception) => {
              if (typeof exception === 'object' && exception) {
                return (exception.current! as any).contains(e.target);
              }

              return false;
            })
          ) {
            onClickOutside();
          }
        }
      }
    },
    [ref, onClickOutside, exceptions]
  );

  React.useEffect(() => {
    document.addEventListener('click', clickListener, true);

    return () => {
      document.removeEventListener('click', clickListener, true);
    };
  }, [clickListener]);

  return ref;
}

export default useOnClickOutside;
