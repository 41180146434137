import React from 'react';

import styled from 'styled-components';

import { APIProject } from '../../store/reducers/project';

import useTxt from '../../hooks/useTxt';

import CAN, { CaslProjectRequestParams } from '../../utils/caslUserPermissions';
import { isClickOrKeyboardSelection } from '../../utils/mouseOrKeyInteraction';

import { IconKebabMenu, IconPersonAlt } from '../../assets/svg';

import { BaseButton, IconButton } from '../Buttons';
import { DropDownItem, DropDownList } from '../DropdownList';

export const ProjectMenuContainer = styled.div`
  padding: ${(props) => props.theme.margin[16]};
  height: 25rem;
  width: 100%;
  white-space: break-spaces;
`;

export const ProjectsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const ProjectButton = styled(BaseButton)`
  margin: ${(props) => props.theme.margin[8]} 0;
  color: ${(props) => props.theme.color.pitch};
`;

type ProjectMenuItemProps = {
  project: APIProject;
  onClickProject: () => void;
  toggleUserRoleMenu: () => void;
};

export const ProjectMenuItem = ({
  project,
  onClickProject,
  toggleUserRoleMenu,
}: ProjectMenuItemProps) => {
  const [isMenuOpen, setDropDownVisibility] = React.useState(false);
  const ability = new CaslProjectRequestParams(project.id);
  const canEditProject = CAN('write', ability);

  const userRightManagementTxt = useTxt(
    'navigation.projectMenu.userManagement'
  );
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const onKebabMenuPress = (
    e: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent
  ) => {
    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();

      return setDropDownVisibility((state) => {
        const newState = !state;

        return newState;
      });
    }
  };

  const onEditUserRights = (
    e: React.KeyboardEvent<HTMLSpanElement> | React.MouseEvent
  ) => {
    if (isClickOrKeyboardSelection(e) && canEditProject) {
      e.preventDefault();
      setDropDownVisibility(false);
      toggleUserRoleMenu();
    }
  };

  return (
    <StyledProjectRow key={project.id}>
      <ProjectButton
        onClick={() => onClickProject()}
        key={`project-selection-${project.id}`}
        data-testid="project-selection-button"
      >
        {`${project.code} ${project.name}`}
      </ProjectButton>
      {canEditProject ? (
        <>
          <IconButton
            icon={IconKebabMenu}
            onClick={onKebabMenuPress}
            onKeyPress={onKebabMenuPress}
            ref={buttonRef}
          />
          {isMenuOpen ? (
            <StyledDropdownList
              role="menu"
              onClose={() => setDropDownVisibility(false)}
              ref={buttonRef}
            >
              <DropDownItem
                disabled={!canEditProject}
                tabIndex={0}
                role="menuitem"
                onClick={onEditUserRights}
                onKeyDown={onEditUserRights}
              >
                <IconPersonAlt />
                {userRightManagementTxt}
              </DropDownItem>
            </StyledDropdownList>
          ) : null}
        </>
      ) : null}
    </StyledProjectRow>
  );
};

const StyledProjectRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDropdownList = styled(DropDownList)`
  padding: ${({ theme }) => theme.margin[16]} 0;
`;
