import {
  APIWorkPackage,
  RawAPIWorkPackage,
  APIWorkPackagePutBody,
  APIWorkPackagePostBody,
  RawAPIUpdatedEntities,
  APIUpdatedEntities,
} from '../../types/api';
import { ID } from '../../types/general';
import { toAPIWorkPackage, mapRawUpdatedEntities } from '../../types/mappers';

import {
  makeAction,
  makeApiActions,
  ExtractActionTypes,
} from '../../utils/actionCreators';
import {
  GET,
  PUT,
  POST,
  BackendError,
  apiErrorHandlingWithDecode,
  DELETE,
} from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, combineThunks, Thunk } from '../../utils/thunk';

import {
  selectDeleteWorkPackageRequest,
  selectProjectWorkPackages,
} from '../reducers/workPackage';
import { fetchOrdersForProject } from './order/order';
import { fetchTopicsForWorkPackage } from './topic';
import { SortableKey } from '../reducers/workSection/sortWorkSections';

export type WorkPackageAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getWorkPackagesStarted')<{ projectId: string }>(),
  ...makeAction('getWorkPackagesFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getWorkPackagesSuccess')<{
    projectId: string;
    workPackages: APIWorkPackage[];
  }>(),
  ...makeAction('workSectionSortOrderToggled')<{
    sortableKey: SortableKey;
  }>(),
  ...makeApiActions('put', 'workpackage')<APIUpdatedEntities>(),
  ...makeApiActions('post', 'workpackage')<APIUpdatedEntities>(),
  ...makeAction('deleteWorkPackageStarted')<{
    workPackageId: string;
  }>(),
  ...makeAction('deleteWorkPackageFailure')<{
    workPackageId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('deleteWorkPackageSuccess')<
    APIUpdatedEntities & {
      workPackageId: string;
    }
  >(),
};
export const {
  getWorkPackagesStarted,
  getWorkPackagesFailure,
  getWorkPackagesSuccess,
  putWorkpackageStarted,
  putWorkpackageFailure,
  putWorkpackageSuccess,
  deleteWorkPackageStarted,
  deleteWorkPackageFailure,
  deleteWorkPackageSuccess,
  postWorkpackageStarted,
  postWorkpackageFailure,
  postWorkpackageSuccess,
  workSectionSortOrderToggled,
} = actionCreators;

const getWorkPackages = async (projectId: ID) => {
  const rawWorkPackages = await GET<RawAPIWorkPackage[]>(
    `v1/projects/${projectId}/work-packages`
  );

  return rawWorkPackages.map(toAPIWorkPackage);
};

export const fetchWorkPackagesForProject = (projectId: ID) =>
  createAsyncThunk(getWorkPackages, {
    args: [projectId],
    isPending: flow(selectProjectWorkPackages(projectId), remoteData.isLoading),
    initialAction: getWorkPackagesStarted({ projectId }),
    successActionCreator: (workPackages) =>
      getWorkPackagesSuccess({ projectId, workPackages }),
    failureActionCreator: (error) =>
      getWorkPackagesFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

export const fetchWorkSectionRows = ({
  projectId,
  workPackageId,
}: {
  projectId: string;
  workPackageId: string;
}) =>
  combineThunks(
    fetchOrdersForProject(projectId),
    fetchTopicsForWorkPackage(workPackageId)
  );

export const updateWorkSection =
  (
    workPackageId: ID,
    body: APIWorkPackagePutBody,
    onEditSuccess?: (_res: any) => void
  ): Thunk =>
  (dispatch, _) => {
    dispatch(putWorkpackageStarted());

    PUT<RawAPIUpdatedEntities>(`v1/work-packages/${workPackageId}/`, body)
      .then(mapRawUpdatedEntities)
      .then((updatedEntities) => {
        dispatch(putWorkpackageSuccess(updatedEntities));

        if (onEditSuccess) {
          onEditSuccess(updatedEntities);
        }
      })
      .catch((error) => {
        dispatch(putWorkpackageFailure(apiErrorHandlingWithDecode(error)));
      });
  };

export const createWorkSection =
  (
    body: APIWorkPackagePostBody,
    onCreateSuccess?: (addedWp: APIUpdatedEntities) => void
  ): Thunk =>
  (dispatch, _) => {
    dispatch(postWorkpackageStarted());

    POST<RawAPIUpdatedEntities>(`v1/work-packages/`, body)
      .then(mapRawUpdatedEntities)
      .then((updatedEntities) => {
        dispatch(postWorkpackageSuccess(updatedEntities));

        if (onCreateSuccess) {
          onCreateSuccess(updatedEntities);
        }
      })
      .catch((error) => {
        dispatch(postWorkpackageFailure(apiErrorHandlingWithDecode(error)));
      });
  };

const deleteWorkSectionRequest = async (workPackageId: ID) => {
  const rawUpdatedEntities = await DELETE<RawAPIUpdatedEntities>(
    `v1/work-packages/${workPackageId}`
  );

  return mapRawUpdatedEntities(rawUpdatedEntities);
};

export const deleteWorkSection = (workPackageId: ID) =>
  createAsyncThunk(deleteWorkSectionRequest, {
    args: [workPackageId],
    isPending: flow(
      selectDeleteWorkPackageRequest(workPackageId),
      remoteData.isLoading
    ),
    initialAction: deleteWorkPackageStarted({ workPackageId }),
    successActionCreator: (updatedEntities) =>
      deleteWorkPackageSuccess({ workPackageId, ...updatedEntities }),
    failureActionCreator: (error) =>
      deleteWorkPackageFailure({
        workPackageId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
