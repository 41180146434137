import moment from 'moment';

import { ActualCost } from '../store/reducers/actualCost';
import { InvoiceHeader } from '../store/reducers/invoiceHeader';

import { DisallowedAccountingCombination, OrderOption } from '../store/actions';

import { APICommonProps, APIOrderRowPostBody } from '../types/api';
import { ID, GlobalState, MonthNumber } from '../types/general';

export const ORDER_ROW_STATUS_CONTRACT = '2';
export const ORDER_ROW_STATUS_CHANGEORDER = '3';
export const ORDER_ROW_STATUS_RESERVES = '1';

export const INVOICE_HEADER_STATUS_NOT_HANDLED = ['1'];
export const INVOICE_HEADER_STATUS_ACCEPTED = ['2', '3'];
export const INVOICE_HEADER_STATUS_COMPLAINT_FILED = ['4'];
export const INVOICE_HEADER_STATUS_DECLINED = ['5', '6'];
export const INVOICE_HEADER_STATUS_BEING_CORRECTED = ['7', '8', '11', '12'];
export const INVOICE_HEADER_STATUS_CORRECTED = ['9', '10'];

export const ALL_UNPROCESSED_INVOICE_STATUSES =
  INVOICE_HEADER_STATUS_NOT_HANDLED.concat(
    INVOICE_HEADER_STATUS_COMPLAINT_FILED
  ).concat(INVOICE_HEADER_STATUS_BEING_CORRECTED);

export const ACTUAL_COST_STATUS_NOT_HANDLED = '1';
export const ACTUAL_COST_STATUS_HANDLED = '2';
export const ACTUAL_COST_STATUS_CANCELED = '3';

export const REVENUE_ROW_STATUS_CONTRACT = '2';

export const MAX_YEARS_FROM_NOW = 10;

export const calculateTotals = (numbers?: number[]) =>
  numbers ? numbers.reduce((a, b) => a + b, 0) : 0;

// TODO: Make unit, quantity and unitPrice nullable instead of being 0 or empty
// string. This needs backend support.
export const emptyOrderRow = (
  orderId: ID,
  topicId: ID
): APIOrderRowPostBody => ({
  description: '',
  quantity: null,
  unit: '',
  unitPrice: null,
  orderId,
  topicId,
  statusId: ORDER_ROW_STATUS_CONTRACT,
});

export const emptyTopic = (orderId: ID) => ({
  orderId,
  name: '',
  workPackageId: null,
});

export const emptyPaymentProgramRow = (paymentProgramRowGroupId: ID) => ({
  description: '',
  quantity: null,
  unit: '',
  unitPriceWithoutVat: null,
  vatPrc: null,
  billingDate: Date.now(),
  paymentProgramRowGroupId,
  statusId: REVENUE_ROW_STATUS_CONTRACT,
});

export const allInitialized = <T>(state: GlobalState<T>, ids: ID[]) =>
  ids.every((id) => state.meta.isInitialized[id]);

export const initialGlobalState: GlobalState<any> = {
  meta: {
    isInitialized: {},
  },
  data: {},
};

// Helpers for filtering with type guards. Usage: e.g. array.filter(isDefined)
// Issue: https://github.com/microsoft/TypeScript/issues/16069
export const isDefined = <T>(t: T | undefined): t is T => t !== undefined;

export const isNotNull = <T>(t: T | null): t is T => t !== null;

export const isMonthNumber = (t: MonthNumber | string): t is MonthNumber =>
  Number(t) > -1 && Number(t) < 12 && Number.isInteger(Number(t));

export const isPresent = <T>(t: T | undefined | null): t is NonNullable<T> =>
  isDefined(t) && isNotNull(t);

export function isInvoiceHeader(
  row: InvoiceHeader | ActualCost
): row is InvoiceHeader {
  return (row as InvoiceHeader).vendorInvoiceNo !== undefined;
}

// checks if a given string includes a percentage
export const containsPercentage = (str: string) => {
  const percentageRegex = /%/;

  return percentageRegex.test(str);
};

export function updateRecord<T extends APICommonProps>(
  whatevers: Record<string, T>,
  updates: T[]
): Record<string, T> {
  return updates.reduce(
    (result, update) => {
      if (update.isDeleted) {
        const { [update.id]: _, ...remaining } = result;

        return remaining;
      }

      return { ...result, [update.id]: update };
    },
    { ...whatevers }
  );
}

export const updateGlobalStateDerivative = <T extends APICommonProps>(
  state: GlobalState<T>,
  updated: T[] | undefined
): GlobalState<T> => {
  if (!updated) {
    return state;
  }

  const data = updateRecord(state.data, updated);

  // Bake the new state:
  return {
    meta: {
      isInitialized: Object.keys(data).reduce(
        (result, id) => ({ ...result, [id]: true }),
        {}
      ),
    },
    data,
  };
};

export const concatQueryParamsWithString = (
  keysAndValues: Record<string, string[]>
): string => {
  const keyNames = Object.keys(keysAndValues);

  if (Object.keys(keysAndValues).length === 0) {
    return '';
  }

  const allKeyValues = keyNames.reduce((result, keyName) => {
    const values = keysAndValues[keyName];

    result.push(...values);

    return result;
  }, [] as string[]);

  if (allKeyValues.length === 0) {
    return '';
  }

  const returnString = keyNames.reduce((result, keyName) => {
    const values = keysAndValues[keyName];

    if (values.length === 0) {
      return result;
    }

    const keyValues = values.join('&'.concat(keyName, '='));

    let newResult = result;

    if (result.length > 1) {
      newResult = result.concat('&');
    }

    return newResult.concat(keyName.concat('='), keyValues);
  }, '?');

  return returnString;
};

export const onlyLettersAndNumbersAndDashes = (str: string) => {
  return Boolean(str.match(/^[A-Za-z0-9-]*$/));
};

export const isNumberString = (str: string) => {
  const numberRegex = /^[0-9$€.,\s\u00A0]+$/;

  const test1 = numberRegex.test(str);

  if (!test1) {
    return false;
  }

  // only 0-1 dots or currencies allowed
  const numberRegex2 = /\./g;
  const numberRegex3 = /[€$]/g;

  const test2 = str.match(numberRegex2)?.length;
  const test3 = str.match(numberRegex3)?.length;

  if (test2 && test2 > 1) {
    return false;
  }

  if (test3 && test3 > 1) {
    return false;
  }

  return true;
};

export const finnishStrictLocalizations = [
  'DD.MM.YYYY',
  'DD.M.YYYY',
  'D.MM.YYYY',
  'D.M.YYYY',
];

export const otherStrictLocalizations = [
  'MM/DD/YYYY',
  'MM/D/YYYY',
  'M/DD/YYYY',
  'M/D/YYYY',
  'MM.DD.YYYY',
  'MM.D.YYYY',
  'M.DD.YYYY',
  'M.D.YYYY',
];

export const momentValueFromInput = (inputValue: Date, lang: string = 'fi') =>
  moment(
    inputValue,
    lang === 'fi' ? finnishStrictLocalizations : otherStrictLocalizations,
    true
  );

export const getEOMonth = (date: Date): Date => {
  const year = date.getFullYear();
  const month = date.getMonth();

  return new Date(year, month + 1, 0);
};

type SelectionType = {
  projectId?: string;
  orderId?: string;
  workPackageId?: string;
  manualEntryDimensionItemId?: string;
  vatCodeId?: string;
  costTypeId?: string;
  accountId?: string;
};

type SelectionKey = keyof SelectionType;

export const filterDisallowedCombinations = (
  options: OrderOption[],
  entity: 'vatCodeId' | 'accountId',
  disallowedCombinations: DisallowedAccountingCombination[],
  selections: SelectionType
): OrderOption[] => {
  const significantCombinations = disallowedCombinations.filter(
    (combination) => {
      const selectionKeys = Object.keys(selections) as SelectionKey[];

      return selectionKeys.some((key) => {
        return combination[key] === selections[key];
      });
    }
  );

  const filteredOptions = options.filter((option) => {
    const isDisallowed = significantCombinations.some((combination) => {
      return combination[entity] === option.id;
    });

    const isAlreadySelected = selections[entity] === option.id;

    return !isDisallowed || isAlreadySelected;
  });

  return filteredOptions;
};

export const checkDueDate = (dueDate: Date | string): number => {
  let date: Date;

  if (typeof dueDate === 'string') {
    date = new Date(dueDate);
  } else if (dueDate instanceof Date) {
    date = dueDate;
  } else {
    return 0;
  }

  const today = new Date();
  const timeDiff = date.getTime() - today.getTime();

  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return daysDiff;
};

export const dayDiff = (
  a: Date | string | null,
  b: Date | string | null
): number => {
  let date1: Date;
  let date2: Date;

  if (typeof a === 'string') {
    date1 = new Date(a);
  } else if (a instanceof Date) {
    date1 = a;
  } else {
    return 0;
  }

  if (typeof b === 'string') {
    date2 = new Date(b);
  } else if (b instanceof Date) {
    date2 = b;
  } else {
    return 0;
  }

  const timeDiff = date1.getTime() - date2.getTime();

  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return daysDiff;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDuplicates<T>(arr: T[]): T[] {
  return arr.filter((item) => {
    return arr.indexOf(item) !== arr.lastIndexOf(item);
  });
}

export const areStringifiedArraysSame = (a: object[], b: object[]) =>
  JSON.stringify(a) === JSON.stringify(b);

export function recordDiff<T>(
  oldRecord: Record<string, T>,
  newRecord: Record<string, T>
) {
  const oldKeys = Object.keys(oldRecord);
  const newKeys = Object.keys(newRecord);

  const keys = [...new Set([...oldKeys, ...newKeys])];

  return keys.reduce(
    (diff, key) => {
      if (oldRecord[key] !== newRecord[key]) {
        return { ...diff, [key]: { old: oldRecord[key], new: newRecord[key] } };
      }

      return diff;
    },
    {} as Record<string, { old: T; new: T }>
  );
}
