import Big from 'big.js';
import { Reducer } from 'redux';
import { v1 as uuid } from 'uuid';

import {
  APINotificationDifferenceByOrder,
  APINotificationFeedItem,
} from '../../types/api';
import {
  NotificationUI,
  NotificationFeedItem,
  NotificationDifferenceByOrder,
  ID,
} from '../../types/general';

import InvoiceHeaderMovedNotification from '../../components/Notifications/InvoiceHeaderMovedNotification';
import InvoiceLineMovedNotification from '../../components/Notifications/InvoiceLineMovedNotification';
import InvoiceSettledNotification from '../../components/Notifications/InvoiceSettledNotification';
import OrderRowOrTargetRowMovedNotification from '../../components/Notifications/OrderRowOrTargetRowMovedNotification';
import TextOnlyNotification from '../../components/Notifications/TextOnlyNotification';
import TopicDeletedNotification from '../../components/Notifications/TopicDeletedNotification';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  assertIsNotFailureAction,
  isUpdatedEntitiesActionType,
} from './utils';
import {
  ACTUAL_COST_STATUS_HANDLED,
  INVOICE_HEADER_STATUS_ACCEPTED,
} from '../../utils/general';

import { ActionTypes } from '../actionTypes';

import { AppState } from './index';

export type NotificationState = {
  ui: NotificationUI[];
  feed: NotificationFeedItem[];
};

const initialState: NotificationState = {
  ui: [],
  feed: [],
};

const getErrorTextId = (errorMessage: string | undefined) => {
  if (!errorMessage) {
    return 'common.notification.unknownError';
  }

  if (errorMessage.includes('csrf token')) {
    return 'common.notification.invalidCsrfTokenError';
  }

  return 'common.notification.error';
};

const mapAPINotificationDifferencesByOrder = (
  diffs?: APINotificationDifferenceByOrder[]
): NotificationDifferenceByOrder[] | undefined =>
  diffs?.map((diff) => ({
    order: diff.order,
    difference: new Big(diff.difference),
  }));

const mapAPINotificationFeedItem = ({
  createdAt,
  updatedAt,
  dismissedAt,
  purchaseInvoiceHeaders,
  actualCosts,
  newOrders,
  editedOrders,
  removedOrders,
  ...rest
}: APINotificationFeedItem): NotificationFeedItem => ({
  ...rest,
  purchaseInvoiceHeaders: purchaseInvoiceHeaders.map(
    ({ amount, dueDate, ...restOfPurchaseInvoiceHeader }) => ({
      ...restOfPurchaseInvoiceHeader,
      amount: new Big(amount),
      dueDate: new Date(dueDate),
    })
  ),
  actualCosts: actualCosts.map(({ amount, date, ...restOfActualCost }) => ({
    ...restOfActualCost,
    amount: new Big(amount),
    date: new Date(date),
  })),
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
  newOrders: mapAPINotificationDifferencesByOrder(newOrders),
  editedOrders: mapAPINotificationDifferencesByOrder(editedOrders),
  removedOrders: mapAPINotificationDifferencesByOrder(removedOrders),
  dismissedAt: dismissedAt ? new Date(dismissedAt) : undefined,
});

const notificationReducer: Reducer<NotificationState, ActionTypes> = (
  state = initialState,
  action
): NotificationState => {
  switch (action.type) {
    case 'DELETE_ARRIVAL_ROW_FAILURE':
    case 'DELETE_ORDER_ROW_FAILURE':
    case 'DELETE_TOPIC_FAILURE':
    case 'DELETE_ORDER_FAILURE':
    case 'GET_ACTUAL_COSTS_FAILURE':
    case 'GET_ACTUAL_COST_LINES_FAILURE':
    case 'GET_ANALYSIS_GROUPS_FAILURE':
    case 'GET_ANALYSIS_ROWS_FAILURE':
    case 'GET_ANALYSIS_SELECT_LIST_ITEMS_FAILURE':
    case 'GET_ARRIVALS_FAILURE':
    case 'GET_ARRIVAL_ROWS_FAILURE':
    case 'GET_BATCH_GROUPS_FAILURE':
    case 'GET_INVOICE_ATTACHMENTS_FAILURE':
    case 'GET_INVOICE_HEADERS_FAILURE':
    case 'GET_INVOICE_LINES_FAILURE':
    case 'GET_INVOICE_ATTACHMENT_FILES_FAILURE':
    case 'GET_ACTUAL_COST_ATTACHMENTS_FAILURE':
    case 'GET_ACTUAL_COST_ATTACHMENT_FILES_FAILURE':
    case 'GET_NOTIFICATIONS_FAILURE':
    case 'GET_ORDERS_FAILURE':
    case 'GET_ORDER_CHANGE_LOG_ENTRIES_FAILURE':
    case 'GET_ORDER_SNAPSHOTS_FAILURE':
    case 'GET_ORDER_CHAT_MESSAGES_FAILURE':
    case 'GET_ORDER_DROPDOWN_OPTIONS_FAILURE':
    case 'GET_ORDER_ROWS_FAILURE':
    case 'GET_ORDER_ROWS_FOR_ANALYSIS_ROW_FAILURE':
    case 'GET_ORDER_ROWS_FOR_WORK_PACKAGE_FAILURE':
    case 'GET_PROCUREMENT_AREAS_FAILURE':
    case 'GET_PROJECTS_FAILURE':
    case 'GET_PROJECT_USERS_FAILURE':
    case 'GET_REVENUES_FAILURE':
    case 'GET_TARGET_ROWS_FAILURE':
    case 'GET_TOPICS_BY_ORDER_ID_FAILURE':
    case 'GET_TOPICS_BY_WORK_PACKAGE_ID_FAILURE':
    case 'GET_USER_FAILURE':
    case 'GET_POSSIBLE_ANALYSIS_LINKS_FOR_ORDER_ROW_FAILURE':
    case 'GET_POSSIBLE_ANALYSIS_LINKS_FOR_TARGET_ROW_FAILURE':
    case 'GET_TASKS_BY_ORDER_ID_FAILURE':
    case 'GET_POSSIBLE_ANALYSIS_LINKS_FOR_REVENUE_FAILURE':
    case 'GET_WORK_PACKAGES_FAILURE':
    case 'GET_WORK_PACKAGE_GROUPS_FAILURE':
    case 'MOVE_TARGET_ROWS_TO_TOPIC_FAILURE':
    case 'MOVE_ORDER_ROWS_TO_TOPIC_FAILURE':
    case 'MOVE_TARGET_ROWS_TO_ORDER_FAILURE':
    case 'MOVE_ORDER_ROWS_TO_ORDER_FAILURE':
    case 'POST_ANALYSIS_ROW_FAILURE':
    case 'POST_ARRIVAL_FAILURE':
    case 'POST_ORDER_FAILURE':
    case 'POST_ORDER_ROW_ANALYSIS_LINK_FAILURE':
    case 'POST_TARGET_ROW_ANALYSIS_LINK_FAILURE':
    case 'POST_ORDER_ROW_FAILURE':
    case 'POST_REVENUE_FAILURE':
    case 'POST_REVENUE_ANALYSIS_LINK_FAILURE':
    case 'POST_TARGET_ROWS_FAILURE':
    case 'SPLIT_TARGET_ROWS_FAILURE':
    case 'POST_TOPIC_FAILURE':
    case 'POST_INVOICE_ATTACHMENT_FILES_FAILURE':
    case 'PUT_ACTUAL_COST_LINES_CONVERT_FAILURE':
    case 'PUT_ACTUAL_COST_CONVERT_FAILURE':
    case 'PUT_ACTUAL_COST_FAILURE':
    case 'PUT_ANALYSIS_ATTRIBUTE_VALUE_FAILURE':
    case 'PUT_ANALYSIS_ROW_FAILURE':
    case 'PUT_ARRIVAL_ROWS_FAILURE':
    case 'PUT_ARRIVAL_ROW_FAILURE':
    case 'PUT_CANCEL_INVOICE_HEADER_COMPLAINT_FAILURE':
    case 'PUT_INVOICE_HEADER_COMPLAINT_FAILURE':
    case 'PUT_INVOICE_HEADER_CONVERT_FAILURE':
    case 'PUT_INVOICE_HEADER_DECLINE_FAILURE':
    case 'PUT_INVOICE_HEADER_FAILURE':
    case 'PUT_INVOICE_HEADER_MOVE_FAILURE':
    case 'PUT_INVOICE_LINES_CONVERT_FAILURE':
    case 'PUT_MARK_NOTIFICATION_READ_FAILURE':
    case 'PUT_ORDER_FAILURE':
    case 'PUT_ORDER_ROW_FAILURE':
    case 'PUT_REVENUE_FAILURE':
    case 'PUT_USER_FAILURE':
    case 'PUT_TOPIC_FAILURE':
    case 'PUT_WORKPACKAGE_FAILURE':
    case 'PUT_DELEGATION_STATUS_FAILURE':
    case 'REMOVE_ORDER_ROW_ANALYSIS_LINK_FAILURE':
    case 'REMOVE_TARGET_ROW_ANALYSIS_LINK_FAILURE':
    case 'REMOVE_REVENUE_ANALYSIS_LINK_FAILURE':
    case 'GET_MANUAL_ENTRIES_DIMENSIONS_FAILURE':
    case 'GET_MANUAL_ENTRIES_DIMENSIONS_INITIAL_FAILURE':
    case 'POST_ORDER_CHAT_MESSAGE_FAILURE':
    case 'POST_MANUAL_ENTRIES_FAILURE':
    case 'POST_SPREAD_ARRIVAL_ROWS_FAILURE':
    case 'GET_PRESETTLED_INVOICE_ROWS_FAILURE':
    case 'GET_SNAPSHOTS_FAILURE':
    case 'GET_ALL_ORDER_SNAPSHOTS_FOR_SNAPSHOT_FAILURE':
    case 'DELETE_ANALYSIS_ROW_FAILURE':
    case 'DELETE_REVENUE_FAILURE':
    case 'POST_SNAPSHOT_FAILURE':
    case 'POST_TASK_FAILURE':
    case 'POST_WORKPACKAGE_FAILURE':
    case 'GET_MANUAL_ENTRIES_VALID_ITEMS_FAILURE':
    case 'GET_COMPANY_REPORTING_PERIODS_FAILURE':
    case 'GET_TARGET_ROWS_FOR_PROJECT_FAILURE':
    case 'GET_TARGET_ROWS_FOR_ANALYSIS_ROW_FAILURE':
    case 'GET_TARGET_ROW_HIERARCHIES_FAILURE':
    case 'GET_TOPICS_BY_PROJECT_ID_FAILURE':
    case 'GET_PROJECT_TIMELINES_FAILURE':
    case 'GET_WORK_PACKAGE_TIMELINES_FAILURE':
    case 'GET_WORK_PACKAGE_GROUP_TIMELINES_FAILURE':
    case 'GET_WORK_PACKAGE_RECEIVED_TIMELINES_FAILURE':
    case 'GET_WORK_PACKAGE_SCHEDULED_TASKS_FAILURE':
    case 'GET_CURRENT_PERIOD_ORDER_SNAPSHOTS_FAILURE':
    case 'GET_INVOICE_HEADERS_FOR_ANALYSIS_ROW_FAILURE':
    case 'GET_ACTUAL_COSTS_FOR_ANALYSIS_ROW_FAILURE':
    case 'GET_ARRIVAL_ROWS_FOR_ANALYSIS_ROW_FAILURE':
    case 'GET_TARGET_CHANGE_LOG_ENTRIES_FAILURE':
    case 'DELETE_TARGET_ROW_FAILURE':
    case 'POST_PAYMENT_PROGRAM_FAILURE':
    case 'PUT_ACTUAL_COST_MOVE_FAILURE':
    case 'PUT_ACTUAL_COST_REHANDLE_FAILURE':
    case 'PUT_INVOICE_HEADER_START_CORRECTION_FAILURE':
    case 'PUT_INVOICE_HEADER_FINISH_CORRECTION_FAILURE':
    case 'GET_PAYMENT_PROGRAM_ROW_GROUPS_FAILURE':
    case 'PUT_PAYMENT_PROGRAM_ROW_GROUP_FAILURE':
    case 'POST_PAYMENT_PROGRAM_ROW_GROUP_FAILURE':
    case 'DELETE_PAYMENT_PROGRAM_ROW_GROUP_FAILURE':
    case 'MOVE_PAYMENT_PROGRAM_ROWS_FAILURE':
    case 'GET_PROCUREMENT_REFERENCE_NUMBER_FAILURE':
    case 'GET_DETAILED_SNAPSHOTS_CSV_FAILURE':
    case 'GET_DETAILED_ANALYSIS_CSV_FAILURE':
    case 'GET_PROJECT_FAILURE':
    case 'GET_INVOICE_IMAGE_FILE_FAILURE':
    case 'GET_ACTUAL_COST_IMAGE_FILE_FAILURE':
    case 'DELETE_MULTIPLE_ARRIVAL_ROWS_FAILURE':
    case 'DELETE_MULTIPLE_ORDER_ROWS_FAILURE':
    case 'GET_NEXTGEN_SCHEDULE_DATA_FAILURE':
    case 'GET_INVOICE_ATTACHMENT_FILE_FAILURE':
    case 'GET_ACTUAL_COST_ATTACHMENT_FILE_FAILURE':
    case 'PUT_PROJECT_USER_RIGHTS_FAILURE':
    case 'GET_ALL_USERS_FAILURE':
    case 'POST_USER_FAILURE':
    case 'GET_RECLAMATION_TEMPLATE_FIELDS_FAILURE':
    case 'PUT_ORIGINAL_TARGET_FAILURE':
    case 'DELETE_INVOICE_ATTACHMENT_FILE_FAILURE':
    case 'CONVERT_TARGET_ROWS_TO_ORDER_ROWS_FAILURE':
    case 'DELETE_WORK_PACKAGE_FAILURE': {
      // cypress seems to cause cancel, abort and network errors sometimes which won't show up in normal use
      if (
        process.env.REACT_APP_CYPRESS &&
        action.payload.error?.status === 520
      ) {
        // eslint-disable-next-line no-console
        console.log(
          'action.payload.error',
          JSON.stringify(action.payload.error)
        );

        return state;
      }

      const textId = getErrorTextId(action.payload.error?.message);

      const errorNotification: NotificationUI = {
        id: uuid(),
        type: 'error',
        textId,
        values: action.payload.error?.message
          ? { message: action.payload.error.message }
          : { type: action.type },
      };

      return {
        ...state,
        ui: [...state.ui, errorNotification],
      };
    }
    case 'GET_USER_SUCCESS': {
      return {
        ...state,
        ui: [],
      };
    }
    case 'GET_NOTIFICATIONS_SUCCESS': {
      return {
        ...state,
        feed: action.payload.map(mapAPINotificationFeedItem),
      };
    }
    case 'PUT_MARK_NOTIFICATION_READ_SUCCESS': {
      const notification = action.payload;

      return {
        ...state,
        feed:
          notification.notificationType === '8'
            ? state.feed.filter(
                (item) =>
                  item.notificationType !== '8' ||
                  !(
                    idsEqual(item, notification) ||
                    idsEqual(item.project, notification.project)
                  )
              )
            : [
                ...state.feed.filter((item) => !idsEqual(item, notification)),
                ...[notification]
                  .filter((item) => !item.isDeleted)
                  .map(mapAPINotificationFeedItem),
              ],
      };
    }
    case 'CLOSE_NOTIFICATION': {
      const notificationId = action.payload;

      return {
        ...state,
        ui: state.ui.filter(
          (notification) => notification.id !== notificationId
        ),
      };
    }
    case 'PUT_ACTUAL_COST_SUCCESS': {
      const { actualCosts = [], notificationFeedItems = [] } = action.payload;

      const filteredNotifications = notificationFeedItems
        .filter(({ isDeleted }) => !isDeleted)
        .map(mapAPINotificationFeedItem);

      const uiNotificationArray = [...state.ui];

      if (
        actualCosts.filter((ac) => ac.statusId === ACTUAL_COST_STATUS_HANDLED)
          .length > 0
      ) {
        uiNotificationArray.push({
          id: uuid(),
          type: 'info',
          text: InvoiceSettledNotification({
            textId: 'order.receiveMode.actualCost.success.notification',
          }),
          timeout: 5000,
        });
      }

      return {
        ui: uiNotificationArray,
        feed:
          notificationFeedItems.length === 0
            ? state.feed
            : filteredNotifications,
      };
    }

    case 'PUT_ACTUAL_COST_MOVE_SUCCESS': {
      const {
        notificationFeedItems = [],
        orderId,
        actualCostId,
        actualCosts = [],
        orders = [],
      } = action.payload;

      const uiNotificationArray = [...state.ui];

      const filteredNotifications = notificationFeedItems
        .filter(({ isDeleted }) => !isDeleted)
        .map(mapAPINotificationFeedItem);

      const actualCost = actualCosts.find((ac) => ac.id === actualCostId);

      const order = orders.find((o) => o.id === orderId);

      const orderVisibleCodeName = `${order?.visibleCode ?? 'N/A'}, ${
        order?.name ?? 'N/A'
      }`;

      uiNotificationArray.push({
        id: uuid(),
        type: 'info',
        text: InvoiceHeaderMovedNotification({
          orderId,
          orderVisibleCodeName,
          actualCostNumber: actualCost?.documentNumber ?? '',
          projectId: actualCost?.projectId ?? null,
          actualCostId,
        }),
        timeout: 5000,
      });

      return {
        ui: uiNotificationArray,
        feed:
          notificationFeedItems.length === 0
            ? state.feed
            : filteredNotifications,
      };
    }

    case 'PUT_INVOICE_HEADER_SUCCESS': {
      const { purchaseInvoiceHeaders = [], notificationFeedItems = [] } =
        action.payload;

      const filteredNotifications = notificationFeedItems
        .filter(({ isDeleted }) => !isDeleted)
        .map(mapAPINotificationFeedItem);

      const uiNotificationArray = [...state.ui];

      if (
        purchaseInvoiceHeaders.filter((pih) =>
          INVOICE_HEADER_STATUS_ACCEPTED.includes(pih.statusId)
        ).length > 0
      ) {
        uiNotificationArray.push({
          id: uuid(),
          type: 'info',
          text: InvoiceSettledNotification({
            textId: 'order.receiveMode.invoice.success.notification',
          }),
          timeout: 5000,
        });
      }

      return {
        ui: uiNotificationArray,
        feed:
          notificationFeedItems.length === 0
            ? state.feed
            : filteredNotifications,
      };
    }
    case 'PUT_INVOICE_HEADER_COMPLAINT_SUCCESS': {
      const { notificationFeedItems = [], purchaseInvoiceReclamations } =
        action.payload;

      const filteredNotifications = notificationFeedItems
        .filter(({ isDeleted }) => !isDeleted)
        .map(mapAPINotificationFeedItem);

      const uiNotificationArray = [...state.ui];

      const isSentSuccessfully =
        purchaseInvoiceReclamations?.[0]?.isSentSuccessfully ?? false;

      const receiverEmail =
        purchaseInvoiceReclamations?.[0]?.receiverEmail ?? '';

      if (purchaseInvoiceReclamations && receiverEmail.length === 0) {
        uiNotificationArray.push({
          id: uuid(),
          type: 'info',
          text: TextOnlyNotification({
            textId: 'order.receiveMode.complaintModal.success.saved',
          }),
          timeout: 5000,
        });
      } else if (purchaseInvoiceReclamations && isSentSuccessfully) {
        uiNotificationArray.push({
          id: uuid(),
          type: 'info',
          text: TextOnlyNotification({
            textId: 'order.receiveMode.complaintModal.success',
          }),
          timeout: 5000,
        });
      } else {
        uiNotificationArray.push({
          id: uuid(),
          type: 'info',
          text: TextOnlyNotification({
            textId: 'order.receiveMode.complaintModal.success.email.error',
          }),
          timeout: 5000,
        });
      }

      return {
        ui: uiNotificationArray,
        feed:
          notificationFeedItems.length === 0
            ? state.feed
            : filteredNotifications,
      };
    }
    case 'PUT_INVOICE_HEADER_MOVE_SUCCESS': {
      const {
        notificationFeedItems = [],
        invoiceHeaderId,
        orderId,
        purchaseInvoiceHeaders = [],
        orders = [],
      } = action.payload;

      const uiNotificationArray = [...state.ui];

      const filteredNotifications = notificationFeedItems
        .filter(({ isDeleted }) => !isDeleted)
        .map(mapAPINotificationFeedItem);

      const invoiceHeader = purchaseInvoiceHeaders.find(
        (pih) => pih.id === invoiceHeaderId
      );

      const order = orders.find((o) => o.id === orderId);

      const orderVisibleCodeName = `${order?.visibleCode ?? 'N/A'}, ${
        order?.name ?? 'N/A'
      }`;

      uiNotificationArray.push({
        id: uuid(),
        type: 'info',
        text: InvoiceHeaderMovedNotification({
          orderId,
          orderVisibleCodeName,
          vendorInvoiceNo: invoiceHeader?.vendorInvoiceNo ?? '',
          projectId: invoiceHeader?.projectId ?? null,
          invoiceHeaderId,
        }),
        timeout: 5000,
      });

      return {
        ui: uiNotificationArray,
        feed:
          notificationFeedItems.length === 0
            ? state.feed
            : filteredNotifications,
      };
    }
    case 'MOVE_INVOICE_LINE_SUCCESS': {
      const { onClick, topicName } = action.payload;

      const uiNotificationArray = [...state.ui];

      uiNotificationArray.push({
        id: uuid(),
        type: 'info',
        text: InvoiceLineMovedNotification({
          textId: 'order.invoiceLines.group.assigned',
          onClick,
          topicName,
        }),
        timeout: 5000,
      });

      return {
        ...state,
        ui: uiNotificationArray,
      };
    }
    case 'MOVE_ACTUAL_COST_LINE_SUCCESS': {
      const { onClick, topicName } = action.payload;

      const uiNotificationArray = [...state.ui];

      uiNotificationArray.push({
        id: uuid(),
        type: 'info',
        text: InvoiceLineMovedNotification({
          textId: 'order.actualCostLines.group.assigned',
          onClick,
          topicName,
        }),
        timeout: 5000,
      });

      return {
        ...state,
        ui: uiNotificationArray,
      };
    }
    case 'DELETE_TOPIC_SUCCESS': {
      const {
        orderId,
        topicName,
        projectId,
        defaultTopic,
        notificationFeedItems = [],
      } = action.payload;

      const uiNotificationArray = [...state.ui];

      uiNotificationArray.push({
        id: uuid(),
        type: 'info',
        text: TopicDeletedNotification({
          orderId,
          deletedTopicName: topicName,
          projectId,
          defaultTopic,
        }),
        timeout: 5000,
      });

      if (notificationFeedItems.length === 0) {
        return {
          ...state,
          ui: uiNotificationArray,
        };
      }

      return {
        ...state,
        ui: uiNotificationArray,
        feed: notificationFeedItems
          .filter(({ isDeleted }) => !isDeleted)
          .map(mapAPINotificationFeedItem),
      };
    }
    case 'POST_INVOICE_ATTACHMENT_FILES_SUCCESS': {
      const uiNotificationArray = [...state.ui];

      uiNotificationArray.push({
        id: uuid(),
        type: 'info',
        textId: 'order.receiveMode.invoice.attachment.upload.success',
        timeout: 5000,
      });

      return {
        ...state,
        ui: uiNotificationArray,
      };
    }
    case 'GET_INVOICE_ATTACHMENT_FILE_SUCCESS':
    case 'GET_ACTUAL_COST_ATTACHMENT_FILE_SUCCESS': {
      const { popUpBlocked } = action.payload;

      const uiNotificationArray = [...state.ui];

      const includesPopUpBlocked = uiNotificationArray.some(
        (notification) =>
          notification.textId ===
          'order.receiveMode.notification.popUps.blocked'
      );

      if (popUpBlocked && !includesPopUpBlocked) {
        uiNotificationArray.push({
          id: uuid(),
          type: 'info',
          textId: 'order.receiveMode.notification.popUps.blocked',
          timeout: 30000,
        });
      }

      return {
        ...state,
        ui: uiNotificationArray,
      };
    }

    case 'MOVE_ORDER_ROWS_TO_TOPIC_SUCCESS':
    case 'MOVE_ORDER_ROWS_TO_ORDER_SUCCESS':
    case 'MOVE_TARGET_ROWS_TO_ORDER_SUCCESS':
    case 'MOVE_TARGET_ROWS_TO_TOPIC_SUCCESS': {
      let targetRowIds: string[] = [];
      let orderRowIds: string[] = [];
      let descriptions: string[] = [];

      const { orders = [], targetRows = [], orderRows = [] } = action.payload;

      if (
        action.type === 'MOVE_ORDER_ROWS_TO_TOPIC_SUCCESS' ||
        action.type === 'MOVE_ORDER_ROWS_TO_ORDER_SUCCESS'
      ) {
        orderRowIds = action.payload.orderRowIds;
        descriptions = orderRows
          .filter((row) => orderRowIds.includes(row.id))
          .map((row) => row.description);
      }

      if (
        action.type === 'MOVE_TARGET_ROWS_TO_TOPIC_SUCCESS' ||
        action.type === 'MOVE_TARGET_ROWS_TO_ORDER_SUCCESS'
      ) {
        targetRowIds = action.payload.targetRowIds;
        descriptions = targetRows
          .filter((row) => targetRowIds.includes(row.id))
          .map((row) => row.description ?? '');
      }

      const orderId =
        orderRowIds.length > 0
          ? orderRows.find((row) => orderRowIds.includes(row.id))?.orderId
          : targetRows.find((row) => targetRowIds.includes(row.id))?.orderId;

      const uiNotificationArray = [...state.ui];

      if (!orderId) {
        return {
          ...state,
          ui: uiNotificationArray,
        };
      }

      const order = orders.find((o) => o.id === orderId);

      if (!order) {
        return {
          ...state,
          ui: uiNotificationArray,
        };
      }

      const orderVisibleCodeName = `${order?.visibleCode ?? 'N/A'}, ${
        order?.name ?? 'N/A'
      }`;

      uiNotificationArray.push({
        id: uuid(),
        type: 'info',
        text: OrderRowOrTargetRowMovedNotification({
          orderId,
          orderVisibleCodeName,
          projectId: order.projectId ?? null,
          descriptions: descriptions,
        }),
        timeout: 5000,
      });

      return {
        ...state,
        ui: uiNotificationArray,
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { notificationFeedItems = [] } = action.payload;

    if (notificationFeedItems.length === 0) {
      return state;
    }

    return {
      ...state,
      feed: notificationFeedItems
        .filter(({ isDeleted }) => !isDeleted)
        .map(mapAPINotificationFeedItem),
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);
  assertIsNotFailureAction(action);

  return state;
};

const idsEqual = (a?: { id: ID } | null, b?: { id: ID } | null) =>
  a && b && a.id && b.id && a.id.toString() === b.id.toString();

export default notificationReducer;

export const getNumberOfUnreadNotifications = (state: AppState) => {
  const notificationFeedItems = state.notification.feed;

  const amount = notificationFeedItems
    .filter(({ isDeleted }) => !isDeleted)
    .reduce(
      (sum, n) => (n.notificationType === '4' && n.isRead ? sum : sum + 1),
      0
    );

  return amount;
};
